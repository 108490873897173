import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'intl/messages';
import NavDropdown from './NavDropdown';

const NavLangs: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <NavDropdown label={formatMessage({ ...messages.languages })}>
      <a className="dropdown-item" href="/nonogram/bg/">
        <FormattedMessage {...messages.bulgarian} />
        &nbsp; (български)
      </a>
      <a className="dropdown-item" href="/nonogram/cs/">
        <FormattedMessage {...messages.czech} />
        &nbsp; (čeština)
      </a>
      <a className="dropdown-item" href="/nonogram/da/">
        <FormattedMessage {...messages.danish} />
        &nbsp; (dansk)
      </a>
      <a className="dropdown-item" href="/nonogram/de/">
        <FormattedMessage {...messages.german} />
        &nbsp; (Deutsche)
      </a>
      <a className="dropdown-item" href="/nonogram/el/">
        <FormattedMessage {...messages.greek} />
        &nbsp; (Ελληνικά)
      </a>
      <a className="dropdown-item" href="/nonogram/en/">
        <FormattedMessage {...messages.english} />
        &nbsp; (English)
      </a>
      <a className="dropdown-item" href="/nonogram/es/">
        <FormattedMessage {...messages.spanish} />
        &nbsp; (Español)
      </a>
      <a className="dropdown-item" href="/nonogram/et/">
        <FormattedMessage {...messages.estonian} />
        &nbsp; (Eesti keel)
      </a>
      <a className="dropdown-item" href="/nonogram/fi/">
        <FormattedMessage {...messages.finnish} />
        &nbsp; (Suorittaa loppuun)
      </a>
      <a className="dropdown-item" href="/nonogram/fr/">
        <FormattedMessage {...messages.french} />
        &nbsp; (français)
      </a>
      <a className="dropdown-item" href="/nonogram/he/">
        <FormattedMessage {...messages.hebrew} />
        &nbsp; (עִברִית)
      </a>
      <a className="dropdown-item" href="/nonogram/hr/">
        <FormattedMessage {...messages.croatian} />
        &nbsp; (hrvatski)
      </a>
      <a className="dropdown-item" href="/nonogram/hu/">
        <FormattedMessage {...messages.hungarian} />
        &nbsp; (Magyar)
      </a>
      <a className="dropdown-item" href="/nonogram/it/">
        <FormattedMessage {...messages.italian} />
        &nbsp; (italiano)
      </a>
      <a className="dropdown-item" href="/nonogram/ja/">
        <FormattedMessage {...messages.japanese} />
        &nbsp; (日本語)
      </a>
      <a className="dropdown-item" href="/nonogram/ko/">
        <FormattedMessage {...messages.korean} />
        &nbsp; (한국인)
      </a>
      <a className="dropdown-item" href="/nonogram/nb/">
        <FormattedMessage {...messages.norwegian} />
        &nbsp; (norsk)
      </a>
      <a className="dropdown-item" href="/nonogram/nl/">
        <FormattedMessage {...messages.dutch} />
        &nbsp; (Nederlands)
      </a>
      <a className="dropdown-item" href="/nonogram/pl/">
        <FormattedMessage {...messages.polish} />
        &nbsp; (Polskie)
      </a>
      <a className="dropdown-item" href="/nonogram/pt/">
        <FormattedMessage {...messages.portuguese} />
        &nbsp; (Português)
      </a>
      <a className="dropdown-item" href="/nonogram/ro/">
        <FormattedMessage {...messages.romanian} />
        &nbsp; (Română)
      </a>
      <a className="dropdown-item" href="/nonogram/ru/">
        <FormattedMessage {...messages.russian} />
        &nbsp; (русский)
      </a>
      <a className="dropdown-item" href="/nonogram/sk/">
        <FormattedMessage {...messages.slovak} />
        &nbsp; (slovenský)
      </a>
      <a className="dropdown-item" href="/nonogram/sl/">
        <FormattedMessage {...messages.slovenian} />
        &nbsp; (Slovenščina)
      </a>
      <a className="dropdown-item" href="/nonogram/sr/">
        <FormattedMessage {...messages.serbian} />
        &nbsp; (Slovenščina)
      </a>
      <a className="dropdown-item" href="/nonogram/sv/">
        <FormattedMessage {...messages.swedish} />
        &nbsp; (svenska)
      </a>
      <a className="dropdown-item" href="/nonogram/tr/">
        <FormattedMessage {...messages.turkish} />
        &nbsp; (Türk)
      </a>
      <a className="dropdown-item" href="/nonogram/vi/">
        <FormattedMessage {...messages.vietnamese} />
        &nbsp; (Tiếng Việt)
      </a>
      <a className="dropdown-item" href="/nonogram/zh/">
        <FormattedMessage {...messages.chinese} />
        &nbsp; (中文)
      </a>
    </NavDropdown>
  );
};

export default NavLangs;
