import React from 'react';
import MyInfoRow from './InfoRow';
import FormField from './FormField';
import { FormFieldType } from './types';

interface FieldWrapProps {
  field: {
    name: string;
    label?: React.ReactNode;
    key?: string;
    noLabel?: boolean;
    type: FormFieldType;
  };
  values?: any;
  errors?: any;
  onChange: (fieldName: string, value: any) => void;
}

export const FieldWrap: React.FC<FieldWrapProps> = ({
  field,
  values,
  errors,
  onChange,
}) => {
  const key = field.key ? field.key : field.name;
  const value = values ? values[key] : false;
  const label = field.noLabel ? '' : (field.label ? field.label : field.name);
  const error = errors ? [errors[field.key ? field.key : field.name]] : [];
  const errorStr = error.length > 0 ? error[0] : '';
  const fieldValue = (
    <>
      <FormField
        key={`field-${field.name}`}
        {...field}
        onChange={onChange}
        value={value}
      />
      {errorStr &&
        <p>{errorStr}</p>
      }
    </>
  );
  if (field.noLabel) {
    return fieldValue;
  }
  return (
    <MyInfoRow
      key={field.name}
      label={label}
      value={fieldValue}
    />
  );
};

export default FieldWrap;
