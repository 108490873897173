import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import messages from 'intl/messages';
import MyLink from 'features/home/components/MyLink';
import MyTitle from 'features/home/components/MyTitle';
import LoginForm, { LoginFormFields } from './LoginForm';
import { useLoginMutation } from '../graphql/hooks';
import { selectUser } from '../../../redux/user-slice';

const LoginPage: React.FC = () => {
  const { loginMutation, result, loading } = useLoginMutation();
  const me = useSelector(selectUser);

  const error = result && result.login === null;

  if (me && me.token) {
    return (
      <div className="text-center">
        <MyTitle message={messages.loggedIn} />
        <FormattedMessage {...messages.loggedIn} />
      </div>
    );
  }

  const submit = (variables: LoginFormFields) => {
    loginMutation({ variables });
  };

  return (
    <div className="container-fluid">
      <MyTitle message={messages.login} />
      <h2 className="text-center">
        <FormattedMessage {...messages.login} />
      </h2>
      <LoginForm
        onSubmit={submit}
        loading={loading}
      />
      {error && (
        <div className="text-center">
          There was a problem. Please try again.
        </div>
      )}
      {result && result.login && result.login.token === null && (
        <div className="text-center">
          The username/password is not recognized.
          <br />
          Please check and try again.
        </div>
      )}
      <div className="mt-4">
        <div className="text-center">
          <FormattedMessage {...messages.noAccount} />
          <br />
          <MyLink href="/register">
            <FormattedMessage {...messages.createAccount} />
          </MyLink>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
