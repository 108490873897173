import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import puzzleReducer from './puzzle-slice';
import userReducer from './user-slice';

export const store = configureStore({
  reducer: {
    puzzle: puzzleReducer,
    user: userReducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
