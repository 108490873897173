import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  PUZZLE_BY_ID,
  PUZZLE_BY_SIZE,
  SUBMIT_ANSWER,
  TOP_TIMES,
} from './constants';
import {
  PuzzleIdQuery,
  PuzzleSizeQuery,
  SubmitAnswerMutation,
  SubmitAnswerMutationVariables,
  TopTimesQuery,
} from './types';
import {
  PuzzleCellState,
  selectPuzzle,
  selectPuzzleAnswer,
  selectTimerStart,
} from '../../../redux/puzzle-slice';

export const usePuzzleIdQuery = (id: number) => {
  const { data, loading, error } = useQuery<PuzzleIdQuery>(PUZZLE_BY_ID, {
    variables: { id },
  });

  const puzzle = data?.puzzleById;

  return { puzzle, loading, error };
};

export const usePuzzleSizeQuery = (size: number) => {
  const { data, loading, error } = useQuery<PuzzleSizeQuery>(PUZZLE_BY_SIZE, {
    variables: { size },
  });

  const puzzle = data?.puzzleBySize;

  return { puzzle, loading, error };
};

export const useTopTimesQuery = () => {
  const { data, loading, error } = useQuery<TopTimesQuery>(TOP_TIMES);

  const topTimes = data?.topTimes;

  return { topTimes, loading, error };
};

export const useSubmitAnswerMutation = () => {
  const [submitAnswer, { data, loading, error }] =  useMutation<
    SubmitAnswerMutation,
    SubmitAnswerMutationVariables
  >(SUBMIT_ANSWER);
  const puzzle = useSelector(selectPuzzle);
  const answer = useSelector(selectPuzzleAnswer);
  const timerStart = useSelector(selectTimerStart);

  const success = data?.submitAnswer;

  const submitMyAnswer = () => {
    const duration = Math.floor((Date.now() - timerStart) / 1000);
    const w = puzzle.size;
    const h = puzzle.size;
    const res = [];	
    let i, j;	
    for(i=0;i<h;i++) {	
      let row = '';	
      for(j=0;j<w;j++) {	
        row += answer[j][i] === PuzzleCellState.On ? 1 : 0;	
      }	
      res.push(row);	
    }	
    submitAnswer({ variables: { hash: puzzle.hash, answer: res, duration } });
  };

  return { submitAnswer: submitMyAnswer, success, loading, error };
};
