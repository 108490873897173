import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import messages from 'intl/messages';
import MyLink from 'features/home/components/MyLink';
import NavDropdown from './NavDropdown';
import { selectUser } from '../../../redux/user-slice';

export const NavAuth: React.FC = () => {
  const me = useSelector(selectUser);
  const isLoggedIn = !!me && !!me.token;
  const { formatMessage } = useIntl();
  if (!isLoggedIn) {
    return (
      <li className="nav-item">
        <MyLink className="nav-link" href="/login">
          <FormattedMessage {...messages.login} />
        </MyLink>
      </li>
    );
  }
  return (
    <NavDropdown label={ formatMessage({ ...messages.account })}>
      <MyLink className="dropdown-item" href="/prefs">
        <FormattedMessage {...messages.settings} />
      </MyLink>
      <MyLink className="dropdown-item" href="/password">
        <FormattedMessage {...messages.changePassword} />
      </MyLink>
      <MyLink className="dropdown-item" href="/logout">
        <FormattedMessage {...messages.logout} />
      </MyLink>
      {me && me.username === 'barry' && (
        <MyLink className="dropdown-item" href="/contacts">
          Messages
        </MyLink>
      )}
    </NavDropdown>
  );
};

export default NavAuth;
