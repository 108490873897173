import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import Form from 'features/barry-forms';
import langs from 'intl/langs';
import { FormField } from 'features/barry-forms/types';

const sizes = [5, 7, 8, 10, 12, 15, 20, 25];

const fields: FormField[] = [
  {
    type: 'select',
    name: 'lang',
    label: <FormattedMessage {...messages.language} />,
    options: Object.keys(langs).map(lang => ({
      value: lang,
      label: langs[lang],
    })),
  },
  {
    type: 'select',
    name: 'size',
    label: <FormattedMessage {...messages.size} />,
    options: sizes.map(size => ({
      value: size,
      label: `${size}x${size}`,
    })),
    isNumeric: true,
  },
];

export interface PrefsValues {
  lang: string;
  size: number;
}

interface PrefsFormProps {
  onSubmit: (values: PrefsValues) => void;
  loading?: boolean;
  initialValues?: PrefsValues;
}

const PrefsForm: React.FC<PrefsFormProps> = ({ onSubmit, ...props }) => (
  <Form<PrefsValues>
    {...props}
    onSubmit={onSubmit}
    fields={fields}
    hideCancel
    submitLabel={<FormattedMessage {...messages.save} />}
  />
);

export default PrefsForm;
