import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import Form from 'features/barry-forms';
import { FormField } from 'features/barry-forms/types';

const fields: FormField[] = [
  {
    type: 'text',
    name: 'username',
    label: <FormattedMessage {...messages.username} />,
  },
  {
    type: 'password',
    name: 'password',
    label: <FormattedMessage {...messages.password} />
  },
  {
    type: 'password',
    name: 'password2',
    label: <FormattedMessage {...messages.confirmPassword} />
  },
  {
    type: 'text',
    name: 'email',
    label: <FormattedMessage {...messages.email} />,
  },
];

export interface RegisterValues {
  username: string;
  password: string;
  password2: string;
  email: string;
}

interface RegisterValidationErrors {
  username?: React.ReactNode;
  password?: React.ReactNode;
  password2?: React.ReactNode;
  email?: React.ReactNode;
}

/* eslint-disable-next-line */
const email_re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validate = (values: RegisterValues) => {
  const errors: RegisterValidationErrors = {};
  if (!values.username) {
    errors.username = <FormattedMessage {...messages.required} />;
  }
  if (!values.password) {
    errors.password = <FormattedMessage {...messages.required} />;
  }
  if (!values.password2) {
    errors.password2 = <FormattedMessage {...messages.required} />;
  } else if (values.password !== values.password2) {
    errors.password2 = <FormattedMessage {...messages.passwordsMustMatch} />;
  }
  if (!values.email) {
    errors.email = <FormattedMessage {...messages.required} />;
  } else if (!email_re.test(values.email)) {
    errors.email = <FormattedMessage {...messages.invalidEmail} />;
  }
  return errors;
};

interface RegisterFormProps {
  onSubmit: (values: RegisterValues) => void;
  loading?: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ onSubmit, ...props }) => (
  <Form<RegisterValues>
    {...props}
    onSubmit={onSubmit}
    fields={fields}
    validate={validate}
    submitLabel={<FormattedMessage {...messages.register} />}
  />
);

export default RegisterForm;
