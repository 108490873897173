import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import MyTitle from 'features/home/components/MyTitle';
import Loader from './Loader';
import TopTimeList from './TopTimeList';
import { useTopTimesQuery } from '../graphql/hooks';

const TopTimePage: React.FC = () => {
  const { loading, error, topTimes } = useTopTimesQuery();
  if (error) {
    return (
      <FormattedMessage {...messages.ajaxError} />
    );
  }
  if (loading || !topTimes) {
    return (
      <div className="text-center">
        <MyTitle message={messages.topTimes} />
        Loading
        <Loader />
      </div>
    );
  }
  return (
    <div className="container">
      <MyTitle message={messages.topTimes} />
      <div className="row">
        {topTimes.map(times => (
          <div className="col-lg-3 col-md-6 col-xs-12 mb-3" key={times.size}>
            <TopTimeList
              size={times.size}
              times={times.times}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopTimePage;
