import { PuzzleCellState } from '../../../redux/puzzle-slice';
import { Puzzle } from '../graphql/types';

type CheckSoutionResult = {
  isCorrect: boolean,
  correctCols: boolean[],
  correctRows: boolean[],
}

const checkSolution = (
  puzzle: Puzzle,
  answer: PuzzleCellState[][],
): CheckSoutionResult => {
  if (!puzzle || !answer) {
    return {
      isCorrect: false,
      correctCols: [],
      correctRows: [],
    };
  }

  const { size, topNumbers, leftNumbers } = puzzle;
  const w = size;
  const h = size;
  const correctRows = [];
  const correctCols = [];
  let i;
  let j;
  let c;
  let t;
  let m;
  let v;
  let res = true;

  for (i = 0; i < h; i++) {
    c = 0;
    t = [];
    m = leftNumbers[i];
    for (j = 0; j < w; j++) {
      v = answer[j][i] === PuzzleCellState.On;
      if (v === true) {
        c++;
      } else if (c > 0) {
        t.push(c);
        c = 0;
      }
    }
    if (c > 0) {
      t.push(c);
    }
    const isEqual = JSON.stringify(m) === JSON.stringify(t);
    res = res && isEqual;
    correctRows.push(isEqual);
  }
  for (j = 0; j < w; j++) {
    c = 0;
    t = [];
    m = topNumbers[j];
    for (i = 0; i < h; i++) {
      v = answer[j][i] === PuzzleCellState.On;
      if (v === true) {
        c++;
      } else if (c > 0) {
        t.push(c);
        c = 0;
      }
    }
    if (c > 0) {
      t.push(c);
    }
    const isEqual = JSON.stringify(m) === JSON.stringify(t);
    res = res && isEqual;
    correctCols.push(isEqual);
  }

  return {
    isCorrect: res,
    correctCols,
    correctRows,
  };
};

export default checkSolution;
