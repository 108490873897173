import React from 'react';
import { useIntl } from 'react-intl';
import messages from 'intl/messages';
import { ANALYTICS_ID } from 'config';
import MyHelmet from './MyHelmet';

export const HelmetHeaders: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <MyHelmet>
      <meta
        name="keywords"
        content={formatMessage(messages.metaKeywords)}
      />
      <meta
        name="description"
        content={formatMessage(messages.metaDescription)}
      />
      <meta
        property="og:url"
        content={window.location.href}
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:image"
        content="https://www.nonogramsonline.com/nonogram.jpg"
      />
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_ID}`}
      />
      <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${ANALYTICS_ID}');
      `}</script>
      <title>Nonograms Online</title>
    </MyHelmet>
  );
};

export default HelmetHeaders;
