import React from 'react';
import MyTitle from 'features/home/components/MyTitle';
import messages from 'intl/messages';
import PuzzleQuery from './PuzzleQuery';
import MyAd from './MyAd';

interface DefaultPageProps {
  match: {
    params: {
      size?: string;
      puzzleId?: string;
    };
  };
}

const DefaultPage: React.FC<DefaultPageProps> = ({ match }) => {
  let p = {};
  if (typeof match.params.size !== 'undefined') {
    p = {
      size: parseInt(match.params.size, 10),
    };
  }
  if (typeof match.params.puzzleId !== 'undefined') {
    p = {
      id: parseInt(match.params.puzzleId, 10),
    };
  }
  return (
    <div className="text-center">
      <MyTitle message={messages.nonogramPuzzle} />
      <div>
        <MyAd slot="6326482591" />
      </div>
      <div className="row">
        <div className="col-lg-2 hidden-sm hidden-xs hidden-md" />
        <div className="col-lg-8 col-sm-12 col-xs-12">
          <PuzzleQuery {...p} />
        </div>
        <div className="col-xs-12 col-lg-2">
          <MyAd slot="1756682195"  />
        </div>
      </div>
    </div>
  );
};

export default DefaultPage;
