import React from 'react';
import { useIntl } from 'react-intl';
import messages from 'intl/messages';
import MyLink from 'features/home/components/MyLink';
import NavDropdown from './NavDropdown';

const sizes = [5, 7, 8, 10, 12, 15, 20, 25];

const NavSizes: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <NavDropdown label={formatMessage({ ...messages.nonogramSizes })}>
      {sizes.map(size =>
        <MyLink
          href={`/nonogram/${size}`}
          className="dropdown-item"
          key={size}
        >
          {size}x{size}
        </MyLink>
      )}
    </NavDropdown>
  );
};

export default NavSizes;
