import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import TopTimeRow from './TopTimeRow';
import { TopTimes } from '../graphql/types';

const TopTimeList: React.FC<TopTimes> = ({ size, times }) => (
  <div className="puzzle-top-time-list">
    <table>
      <tr>
        <td colSpan={3}>
          <FormattedMessage {...messages.topTimesFor} values={{ size }} />
        </td>
      </tr>
      {times.length === 0 ? (
        <tr>
          <td colSpan={3}>
            <FormattedMessage {...messages.noTimes} />
          </td>
        </tr>
      ) : (
        <tr>
          <td>
            <b>
              <FormattedMessage {...messages.user} />
            </b>
          </td>
          <td>
            <b>
              <FormattedMessage {...messages.time} />
            </b>
          </td>
          <td>
            <b>
              <FormattedMessage {...messages.puzzle} />
            </b>
          </td>
        </tr>
      )}
      {times.map(time => (
        <TopTimeRow {...time} key={JSON.stringify(time)} />
      ))}
    </table>
  </div>
);

export default TopTimeList;
