// require('dotenv').config();

export const IS_DEV = process.env.NODE_ENV === 'development';

const serverUrl =
  'https://us-central1-nonograms-225103.cloudfunctions.net/nono-gql';

const localUrl = 'http://localhost:8080/nono-gql';

export const GRAPHQL_URL = serverUrl;

export const AD_TEST = false;

export const ANALYTICS_ID = 'G-7J6GF08E3S';
