import React from 'react';
import MyLink from 'features/home/components/MyLink';
import { TopTime } from '../graphql/types';

const TopTimeRow: React.FC<TopTime> = ({ username, duration, puzzleId }) => (
  <tr>
    <td>{username}</td>
    <td>{duration}</td>
    <td>
      <MyLink href={`/nonogram/puzzle/${puzzleId}`}>
        {puzzleId}
      </MyLink>
    </td>
  </tr>
);

export default TopTimeRow;
