import React from 'react';
import { useIntl } from 'react-intl';
import MyHelmet from './MyHelmet';

interface MyTitleProps {
  title?: string;
  message?: {
    id: string;
    defaultMessage: string;
  };
}

const MyTitle: React.FC<MyTitleProps> = ({
  title = 'Nonograms Online',
  message,
}) => {
  const { formatMessage } = useIntl();
  return (
    <MyHelmet>
      <title>{title}{message ? ` - ${formatMessage(message)}` : ''}</title>
    </MyHelmet>
  );
};

export default MyTitle;
