import React, { MouseEvent, TouchEvent } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import PuzzleCell from './PuzzleCell';
import {
  PuzzleCellState,
  selectHighlight,
  selectLeftNumbersCorrect,
  selectPuzzle,
  selectPuzzleAnswer,
  selectSolved,
  selectTopNumbersCorrect,
  setPuzzleCell,
} from '../../../redux/puzzle-slice';
import { useAppDispatch } from '../../../redux/store';

const borderClass = (
  x: number,
  w: number,
  baseClass: string,
  addedClass: string
): string => {
  let d = 5;
  if (x + 1 === w) {
    return baseClass;
  }
  if (w % 5 !== 0) {
    d = 4;
  }
  if ((x + 1) % d !== 0) {
    return baseClass;
  }
  return classnames(baseClass, addedClass);
};

const Puzzle: React.FC = () => {
  const highlight = useSelector(selectHighlight);
  const puzzle = useSelector(selectPuzzle);
  const answer = useSelector(selectPuzzleAnswer);
  const solved = useSelector(selectSolved);
  const topNumbersCorrect = useSelector(selectTopNumbersCorrect);
  const leftNumbersCorrect = useSelector(selectLeftNumbersCorrect);
  const dispatch = useAppDispatch();
  const [curDrag, setCurDrag] = React.useState<PuzzleCellState>(
    PuzzleCellState.Empty
  );
  const [isDragging, setIsDragging] = React.useState(false);

  const setCell = React.useCallback(
    (x: number, y: number, value: PuzzleCellState) => {
      dispatch(setPuzzleCell({
        x,
        y,
        value,
      }));
    },
    [dispatch]
  );

  const mouseOverCell = React.useCallback((x: number, y: number) => {
    if (!isDragging) {
      return;
    }
    setCell(x, y, curDrag);
  }, [curDrag, setCell, isDragging]);

  const mouseDownCell = React.useCallback(
    (evt: MouseEvent, x: number, y: number) => {
      evt.preventDefault();
      const v = answer[x][y];
      let nextDrag = PuzzleCellState.Empty;
      if (v === PuzzleCellState.Empty) {
        if (evt.button === 0) {
          nextDrag = PuzzleCellState.On;
        } else {
          nextDrag = PuzzleCellState.Off;
        }
      }
      setCurDrag(nextDrag);
      setCell(x, y, nextDrag);
      setIsDragging(true);
    },
    [answer, setCell, setCurDrag]
  );

  const mouseOut = React.useCallback((evt: MouseEvent) => {
    evt.preventDefault();
    setIsDragging(false);
  }, []);

  const touchStart = React.useCallback(
    (evt: TouchEvent, x: number, y: number) => {
      // evt.preventDefault();
      const v = answer[x][y];
      let nextDrag = PuzzleCellState.Empty;
      if (v === null || v === PuzzleCellState.Empty) {
        nextDrag = PuzzleCellState.On;
      } else if (v === PuzzleCellState.On) {
        nextDrag = PuzzleCellState.Off;
      }
      setCell(x, y, nextDrag);
      setCurDrag(nextDrag);
      setIsDragging(true);
    },
    [answer, setCell, setCurDrag]
  );

  const touchMove = React.useCallback((evt: TouchEvent) => {
    let el;
    let i = 0;
    const l = evt.touches.length;
    let row;
    let col;

    for (; i < l; i++) {
      el = document.elementFromPoint(
        evt.nativeEvent.touches[i].clientX,
        evt.nativeEvent.touches[i].clientY,
      );
      if (el) {
        row = el.getAttribute('data-row');
        col = el.getAttribute('data-col');
        if ((row || row === '0') && (col || col === '0')) {
          const x = parseInt(col);
          const y = parseInt(row);
          setCell(x, y, curDrag);
        }
      }
    }
  }, [curDrag, setCell]);

  const touchEnd = React.useCallback((evt: TouchEvent) => {
    evt.preventDefault();
    setCurDrag(PuzzleCellState.Empty);
    setIsDragging(false);
  }, []);

  if (!puzzle) {
    return null;
  }

  const {
    topNumbers,
    leftNumbers,
    size,
  } = puzzle;

  return (
    <table
      id="nonogram"
      cellSpacing="0"
      cellPadding="1"
      border={1}
      className={solved ? 'solved' : ''}
    >
      <tbody>
        <tr className="b">
          <td className="r" onMouseOver={mouseOut}>
            &nbsp;
          </td>
          {topNumbers.map((arr, idx) => (
            <td
              valign="bottom"
              className={classnames(
                borderClass(idx, size, '', 'r'),
                highlight && topNumbersCorrect[idx] && 'text-success',
              )}
              key={idx}
              onMouseOver={mouseOut}
            >
              {arr.map((n, aa) => (
                <React.Fragment key={aa}>
                  {n}
                  <br />
                </React.Fragment>
              ))}
            </td>
          ))}
        </tr>
        {leftNumbers.map((arr, row_i) => (
          <tr
            key={row_i}
            className={borderClass(row_i, size, '', 'b')}
          >
            <td
              className={classnames(
                'left-numbers',
                highlight && leftNumbersCorrect[row_i] && 'text-success',
              )}
              onMouseOver={mouseOut}
            >
              {arr.join(' ')}
            </td>
            {topNumbers.map((arr, col_i) => (
              <PuzzleCell
                y={row_i}
                x={col_i}
                className={borderClass(
                  col_i,
                  size,
                  `puzzle-anim-${size - row_i + col_i}`,
                  'r',
                )}
                onMouseOver={mouseOverCell}
                onMouseDown={mouseDownCell}
                onMouseUp={mouseOut}
                onTouchEnd={touchEnd}
                onTouchStart={touchStart}
                onTouchMove={touchMove}
                key={`${row_i}x${col_i}`}
              />
            ))}
            <td className="puzzle-out" onMouseOver={mouseOut} />
          </tr>
        ))}
        <tr>
          <td className="puzzle-out" onMouseOver={mouseOut} />
          {topNumbers.map((arr, col_i) => (
            <td
              className="puzzle-out"
              onMouseOver={mouseOut}
              key={col_i}
            />
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default Puzzle;
