import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Puzzle } from 'features/puzzle/graphql/types';
import { RootState } from './store';
import checkSolution from '../features/puzzle/utils/checkSolution';

export enum PuzzleCellState {
  Empty = 0,
  On = 1,
  Off = 2,
}

export interface PuzzleState {
  timerStart: number;
  timerStop: number;
  puzzleAnswer: PuzzleCellState[][];
  puzzle: Puzzle;
  highlight: boolean;
  solved?: boolean;
  leftNumbersCorrect: boolean[];
  topNumbersCorrect: boolean[];
}

export const initialState: PuzzleState = {
  timerStart: Date.now(),
  timerStop: -1,
  puzzleAnswer: [],
  puzzle: {
    topNumbers: [],
    leftNumbers: [],
    size: 0,
    id: 0,
    hash: '',
  },
  highlight: false,
  solved: false,
  leftNumbersCorrect: [],
  topNumbersCorrect: [],
};

export const puzzleSlice = createSlice({
  name: 'puzzle',
  initialState,
  reducers: {
    setPuzzle: (state, action: PayloadAction<Puzzle>) => {
      state.timerStart = Date.now();
      state.puzzle = action.payload;
      state.puzzleAnswer =
        Array.from(Array(action.payload.size), () =>
          Array(action.payload.size).fill(PuzzleCellState.Empty)
      );
      state.solved = false;
      state.leftNumbersCorrect =
        Array(action.payload.leftNumbers.length).fill(false);
      state.topNumbersCorrect =
        Array(action.payload.topNumbers.length).fill(false);
    },
    resetPuzzle: (state) => {
      state.timerStart = Date.now();
      state.puzzleAnswer = Array.from(
        Array(state.puzzle.size), () =>
          Array(state.puzzle.size).fill(PuzzleCellState.Empty)
      );
    },
    setPuzzleCell: (
      state,
      action: PayloadAction<{ x: number, y: number, value: PuzzleCellState }>
    ) => {
      if (state.solved) {
        return;
      }
      state.puzzleAnswer[action.payload.x][action.payload.y] =
        action.payload.value;
      const status =  checkSolution(state.puzzle, state.puzzleAnswer);
      state.solved = status.isCorrect;
      state.leftNumbersCorrect = status.correctRows;
      state.topNumbersCorrect = status.correctCols;
      if (status.isCorrect) {
        state.timerStop = Date.now();
      }
    },
    setHighlight: (state, action: PayloadAction<boolean>) => {
      state.highlight = action.payload;
    },
  }
});

export const {
  setPuzzle,
  resetPuzzle,
  setPuzzleCell,
  setHighlight,
} = puzzleSlice.actions;

export const selectPuzzle = (state: RootState) => state.puzzle.puzzle;
export const selectPuzzleAnswer = (state: RootState) =>
  state.puzzle.puzzleAnswer;
export const selectTimerStart = (state: RootState) => state.puzzle.timerStart;
export const selectHighlight = (state: RootState) => state.puzzle.highlight;
export const selectTimerStop = (state: RootState) => state.puzzle.timerStop;
export const selectSolved = (state: RootState) => state.puzzle.solved;
export const selectLeftNumbersCorrect = (state: RootState) =>
  state.puzzle.leftNumbersCorrect;
export const selectTopNumbersCorrect = (state: RootState) =>
  state.puzzle.topNumbersCorrect;

export default puzzleSlice.reducer;
