import messages from 'intl/messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const playStoreLink = 
  "https://play.google.com/store/apps/details?id=com.nonogramsonline.game";

const appleAppLink =
  "https://apps.apple.com/us/app/nonograms-online/id1582211195?platform=iphone";

const playStoreIcon =
  'https://play.google.com/intl/en_us/badges/static/images/badges/' +
  'en_badge_web_generic.png';

const AppsPage: React.FC = () => (
  <div>
    <h2 className='text-center'>
      <FormattedMessage {...messages.nonogramPuzzle} />
      {' '}
      <FormattedMessage {...messages.mobileApps} />
    </h2>
    <div className="text-center my-4">
      <a href={playStoreLink}>
        <img
          style={{ width: '200px' }}
          alt='Get it on Google Play'
          src={playStoreIcon}
        />
      </a>
      <a href={appleAppLink}>
        <img
          src="https://watching-order.com/images/apple-badge.png"
          alt="Get it at the App Store"
        />
      </a>
    </div>

    <div className='text-center mb-4 flex justify-around'>
      <a
        href="https://barrywebdev.com/games/freecell"
        target="_blank"
        className='mr-4'
      >
        <FormattedMessage {...messages.freecell} />
      </a>
      <a
        href="https://bearandrye.com/"
        target="_blank"
      >
        <FormattedMessage {...messages.mobileApps} />
      </a>
    </div>
  </div>
);

export default AppsPage;
