import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NonogramPage from 'features/puzzle/components/DefaultPage';
import TopTimePage from 'features/puzzle/components/TopTimePage';
import ContactMePage from 'features/home/components/ContactMePage';
import HowToSolvePage from 'features/home/components/HowToSolvePage';
import LoginPage from 'features/auth/components/LoginPage';
import LogoutPage from 'features/auth/components/LogoutPage';
import RegisterPage from 'features/auth/components/RegisterPage';
import PrefsPage from 'features/auth/components/PrefsPage';
import PasswordPage from 'features/auth/components/PasswordPage';
import PrivacyPolicyPage from 'features/home/components/PrivacyPolicyPage';
import TermsOfServicePage from 'features/home/components/TermsOfServicePage';
import ContactsPage from 'features/home/components/ContactsPage';
import AppsPage from 'features/home/components/AppsPage';

const Router: React.FC = () => (
  <Switch>
    <Route
      path="/apps"
      component={AppsPage}
    />
    <Route
      path="/:lang([a-z][a-z])/apps"
      component={AppsPage}
    />

    <Route
      path="/login"
      component={LoginPage}
    />
    <Route
      path="/:lang([a-z][a-z])/login"
      component={LoginPage}
    />
    <Route
      path="/logout"
      component={LogoutPage}
    />
    <Route
      path="/:lang([a-z][a-z])/logout"
      component={LogoutPage}
    />
    <Route
      path="/register"
      component={RegisterPage}
    />
    <Route
      path="/:lang([a-z][a-z])/register"
      component={RegisterPage}
    />
    <Route
      path="/prefs"
      component={PrefsPage}
    />
    <Route
      path="/:lang([a-z][a-z])/prefs"
      component={PrefsPage}
    />
    <Route
      path="/password"
      component={PasswordPage}
    />
    <Route
      path="/:lang([a-z][a-z])/password"
      component={PasswordPage}
    />

    <Route
      path="/nonogram/:lang([a-z][a-z])/blog/(.*)"
      component={HowToSolvePage}
    />
    <Route
      path="/:lang([a-z][a-z])/blog/(.*)"
      component={HowToSolvePage}
    />
    <Route
      path="/blog/(.*)"
      component={HowToSolvePage}
    />
    <Route
      path="/:lang([a-z][a-z])/how-to-solve"
      component={HowToSolvePage}
    />
    <Route
      path="/how-to-solve"
      component={HowToSolvePage}
    />


    <Route
      path="/contact"
      component={ContactMePage}
    />
    <Route
      path="/:lang([a-z][a-z])/contact"
      component={ContactMePage}
    />
    <Route
      path="/:lang([a-z][a-z])/contacts"
      component={ContactsPage}
    />
    <Route
      path="/contacts"
      component={ContactsPage}
    />

    <Route
      path="/privacy/"
      component={PrivacyPolicyPage}
    />
    <Route
      path="/:lang([a-z][a-z])/privacy/"
      component={PrivacyPolicyPage}
    />

<Route
      path="/tos/"
      component={TermsOfServicePage}
    />
    <Route
      path="/:lang([a-z][a-z])/tos/"
      component={TermsOfServicePage}
    />

    <Route
      path="/top"
      component={TopTimePage}
    />
    <Route
      path="/:lang([a-z][a-z])/top"
      component={TopTimePage}
    />
    <Route
      path="/nonogram/top"
      component={TopTimePage}
    />



    <Route
      path="/nonogram/nonograms/puzzle/:lang([a-z][a-z])/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonogram/puzzle/:lang([a-z][a-z])/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonogram/nonograms/:lang([a-z][a-z])/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonogram/:lang([a-z][a-z])/nonograms/puzzle/:puzzleId(\d+)"
      component={NonogramPage}
    />

    <Route
      path="/nonogram/:lang([a-z][a-z])/:size(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonogram/:lang([a-z][a-z])"
      component={NonogramPage}
    />
    <Route
      path="/nonogram/nonograms/puzzle/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonogram/puzzle/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonogram/nonograms/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonogram/:size(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonogram/"
      component={NonogramPage}
    />
    <Route
      path="/nonograms/puzzle/:lang([a-z][a-z])/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/puzzle/:lang([a-z][a-z])/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonograms/:lang([a-z][a-z])/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/:lang([a-z][a-z])/nonogram/puzzle/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/:lang([a-z][a-z])/nonogram/:size(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/:lang([a-z][a-z])/:size(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/:lang([a-z][a-z])"
      component={NonogramPage}
    />
    <Route
      path="/nonograms/puzzle/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/puzzle/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/nonograms/:puzzleId(\d+)"
      component={NonogramPage}
    />
    <Route
      path="/:size(\d+)"
      component={NonogramPage}
    />
    <Route
      component={NonogramPage}
    />
  </Switch>
);

export default Router;
