import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import messages from 'intl/messages';
import MyTitle from 'features/home/components/MyTitle';
import PrefsForm, { PrefsValues } from './PrefsForm';
import { useSetPrefsMutation } from '../graphql/hooks';
import { selectUser } from '../../../redux/user-slice';

const PrefsPage: React.FC = () => {
  const me = useSelector(selectUser);
  const { setPrefsMutation, loading, data } = useSetPrefsMutation();

  const submit = React.useCallback((variables: PrefsValues) => {
    setPrefsMutation({ variables });
  }, [setPrefsMutation]);

  const initialValues =
    me ?
      {
        lang: me.lang || 'en',
        size: me.size || 5,
      }
    :
      undefined;
  return (
    <div className="container-fluid">
      <MyTitle message={messages.settings} />
      <h2 className="text-center">
        <FormattedMessage {...messages.settings} />
      </h2>
      <PrefsForm
        onSubmit={submit}
        initialValues={initialValues}
        loading={loading}
      />
      {data && data.setPrefs && (
        <div className="mt-3 alert alert-success" role="alert">
          <FormattedMessage {...messages.settingsUpdated} />
        </div>
      )}
    </div>
  );
};

export default PrefsPage;
