import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import MyHelmet from 'features/home/components/MyHelmet';
import { IS_DEV, AD_TEST } from 'config';

interface MyAdProps {
  slot: string;
}

declare global {
  interface Window {
    adsbygoogle?: any[];
  }
}

const MyAd: React.FC<MyAdProps> = ({
  slot,
}) => {
  React.useEffect(() => {
    if(window && (!IS_DEV || AD_TEST)) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <>
      <MyHelmet>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        />
      </MyHelmet>
      <div>
        <FormattedMessage {...messages.ad} />
      </div>
      <ins
        className="adsbygoogle"
        data-ad-client="ca-pub-1532657361966464"
        data-ad-slot={slot}
        data-ad-format="auto"
        data-adtest={IS_DEV && AD_TEST && 'on'}
        data-full-width-responsive="true"
      />
    </>
  );
};

export default MyAd;
