import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/user-slice';
import { useGetContactMe, useMarkContactMeAsRead } from '../graphql/hooks';
import { UserInfo } from '../../auth/graphql/types';

const isBarry = (me: UserInfo | null) => me && me.username === 'barry';

const ContactsPage: React.FC = () => {
  const me = useSelector(selectUser);
  const { getContactMe, loading, messages, refetch } = useGetContactMe();
  const { markContactMeAsRead } = useMarkContactMeAsRead();
  const sortedMessages = React.useMemo(() => {
    if (!messages) {
      return [];
    }
    return [...messages].sort((a, b) => {
      if (a.is_read && !b.is_read) {
        return 1;
      }
      if (!a.is_read && b.is_read) {
        return -1;
      }
      return b.inserted.localeCompare(a.inserted);
    });
  }, [messages]);

  React.useEffect(() => {
    if (!isBarry(me)) {
      return;
    }
    getContactMe();
  }, [me, getContactMe]);

  const markAsRead = React.useCallback(
    async (id: string) => {
      await markContactMeAsRead({ variables: { id } });
      refetch();
    },
    [markContactMeAsRead, refetch]
  );

  if (!isBarry(me)) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <table width="100%" className="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {sortedMessages.map((message) => (
            <tr key={message.id}>
              <td>
                {!message.is_read && (
                  <button
                    onClick={() => {
                      markAsRead(message.id);
                    }}
                    className="btn btn-primary btn-sm"
                  >
                    Mark as read
                  </button>
                )}
              </td>
              <td>{message.name}</td>
              <td>{message.email}</td>
              <td>{message.message}</td>
              <td>{message.inserted}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactsPage;
