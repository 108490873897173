import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import MyTitle from 'features/home/components/MyTitle';
import PasswordForm, { PasswordValues } from './PasswordForm';
import { useChangePasswordMutation } from '../graphql/hooks';

export const PasswordPage: React.FC = () => {
  const { changePasswordMutation, loading, data } = useChangePasswordMutation();

  const submit = React.useCallback((variables: PasswordValues) => {
    changePasswordMutation({
      variables: {
        pass: variables.password,
      }
    });
  }, [changePasswordMutation]);

  return (
    <div className="container-fluid">
      <MyTitle message={messages.changePassword} />
      <h2 className="text-center">
        <FormattedMessage {...messages.changePassword} />
      </h2>
      <PasswordForm onSubmit={submit} loading={loading} />
      {data && data.changePassword && (
        <div className="mt-3 alert alert-success" role="alert">
          <FormattedMessage {...messages.passwordUpdated} />
        </div>
      )}
    </div>
  );
};

export default PasswordPage;
