import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import ContactMeForm, { ContactMeFormValues } from './ContactMeForm';
import { useSendMessage } from '../graphql/hooks';

export const ContactMePage: React.FC = () => {
  const { sendMessage, loading, called } = useSendMessage();

  const submit = (variables: ContactMeFormValues) => {
    sendMessage({ variables });
  };

  return (
    <div className="container-fluid">
      <h2 className="text-center">
        <FormattedMessage {...messages.contactMe} />
      </h2>
      <ContactMeForm
        onSubmit={submit}
        loading={loading}
      />
      {called &&
        <FormattedMessage {...messages.sentMessage} />
      }
    </div>
  );
};

export default ContactMePage;
