import { useMutation } from '@apollo/client';
import { useAppDispatch } from '../../../redux/store';
import {
  CHANGE_PASSWORD,
  LOGIN,
  REGISTER,
  SET_PREFS,
} from './constants';
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  LoginMutation,
  LoginMutationVariables,
  RegisterMutation,
  RegisterMutationVariables,
  SetPrefsMutation,
  SetPrefsMutationVariables,
} from './types';
import { setUser } from '../../../redux/user-slice';

export const useLoginMutation = () => {
  const dispatch = useAppDispatch();
  const [loginMutation, { data, loading, error }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LOGIN, {
    update(_, { data }) {
      if (data?.login) {
        dispatch(setUser(data.login));
      }
    }
  });

  return { loginMutation, result: data, loading, error };
};

export const useRegisterMutation = () => {
  const dispatch = useAppDispatch();
  const [registerMutation, { data, loading, error, called }] = useMutation<
    RegisterMutation,
    RegisterMutationVariables
  >(REGISTER, {
    update(_, { data }) {
      if (data?.register) {
        dispatch(setUser(data.register));
      }
    }
  });

  return { registerMutation, data, loading, error, called };
};

export const useChangePasswordMutation = () => {
  const [changePasswordMutation, { data, loading, error }] = useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(CHANGE_PASSWORD);

  return { changePasswordMutation, data, loading, error };
};

export const useSetPrefsMutation = () => {
  const dispatch = useAppDispatch();
  const [setPrefsMutation, { data, loading, error }] = useMutation<
    SetPrefsMutation,
    SetPrefsMutationVariables
  >(SET_PREFS, {
    update(_, { data }) {
      if (data?.setPrefs) {
        dispatch(setUser(data.setPrefs));
      }
    }
  });

  return { setPrefsMutation, data, loading, error };
};

export const useLogoutMutation = () => {
  const dispatch = useAppDispatch();
  const logout = () => {
    dispatch(setUser(null));
  };

  return { logout };
};
