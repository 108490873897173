import { gql } from '@apollo/client';

const USER_FIELDS = `
  token
  username
  lang
  size
`;

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(user: $username, pass: $password) {
      ${USER_FIELDS}
    }
  }
`;

export const REGISTER = gql`
  mutation register($username: String!, $password: String!, $email: String!) {
    register(user: $username, pass: $password, email: $email) {
      ${USER_FIELDS}
    }
  }
`;

export const SET_PREFS = gql`
  mutation setPrefs($size: Int!, $lang: String!) {
    setPrefs(size: $size, lang: $lang) {
      ${USER_FIELDS}
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($pass: String!) {
    changePassword(pass: $pass)
  }
`;
