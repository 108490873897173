import React from 'react';
import { useIntl } from 'react-intl';

interface MyLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
}

const MyLink: React.FC<MyLinkProps> = ({
  href,
  children,
  ...props
}) => {
  const { locale } = useIntl();
  let calculatedLink = href;
  const langCheck = /\/..\//g;
  if (!href?.match(langCheck) && href?.startsWith('/')) {
    calculatedLink = `/${locale}${href}`;
  }
  return (
    <a {...props} href={calculatedLink}>{children}</a>
  );
};

export default MyLink;
