
interface Langs {
  [key: string]: string;
}

const langs: Langs = {
  'bg': 'Bulgarian',
  'cs': 'Czech',
  'da': 'Danish',
  'de': 'German',
  'el': 'Greek',
  'en': 'English',
  'es': 'Spanish',
  'et': 'Estonian',
  'fi': 'Finnish',
  'fr': 'French',
  'he': 'Hebrew',
  'hr': 'Croatian',
  'hu': 'Hungarian',
  'it': 'Italian',
  'ja': 'Japanese',
  'ko': 'Korean',
  'nb': 'Nowegian',
  'nl': 'Dutch',
  'pl': 'Polish',
  'pt': 'Portuguese',
  'ro': 'Romanian',
  'ru': 'Russian',
  'sk': 'Slovak',
  'sl': 'Slovene',
  'sr': 'Serbian',
  'sv': 'Swedish',
  'tr': 'Turkish',
  'vi': 'Vietnamese',
  'zh': 'Chinese'
};

export default langs;
