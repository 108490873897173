import React, { MouseEvent, TouchEvent } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import {
  PuzzleCellState,
  selectPuzzleAnswer,
} from '../../../redux/puzzle-slice';

interface PuzzleCellProps {
  x: number;
  y: number;
  className?: string;
  onMouseOver?: (x: number, y: number) => void;
  onMouseDown?: (evt: MouseEvent, x: number, y: number) => void;
  onMouseUp?: (evt: MouseEvent) => void;
  onTouchStart?: (evt: TouchEvent, x: number, y: number) => void;
  onTouchEnd?: (evt: TouchEvent) => void;
  onTouchMove?: (evt: TouchEvent) => void;
}

const PuzzleCell: React.FC<PuzzleCellProps> = ({
  x,
  y,
  className,
  onMouseOver,
  onMouseDown,
  onTouchStart,
  ...props
}) => {
  const answer = useSelector(selectPuzzleAnswer);
  const value = answer[x][y];

  const handleMouseOver = React.useCallback((evt: MouseEvent) => {
    if (onMouseOver) {
      onMouseOver(x, y);
    }
  }, [onMouseOver, x, y]);

  const handleMouseDown = React.useCallback((evt: MouseEvent) => {
    if (onMouseDown) {
      onMouseDown(evt, x, y);
    }
  }, [onMouseDown, x, y]);

  const handleTouchStart = React.useCallback((evt: TouchEvent) => {
    if (onTouchStart) {
      onTouchStart(evt, x, y);
    }
  }, [onTouchStart, x, y]);

  return (
    <td
      className={classnames(
        'puzzle-cell',
        className,
        value === PuzzleCellState.On && 'puzzle-cell-on',
        value === PuzzleCellState.Off && 'puzzle-cell-off',
      )}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      {...props}
      onContextMenu={e => e.preventDefault()}
      data-row={y}
      data-col={x}
    />
  );
};

export default PuzzleCell;
