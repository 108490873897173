import { ContextSetter, setContext } from '@apollo/client/link/context';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { GRAPHQL_URL } from 'config';
import { getUserInfo } from 'features/auth/utils';

const httpLink = new HttpLink({
  uri: GRAPHQL_URL
});
export const context: ContextSetter = (_, { headers }) => {
  const info = getUserInfo();
  if (!info) {
    return {
      headers
    };
  }
  const { token } = info;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
};
const authLink = setContext(context);
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});

export default client;
