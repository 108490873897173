import React from 'react';
import classnames from 'classnames';

interface InfoRowProps extends React.HTMLAttributes<HTMLDivElement> {
  label: React.ReactNode;
  value?: React.ReactNode;
  className?: string;
}

const InfoRow: React.FC<InfoRowProps> = ({
  className,
  label,
  value,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classnames('row justify-content-center mb-2', className)}
    >
      <div className="text-right col-6">{label}</div>
      <div className="col-6">{value}</div>
    </div>
  );
};

export default InfoRow;
