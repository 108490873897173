import React from 'react';
import classnames from 'classnames';

const NavDropdown: React.FC<{
  label: string;
  children: React.ReactNode;
}> = ({
  label,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const nodeRef = React.useRef<HTMLDivElement | null>(null);

  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    const handleClick = (e: Event) => {
      if (nodeRef?.current?.contains(e.target as Node)) {
        return;
      }
  
      if (isOpen) {
        setIsOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <div
      className={classnames('dropdown', isOpen ? ' show' : '')}
      ref={nodeRef}
    >
      <a
        aria-haspopup="true"
        href="#sizes"
        className="dropdown-toggle nav-link"
        aria-expanded={isOpen ? 'true' : 'false'}
        onClick={toggle}
      >
        {label}
      </a>
      <div
        className={classnames('dropdown-menu', isOpen ? ' show' : '')}
        aria-hidden={isOpen ? 'false' : 'true'}
      >
        {children}
      </div>
    </div>
  );
};

export default NavDropdown;
