import { gql } from '@apollo/client';

export const PUZZLE_BY_ID = gql`
  query PuzzleById($id: Int!) {
    puzzleById(id: $id) {
      topNumbers
      leftNumbers
      size
      id
      hash
    }
  }
`;

export const PUZZLE_BY_SIZE = gql`
  query PuzzleBySize($size: Int!) {
    puzzleBySize(size: $size) {
      topNumbers
      leftNumbers
      size
      id
      hash
    }
  }
`;

export const TOP_TIMES = gql`
  query topTimes {
    topTimes {
      size
      times {
        duration
        username
        puzzleId
      }
    }
  }
`;

export const SUBMIT_ANSWER = gql`
  mutation submitAnswer($hash: String!, $answer: [String!]!, $duration: Int!) {
    submitAnswer(hash: $hash, answer: $answer, duration: $duration)
  }
`;
