import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import MyLink from 'features/home/components/MyLink';
import MyTitle from 'features/home/components/MyTitle';
import { useLogoutMutation } from '../graphql/hooks';

const LogoutPage: React.FC = () => {
  const { logout } = useLogoutMutation();

  React.useEffect(() => {
    logout();
  }, [logout]);

  return (
    <div className="text-center">
      <MyTitle message={messages.logout} />
      <h2>
        <FormattedMessage {...messages.logout} />
      </h2>
      <div>
        <FormattedMessage {...messages.loggedOut} />
      </div>
      <div>
        <MyLink href="/">
          <FormattedMessage {...messages.returnToPuzzles} />
        </MyLink>
      </div>
    </div>
  );
};

export default LogoutPage;
