import React from 'react';
import { useSelector } from 'react-redux';
import { selectTimerStart, selectTimerStop } from '../../../redux/puzzle-slice';

const doubleDigits = (num: number) => (num < 10 ? `0${num}` : `${num}`);

const formatTime = (seconds: number) => {
  const h = Math.floor(seconds / 60 / 60);
  const m = Math.floor(seconds / 60) - h * 60;
  const s = seconds % 60;
  const res = `${doubleDigits(h)}:${doubleDigits(m)}:${doubleDigits(s)}`;
  return res;
};

const Timer: React.FC = () => {
  const [timer, setTimer] = React.useState('00:00:00');
  const [last, setLast] = React.useState(0);
  const start = useSelector(selectTimerStart);
  const stop = useSelector(selectTimerStop);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const time = stop > 0 ? stop : new Date().getTime();
      const diff = Math.floor((time - start) / 1000);
      if (diff !== last) {
        setTimer(formatTime(diff));
        setLast(diff);
      }
    }, 150);

    return () => clearInterval(interval);
  }, [start, stop, last]);

  return (
    <div>
      {timer}
    </div>
  );
};

export default Timer;
