import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import messages from 'intl/messages';
import MyTitle from 'features/home/components/MyTitle';
import RegisterForm, { RegisterValues } from './RegisterForm';
import { useRegisterMutation } from '../graphql/hooks';
import { selectUser } from '../../../redux/user-slice';

const RegisterPage: React.FC = () => {
  const { registerMutation, data, loading } = useRegisterMutation();
  const me = useSelector(selectUser);

  const error = data && data.register === null;

  const submit = React.useCallback((variables: RegisterValues) => {
    registerMutation({ variables });
  }, [registerMutation]);

  if (me && me.token) {
    return (
      <div className="text-center">
        <MyTitle message={messages.loggedIn} />
        <FormattedMessage {...messages.loggedIn} />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <MyTitle message={messages.register} />
      <h2 className="text-center">
        <FormattedMessage {...messages.register} />
      </h2>
      <RegisterForm onSubmit={submit} loading={loading} />
      {error && (
        <div className="text-center">
          <FormattedMessage {...messages.ajaxError} />
        </div>
      )}
      {data && data.register && data.register === null && (
        <div className="text-center">
          The username or email address is already in use.
        </div>
      )}
    </div>
  );
};

export default RegisterPage;
