import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserInfo } from '../features/auth/graphql/types';
import { RootState } from './store';
import { getUserInfo, storeUserInfo } from '../features/auth/utils';

interface UserSliceState {
  user: UserInfo | null;
}

const initialState: UserSliceState = {
  user: getUserInfo(),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserInfo | null>) {
      state.user = action.payload;
      storeUserInfo(action.payload);
    },
  },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;

export default userSlice.reducer;
