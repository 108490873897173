import React from 'react';
import Helmet from 'react-helmet';

interface OgInfo {
  ogTitle?: string;
  ogDesc?: string;
}

const getOgInfo = (children: React.ReactNode): OgInfo => {
  const res: OgInfo = {};
  if (!children) {
    return res;
  }
  if (Array.isArray(children)) {
    return children.reduce(
      (acc, rec) => Object.assign(acc, getOgInfo(rec)),
      res,
    );
  }
  if (typeof children !== 'object' || !('type' in children)) {
    return res;
  }
  const { type, props: { name, children: kids, content } } = children;
  if (type === 'title') {
    res.ogTitle = kids;
  } else if (type === 'meta' && name === 'description') {
    res.ogDesc = content;
  }
  return res;
};

const MyHelmet: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { ogTitle, ogDesc } = getOgInfo(children);
  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        {children}
        {ogTitle &&
          <meta
            property="og:title"
            content={ogTitle}
          />
        }
        {ogDesc &&
          <meta
            property="og:description"
            content={ogDesc}
          />
        }
      </Helmet>
    </>
  );
};

export default MyHelmet;
