import React, { FormEvent } from 'react';
import { FormFieldType } from './types';

interface FormFieldProps {
  type: FormFieldType;
  validate?: () => void;
  value?: any;
  onChange: (fieldName: string, value: any) => void;
  options?: any[];
  name: string;
  isNumeric?: boolean;
};

const FormField: React.FC<FormFieldProps> = ({
  type,
  validate,
  value,
  onChange,
  options,
  name,
  isNumeric,
}) => {

  const handleChange = React.useCallback(
    (
      e: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      if (type === 'checkbox') {
        return onChange(name, !value);
      } else if (isNumeric) {
        return onChange(name, parseInt(e.currentTarget.value, 10));
      } else {
        return onChange(name, e.currentTarget.value);
      }
    },
    [name, onChange, type, value, isNumeric]
  );
  
  if (type === 'text') {
    return (
      <input
        type="text"
        value={value || ''}
        onChange={handleChange}
        name={name}
      />
    );
  }

  if (type === 'password') {
    return (
      <input
        type="password"
        value={value || ''}
        onChange={handleChange}
        name={name}
      />
    );
  }

  if (type === 'number') {
    return (
      <input
        type="number"
        value={value || ''}
        onChange={handleChange}
        name={name}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <textarea onChange={handleChange} name={name}>{value || ''}</textarea>
    );
  }

  if (type === 'select') {
    return (
      <select onChange={handleChange} name={name} value={value}>
        {options?.map(option => (
          <option
            value={option.value}
            key={`${name}-${option.value}`}
          >
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  if (type === 'checkbox') {
    return (
      <input
        name={name}
        checked={value}
        onChange={handleChange}
        type="checkbox"
      />
    );
  }
};

export default FormField;
