import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import Form from 'features/barry-forms';
import { FormField } from 'features/barry-forms/types';

export interface ContactMeFormValues {
  name: string;
  email: string;
  msg: string;
}

const fields: FormField[] = [
  {
    type: 'text',
    name: 'name',
    label: <FormattedMessage {...messages.yourName} />,
  },
  {
    type: 'text',
    name: 'email',
    label: <FormattedMessage {...messages.yourEmail} />,
  },
  {
    type: 'textarea',
    name: 'msg',
    label: <FormattedMessage {...messages.message} />,
  },
];

export const validate = (values: ContactMeFormValues) => {
  const errors: Partial<ContactMeFormValues> = {};
  if (!values.name) {
    errors.name = 'Name is required';
  }
  if (!values.msg) {
    errors.msg = 'Message is required';
  }
  return errors;
};

interface ContactMeFormProps {
  onSubmit: (values: ContactMeFormValues) => void;
  loading?: boolean;
}

const ContactMeForm: React.FC<ContactMeFormProps> = ({
  onSubmit,
  loading,
}) => (
  <Form<ContactMeFormValues>
    onSubmit={onSubmit}
    fields={fields}
    validate={validate}
    submitLabel={<FormattedMessage {...messages.sendMessage} />}
    hideCancel
    loading={loading}
  />
);

export default ContactMeForm;
