import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import MyTitle from './MyTitle';

const HowToSolvePage: React.FC = () => (
  <div className="container-fluid">
    <MyTitle message={messages.howToSolveNonogram} />
    <h2 className="text-center">
      <FormattedMessage {...messages.howToSolveNonogram} />
    </h2>
    <h4 className="text-center">
      <FormattedMessage {...messages.objective} />
    </h4>
    <div className="row">
      <div className="col">
        <p>
          <FormattedMessage {...messages.nonoObjective} />
        </p>
      </div>
    </div>
    <h4 className="text-center">
      <FormattedMessage {...messages.controls} />
    </h4>
    <div className="row">
      <div className="col">
        <p>
          <FormattedMessage {...messages.nonoControlsLeft} />
        </p>
        <p>
          <FormattedMessage {...messages.nonoControlsRight} />
        </p>
        <p>
          <FormattedMessage {...messages.nonoControlsTouch} />
        </p>
        <p>
          <FormattedMessage {...messages.nonoControlsDrag} />
        </p>
      </div>
    </div>
  </div>
);

export default HowToSolvePage;
