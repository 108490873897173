const messages = {
  account: {
    id: 'account',
    defaultMessage: 'Account',
  },
  username: {
    id: 'username',
    defaultMessage: 'Username',
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading',
  },
  login: {
    id: 'login',
    defaultMessage: 'Login',
  },
  noAccount: {
    id: 'noAccount',
    defaultMessage: 'Don\'t have an account?',
  },
  createAccount: {
    id: 'createAccount',
    defaultMessage: 'Create an account',
  },
  required: {
    id: 'required',
    defaultMessage: 'Required',
  },
  loggedIn: {
    id: 'loggedIn',
    defaultMessage: 'You are logged in.',
  },
  logout: {
    id: 'logout',
    defaultMessage: 'Logout',
  },
  loggedOut: {
    id: 'loggedOut',
    defaultMessage: 'You are now logged out',
  },
  returnToPuzzles: {
    id: 'returnToPuzzles',
    defaultMessage: 'Return to Puzzles',
  },
  changePassword: {
    id: 'changePassword',
    defaultMessage: 'Change Password',
  },
  settings: {
    id: 'settings',
    defaultMessage: 'Settings',
  },
  settingsUpdated: {
    id: 'settingsUpdated',
    defaultMessage: 'Settings Updated',
  },
  register: {
    id: 'register',
    defaultMessage: 'Register',
  },
  forgotYourPassword: {
    id: 'forgotYourPassword',
    defaultMessage: 'Forgot Your Password?',
  },
  resetPassword: {
    id: 'resetPassword',
    defaultMessage: 'Reset Your Password',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  confirmPassword: {
    id: 'confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  passwordUpdated: {
    id: 'passwordUpdated',
    defaultMessage: 'Password Updated',
  },
  passwordsMustMatch: {
    id: 'passwordsMustMatch',
    defaultMessage: 'Passwords must match',
  },
  haveAnAccount: {
    id: 'haveAnAccount',
    defaultMessage: 'Already have an account?',
  },
  ajaxError: {
    id: 'ajaxError',
    defaultMessage: 'There was a problem. Try again later',
  },
  language: {
    id: 'language',
    defaultMessage: 'Language',
  },
  size: {
    id: 'size',
    defaultMessage: 'Size',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  invalidEmail: {
    id: 'invalidEmail',
    defaultMessage: 'Provide a valid email',
  },
  sendingEmail: {
    id: 'sendingEmail',
    defaultMessage: 'Sending email',
  },
  resetEmailSent: {
    id: 'resetEmailSent',
    defaultMessage: 'Check your email for the link to reset your password.',
  },
  ad: {
    id: 'ad',
    defaultMessage: 'Advertisement',
  },
  nonogramPuzzle: {
    id: 'nonogramPuzzle',
    defaultMessage: 'Nonogram Puzzle',
  },
  goodLuck: {
    id: 'goodLuck',
    defaultMessage: 'Good Luck!',
  },
  errorFound: {
    id: 'errorFound',
    defaultMessage: 'Error Found',
  },
  correct: {
    id: 'correct',
    defaultMessage: 'Correct!',
  },
  startOver: {
    id: 'startOver',
    defaultMessage: 'Start Over',
  },
  showCorrect: {
    id: 'showCorrect',
    defaultMessage: 'Show Correct Rows/Cols',
  },
  checkIt: {
    id: 'checkIt',
    defaultMessage: 'Check It',
  },
  newNonogram: {
    id: 'newNonogram',
    defaultMessage: 'New Nonogram',
  },
  topTimesFor: {
    id: 'topTimesFor',
    defaultMessage: 'Top Times for {size}x{size}',
  },
  noTimes: {
    id: 'noTimes',
    defaultMessage: 'No times to show',
  },
  user: {
    id: 'user',
    defaultMessage: 'User',
  },
  time: {
    id: 'time',
    defaultMessage: 'Time',
  },
  puzzle: {
    id: 'puzzle',
    defaultMessage: 'Puzzle',
  },
  nonogramSizes: {
    id: 'nonogramSizes',
    defaultMessage: 'Nonogram Sizes',
  },
  contactMe: {
    id: 'contactMe',
    defaultMessage: 'Contact Me',
  },
  howToSolve: {
    id: 'howToSolve',
    defaultMessage: 'How To Solve',
  },
  topTimes: {
    id: 'topTimes',
    defaultMessage: 'Top Times',
  },
  languages: {
    id: 'languages',
    defaultMessage: 'Languages',
  },
  yourName: {
    id: 'yourName',
    defaultMessage: 'Your Name',
  },
  yourEmail: {
    id: 'yourEmail',
    defaultMessage: 'Your Email',
  },
  message: {
    id: 'message',
    defaultMessage: 'Message',
  },
  sendingMessage: {
    id: 'sendingMessage',
    defaultMessage: 'Sending Message',
  },
  sendMessage: {
    id: 'sendMessage',
    defaultMessage: 'Send Message'
  },
  sentMessage: {
    id: 'sentMessage',
    defaultMessage: 'Message Sent',
  },
  bulgarian: {
    id: 'bulgarian',
    defaultMessage: 'Bulgarian',
  },
  czech: {
    id: 'czech',
    defaultMessage: 'Czech',
  },
  danish: {
    id: 'danish',
    defaultMessage: 'Danish',
  },
  german: {
    id: 'german',
    defaultMessage: 'German',
  },
  greek: {
    id: 'greek',
    defaultMessage: 'Greek',
  },
  english: {
    id: 'english',
    defaultMessage: 'English',
  },
  spanish: {
    id: 'spanish',
    defaultMessage: 'Spanish',
  },
  estonian: {
    id: 'estonian',
    defaultMessage: 'Estonian',
  },
  finnish: {
    id: 'finnish',
    defaultMessage: 'Finnish',
  },
  french: {
    id: 'french',
    defaultMessage: 'French',
  },
  hebrew: {
    id: 'hebrew',
    defaultMessage: 'Hebrew',
  },
  croatian: {
    id: 'croatian',
    defaultMessage: 'Croatian',
  },
  hungarian: {
    id: 'hungarian',
    defaultMessage: 'Hungarian',
  },
  italian: {
    id: 'italian',
    defaultMessage: 'Italian',
  },
  japanese: {
    id: 'japanese',
    defaultMessage: 'Japanese',
  },
  korean: {
    id: 'korean',
    defaultMessage: 'Korean',
  },
  norwegian: {
    id: 'norwegian',
    defaultMessage: 'Norwegian',
  },
  dutch: {
    id: 'dutch',
    defaultMessage: 'Dutch',
  },
  polish: {
    id: 'polish',
    defaultMessage: 'Polish',
  },
  portuguese: {
    id: 'portuguese',
    defaultMessage: 'Portuguese',
  },
  romanian: {
    id: 'romanian',
    defaultMessage: 'Romanian',
  },
  russian: {
    id: 'russian',
    defaultMessage: 'Russian',
  },
  slovak: {
    id: 'slovak',
    defaultMessage: 'Slovak',
  },
  slovenian: {
    id: 'slovenian',
    defaultMessage: 'Slovenian',
  },
  serbian: {
    id: 'serbian',
    defaultMessage: 'Serbian',
  },
  swedish: {
    id: 'swedish',
    defaultMessage: 'Swedish',
  },
  turkish: {
    id: 'turkish',
    defaultMessage: 'Turkish',
  },
  vietnamese: {
    id: 'vietnamese',
    defaultMessage: 'Vietnamese',
  },
  chinese: {
    id: 'chinese',
    defaultMessage: 'Chinese',
  },
  howToSolveNonogram: {
    id: 'howToSolveNonogram',
    defaultMessage: 'How to Solve a Nonogram',
  },
  objective: {
    id: 'objective',
    defaultMessage: 'Objective',
  },
  nonoObjective: {
    id: 'nonoObjective',
    defaultMessage:
      `The object of the game is to fill the grid with black squares
        so that the numbers of filled sequential squares match the numbers
        at the top and left.`,
  },
  controls: {
    id: 'controls',
    defaultMessage: 'Controls',
  },
  nonoControlsLeft: {
    id: 'nonoControlsLeft',
    defaultMessage:
      'Left click to mark a square as on (black) or unknown (white).',
  },
  nonoControlsRight: {
    id: 'nonoControlsRight',
    defaultMessage:
      'Right click to mark a square as off (red X) or unknown (white).',
  },
  nonoControlsTouch: {
    id: 'nonoControlsTouch',
    defaultMessage:
      'Touch to cycle a square as on (black), off (red X), or unknown (white).',
  },
  nonoControlsDrag: {
    id: 'nonoControlsDrag',
    defaultMessage: 'Dragging works for all three of the above.',
  },
  freecell: {
    id: 'freecell',
    defaultMessage: 'Freecell',
  },
  metaDescription: {
    id: 'metaDescription',
    defaultMessage: 'Nonograms Online. Over 500,000 puzzles in 29 languages.'
  },
  metaKeywords: {
    id: 'metaKeywords',
    defaultMessage:
      'nonogram, nonograms, picture, puzzle, griddlers, griddler, picross',
  },
  mobileApps: {
    id: 'mobileApps',
    defaultMessage: 'Mobile Apps',
  },
};

export default messages;
