import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import messages from 'intl/messages';
import MyLink from 'features/home/components/MyLink';
import NavSizes from './NavSizes';
import NavLangs from './NavLangs';
import NavAuth from './NavAuth';

const NavMain: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <nav className="navbar navbar-expand-md navbar-dark">
      <MyLink className="navbar-brand" href="/">
        Nonograms Online
      </MyLink>
      <button type="button" className="navbar-toggler" onClick={toggle}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={classnames(
          'collapse navbar-collapse',
          isOpen ? ' show' : ''
        )}
      >
        <ul className="ml-auto navbar-nav">
          <NavSizes />
          <NavLangs />
          <li className="nav-item">
            <MyLink
              className="nav-link"
              href="/apps"
            >
              <FormattedMessage {...messages.mobileApps} />
            </MyLink>
          </li>
          <li className="nav-item">
            <MyLink className="nav-link"  href="/how-to-solve">
              <FormattedMessage {...messages.howToSolve} />
            </MyLink>
          </li>
          <li className="nav-item">
            <MyLink className="nav-link" href="/top">
              <FormattedMessage {...messages.topTimes} />
            </MyLink>
          </li>
          <NavAuth />
          <li className="nav-item">
            <MyLink
              className="nav-link"
              href={
                "https://www.facebook.com/pages/Nonograms-Online/46749863722"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Fb
            </MyLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavMain;

