import { useSelector } from 'react-redux';
import { selectPuzzle } from '../redux/puzzle-slice';

export const useSendEvent = () => {
  const puzzle = useSelector(selectPuzzle);
  const sendEvent = (event: string, event_label?: string) => {
    if (puzzle && window.gtag) {
      const { id, size } = puzzle;
      const event_category = `Nonogram-${size}-${id}`;
      window.gtag(
        'event',
        event,
        {
          event_category,
          event_label,
        }
      );
    }
  };

  return sendEvent;
};
