import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import Form from 'features/barry-forms';
import { FormField } from 'features/barry-forms/types';

const fields: FormField[] = [
  {
    type: 'text',
    name: 'username',
    label: <FormattedMessage {...messages.username} />,
  },
  {
    type: 'password',
    name: 'password',
    label: <FormattedMessage {...messages.password} />,
  },
];

export interface LoginFormFields {
  username: string;
  password: string;
}

interface LoginFormValidationErrors {
  username?: React.ReactNode;
  password?: React.ReactNode;
}

export const validate = (values: LoginFormFields) => {
  const errors: LoginFormValidationErrors = {};
  if (!values.username) {
    errors.username = <FormattedMessage {...messages.required} />;
  }
  if (!values.password) {
    errors.password = <FormattedMessage {...messages.required} />;
  }
  return errors;
};

interface LoginFormProps {
  onSubmit: (values: LoginFormFields) => void;
  loading?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, ...props }) => (
  <Form<LoginFormFields>
    {...props}
    fields={fields}
    validate={validate}
    onSubmit={onSubmit}
    submitLabel={<FormattedMessage {...messages.login} />}
    hideCancel
  />
);

export default LoginForm;
