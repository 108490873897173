import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_CONTACT_ME,
  MARK_CONTACT_MESSAGE_READ,
  SEND_MESSAGE,
} from './constants';
import {
  GetContactMe,
  MarkContactMeAsRead,
  MarkContactMeAsReadVariables,
  SendMessage,
  SendMessageVariables,
} from './types';

export const useSendMessage = () => {
  const [sendMessage, { loading, error, called }] = useMutation<
    SendMessage,
    SendMessageVariables
  >(SEND_MESSAGE);

  return { sendMessage, loading, error, called };
};

export const useMarkContactMeAsRead = () => {
  const [markContactMeAsRead, { data }] = useMutation<
    MarkContactMeAsRead,
    MarkContactMeAsReadVariables
  >(MARK_CONTACT_MESSAGE_READ);

  return { markContactMeAsRead, data };
};

export const useGetContactMe = () => {
  const [getContactMe, { data, loading, refetch }] = useLazyQuery<
    GetContactMe
  >(GET_CONTACT_ME);

  return { getContactMe, messages: data?.getContactMe, loading, refetch };
};
