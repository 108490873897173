import React from 'react';
import { IntlProvider } from 'react-intl';
import localeData from 'intl/localeData';
import MyLink from 'features/home/components/MyLink';
import NavMain from './NavMain';
import HelmetHeaders from './HelmetHeaders';
import { getUserInfo } from '../../auth/utils';

const solveForLang = () => {
  const me = getUserInfo();
  const re_lang_url = /\/([a-z][a-z])\//;
  let match;
  let [lang] = (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language
  )
    .toLowerCase()
    .split(/[_-]+/);

  if (me && me.lang) {
    ({ lang } = me);
  }

  if (window && window.location && window.location.pathname) {
    match = re_lang_url.exec(window.location.pathname);
    if (match) {
      [, lang] = match;
    } else {
    }
  }
  if (!(lang in localeData)) {
    lang = 'en';
  }
  return {
    lang,
    messages: localeData[lang as keyof typeof localeData],
  };
};

const App: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const currentYear = (new Date()).getFullYear();
  const { lang, messages } = solveForLang();
  return (
    <IntlProvider locale={lang} messages={messages}>
      <HelmetHeaders />
      <div>
        <NavMain />
        <div className="container mt-4">{children}</div>
        <div className="container text-center mt-2 mb-2">
          Copyright &copy; 2007-{currentYear}
          {' - '}
          <MyLink href="/contact/">Barry Gilbert</MyLink>
          {' - '}
          <MyLink href="/privacy/">Privacy Policy</MyLink>
          {' - '}
          <MyLink href="/tos/">Terms of Service</MyLink>
        </div>
      </div>
    </IntlProvider>
  );
};

export default App;
