import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import messages from 'intl/messages';
import MyLink from 'features/home/components/MyLink';
import Loader from './Loader';
import Timer from './Timer';
import Puzzle from './Puzzle';
import { PuzzleQueryResult } from '../graphql/types';
import {
  resetPuzzle,
  selectHighlight,
  selectSolved,
  selectTimerStart,
  setHighlight,
} from '../../../redux/puzzle-slice';
import { useAppDispatch } from '../../../redux/store';
import { useSendEvent } from '../../../utils/events';
import { useSubmitAnswerMutation } from '../graphql/hooks';

interface PuzzleWrapperProps {
  info: PuzzleQueryResult
}

type Message = 'goodLuck' | 'correct' | 'errorFound';

const PuzzleWrapper: React.FC<PuzzleWrapperProps> = ({
  info: {
    loading,
    error,
    puzzle,
  },
}) => {
  const [message, setMessage] = React.useState<Message>('goodLuck');
  const highlight = useSelector(selectHighlight);
  const [solvedSent, setSolvedSent] = React.useState<boolean>(false);
  const solved = useSelector(selectSolved);
  const dispatch = useAppDispatch();
  const sendEvent = useSendEvent();
  const timerStart = useSelector(selectTimerStart);
  const { submitAnswer } = useSubmitAnswerMutation();
  
  const toggleHighlight = React.useCallback(() => {
    dispatch(setHighlight(!highlight));
  }, [dispatch, highlight]);

  const startOver = React.useCallback(() => {
    sendEvent('reset');
    setMessage('goodLuck');
    dispatch(resetPuzzle());
  }, [dispatch, sendEvent]);

  React.useEffect(() => {
    if (solved && !solvedSent) {
      setSolvedSent(true);
      setMessage('correct');

      sendEvent(`winner${highlight ? '-highlight' : ''}`);

      submitAnswer();
    }
  }, [
    dispatch,
    highlight,
    timerStart,
    solved,
    solvedSent,
    sendEvent,
    submitAnswer,
  ]);

  const newNonogram = React.useCallback(() => {
    sendEvent('new-puzzle');
    window.location.reload();
  }, [sendEvent]);

  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
        <Loader />
      </div>
    );
  }

  if (error || !puzzle) {
    return (
      <div>
        <FormattedMessage {...messages.ajaxError} />
      </div>
    );
  }

  return (
    <div className="text-center">
      <h1>
        <FormattedMessage {...messages.nonogramPuzzle} />
      </h1>
      <div>
        Nonogram #
        <MyLink href={`/nonogram/puzzle/${puzzle.id}`}>
          {puzzle.id}
        </MyLink>
      </div>
      <Timer />
      <div>
        <FormattedMessage {...messages[message]} />
      </div>
      <div>
        <Puzzle />
      </div>
      <div className="mt-2">
        <button
          className="btn btn-secondary mr-1 ml-1 mt-2"
          onClick={startOver}
        >
          <FormattedMessage {...messages.startOver} />
        </button>
        <button
          className="btn btn-secondary mr-1 ml-1 mt-2"
          onClick={newNonogram}
        >
          <FormattedMessage {...messages.newNonogram} />
        </button>
        <button
          className={classnames(
            'btn mt-2 mr-1 ml-1',
            highlight ? 'btn-success' : 'btn-danger',
          )}
          onClick={toggleHighlight}
        >
          <input
            type="checkbox"
            checked={highlight}
            onChange={toggleHighlight}
          />
          {' '}
          <FormattedMessage {...messages.showCorrect} />
        </button>
      </div>
    </div>
  );
};

export default PuzzleWrapper;
