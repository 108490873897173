import React from 'react';

const TermsOfServicePage: React.FC = () => (
  <div className="container">
    <h1>Terms & Conditions</h1>

    <p>
      By using the website, these terms will automatically apply to you &hyphen;
      you should make sure therefore that you read them carefully before using
      the website. You&apos;re not allowed to copy, or modify the website, any
      part of the website, or our trademarks in any way. You&apos;re not allowed
      to attempt to extract the source code of the website, and you also
      shouldn&apos;t try to translate the website into other languages or make
      derivative versions. The website itself, and all the trademarks,
      copyright, database rights and other intellectual property rights related
      to it, still belong to Bear and Rye, LLC.
    </p>

    <p>
      Bear and Rye, LLC is committed to ensuring that the website is as useful
      and efficient as possible. For that reason, we reserve the right to make
      changes to the website or to charge for its services, at any time and for
      any reason. We will never charge you for the website or its services
      without making it very clear to you exactly what you&apos;re paying for.
    </p>

    <p>
      The Nonograms Online website stores and processes personal data that you
      have provided to us, in order to provide our Service. It&apos;s your
      responsibility to keep your phone and access to the website secure. We
      therefore recommend that you do not jailbreak or root your phone, which
      is the process of removing software restrictions and limitations imposed
      by the official operating system of your device. It could make your phone
      vulnerable to malware/viruses/malicious programs, compromise your
      phone&apos;s security features and it could mean that the Nonograms
      Online website won&apos;t work properly or at all.
    </p>

    <p>
      The website does use third party services that declare their own Terms and
      Conditions.
    </p>

    <p>
      Link to Terms and Conditions of third party service providers used by the
      website
    </p>
      
    <p>
      You should be aware that there are certain things that Bear and Rye, LLC
      will not take responsibility for. Certain functions of the website will
      require the website to have an active internet connection. The connection
      can be Wi-Fi, or provided by your mobile network provider, but Bear and
      Rye, LLC cannot take responsibility for the website not working at full
      functionality if you don&apos;t have access to Wi-Fi, and you don&apos;t
      have any of your data allowance left.
    </p>

    <p>
      If you&apos;re using the website outside of an area with Wi-Fi, you should
      remember that your terms of the agreement with your mobile network
      provider will still apply. As a result, you may be charged by your mobile
      provider for the cost of data for the duration of the connection while
      accessing the website, or other third-party charges. In using the website,
      you&apos;re accepting responsibility for any such charges, including
      roaming data charges if you use the website outside of your home territory
      (i.e. region or country) without turning off data roaming. If you are not
      the bill payer for the device on which you&apos;re using the website,
      please be aware that we assume that you have received permission from the
      bill payer for using the website.
    </p>

    <p>
      Along the same lines, Bear and Rye, LLC cannot always take responsibility
      for the way you use the website i.e. You need to make sure that your
      device stays charged &hyphen; if it runs out of battery and you can&apos;t
      turn it on to avail the Service, Bear and Rye, LLC cannot accept
      responsibility.
    </p>

    <p>
      With respect to Bear and Rye, LLC&apos;s responsibility for your use of
      the website, when you&apos;re using the website, it&apos;s important to
      bear in mind that although we endeavor to ensure that it is updated and
      correct at all times, we do rely on third parties to provide information
      to us so that we can make it available to you. Bear and Rye, LLC accepts
      no liability for any loss, direct or indirect, you experience as a result
      of relying wholly on this functionality of the website.
    </p>

    <p>
      At some point, we may wish to update the website. The requirements for
      both the website (and for any additional systems we decide to extend the
      availability of the website to) may change. Bear and Rye, LLC does not
      promise that it will always update the website so that it is relevant to
      you and/or works with the website version. However, you promise to always
      accept updates to the application when offered to you. We may also wish to
      stop providing the website and may terminate use of it at any time without
      giving notice of termination to you. Unless we tell you otherwise, upon
      any termination, (a) the rights and licenses granted to you in these terms
      will end; (b) you must stop using the website, and (if needed) delete it
      from your device.
    </p>

    <h2>Changes to This Terms and Conditions</h2>

    <p>
      We may update our Terms and Conditions from time to time. Thus, you are
      advised to review this page periodically for any changes. We will notify
      you of any changes by posting the new Terms and Conditions on this page.
    </p>

    <p>
      These terms and conditions are effective as of 2021-08-10
    </p>
  </div>
);

export default TermsOfServicePage;
