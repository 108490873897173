import React from 'react';
import { useSelector } from 'react-redux';
import PuzzleWrapper from './PuzzleWrapper';
import { usePuzzleIdQuery, usePuzzleSizeQuery } from '../graphql/hooks';
import { useAppDispatch } from '../../../redux/store';
import { setPuzzle } from '../../../redux/puzzle-slice';
import { selectUser } from '../../../redux/user-slice';

interface PuzzleByIdProps {
  id: number;
}

export const PuzzleById: React.FC<PuzzleByIdProps> = ({ id }) => {
  const info = usePuzzleIdQuery(id);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (info.puzzle) {
      dispatch(setPuzzle(info.puzzle));
    }
  }, [info.puzzle, dispatch]);
  
  return <PuzzleWrapper info={info} />;
};

interface PuzzleBySizeProps {
  size: number;
}

export const PuzzleBySize: React.FC<PuzzleBySizeProps> = ({ size }) => {
  const info = usePuzzleSizeQuery(size);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (info.puzzle) {
      dispatch(setPuzzle(info.puzzle));
    }
  }, [info.puzzle, dispatch]);

  return <PuzzleWrapper info={info} />;
};

interface PuzzleQueryProps {
  id?: number;
  size?: number;
}

const PuzzleQuery: React.FC<PuzzleQueryProps> = ({
  id,
  size,
}) => {
  const user = useSelector(selectUser);
  if (id) {
    return <PuzzleById id={id} />;
  }
  if (size) {
    return <PuzzleBySize size={size} />;
  }
  if (user) {
    return <PuzzleBySize size={user.size} />;
  }
  return <PuzzleBySize size={10} />;
};

export default PuzzleQuery;
