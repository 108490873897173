
const localeData = {
  "bg": {
    "account": "Сметка",
    "username": "Потребител",
    "password": "парола",
    "loading": "Зареждане",
    "login": "Влизам",
    "noAccount": "Нямате профил?",
    "createAccount": "Регистрирайте се тук",
    "required": "длъжен",
    "loggedIn": "Влезли сте в системата!",
    "logout": "Излез от профила си",
    "loggedOut": "Вече сте излезли",
    "returnToPuzzles": "Върни се към пъзелите",
    "changePassword": "Промяна на паролата",
    "settings": "Настройки",
    "settingsUpdated": "Настройките обновени",
    "register": "Регистрирам",
    "forgotYourPassword": "Забравена парола?",
    "resetPassword": "Променете паролата си",
    "email": "електронна поща",
    "confirmPassword": "Потвърди парола",
    "passwordUpdated": "Актуализирана парола",
    "passwordsMustMatch": "паролите трябва да съвпадат",
    "haveAnAccount": "Вече имате профил?",
    "ajaxError": "Имаше проблем. Опитайте отново по-късно",
    "language": "език",
    "size": "размер",
    "save": "Запази",
    "invalidEmail": "Въведете валиден имейл",
    "sendingEmail": "Изпращане на имейл",
    "resetEmailSent": "Проверете електронната си поща за връзката, за да зададете нова парола.",
    "ad": "реклама",
    "nonogramPuzzle": "Nonogram Puzzle",
    "goodLuck": "Късмет!",
    "errorFound": "Намерени грешки",
    "correct": "Правилно !!",
    "startOver": "Започни отначало",
    "showCorrect": "Показване на правилните редове/колони",
    "checkIt": "Провери го",
    "newNonogram": "Нов Nonogram",
    "topTimesFor": "Големи времена за {size} x {size}",
    "noTimes": "Няма време за показване",
    "user": "потребител",
    "time": "път",
    "puzzle": "пъзели",
    "nonogramSizes": "Неразмерни размери",
    "contactMe": "Свържи се с мен",
    "howToSolve": "Как да решим",
    "topTimes": "Top Times",
    "languages": "Езици",
    "yourName": "Твоето име",
    "yourEmail": "Твоят имейл",
    "message": "съобщение",
    "sendingMessage": "Изпращане на съобщение",
    "sendMessage": "Изпрати съобщение",
    "sentMessage": "Съобщението е изпратено",
    "bulgarian": "български",
    "czech": "чешки",
    "danish": "датски",
    "german": "Немски",
    "greek": "Гръцки",
    "english": "Английски",
    "spanish": "испански",
    "estonian": "естонски",
    "finnish": "фински",
    "french": "Френски",
    "hebrew": "иврит",
    "croatian": "хърватски",
    "hungarian": "унгарски",
    "italian": "Италиански",
    "japanese": "японски",
    "korean": "корейски",
    "norwegian": "норвежки",
    "dutch": "холандски",
    "polish": "полски",
    "portuguese": "португалски",
    "romanian": "румънски",
    "russian": "Руски",
    "slovak": "словашки",
    "slovenian": "словенски",
    "serbian": "сръбски",
    "swedish": "шведски",
    "turkish": "Турски",
    "vietnamese": "виетнамски",
    "chinese": "Китайски",
    "howToSolveNonogram": "Как да решим Nonogram",
    "objective": "Обективен",
    "nonoObjective": "Целта на играта е да запълни мрежата с черни квадрати, така че броят на запълнените последователни квадрати да съответства на номерата в горната част и вляво.",
    "controls": "Контроли",
    "nonoControlsLeft": "Кликнете с левия бутон, за да маркирате квадрат като (черен) или неизвестен (бял).",
    "nonoControlsRight": "Кликнете с десния бутон, за да маркирате квадрат като изключен (червен X) или неизвестен (бял).",
    "nonoControlsTouch": "Докоснете, за да превъртите квадратично (черно), изключено (червено X) или неизвестно (бяло).",
    "nonoControlsDrag": "Плъзгането работи за всичките три по-горе.",
    "freecell": "Freecell",
    "metaDescription": "Нонограми онлайн. Над 500 000 пъзела на 29 езика.",
    "metaKeywords": "nonogram, nonograms, картина, пъзел, griddlers, griddler, picross",
    "mobileApps": "Мобилни приложения"
  },
  "cs": {
    "account": "Účet",
    "username": "Uživatelské jméno",
    "password": "Heslo",
    "loading": "načítání",
    "login": "Přihlásit se",
    "noAccount": "Nemáte účet?",
    "createAccount": "Registrujte se zde",
    "required": "Povinný",
    "loggedIn": "Jste přihlášeni!",
    "logout": "Odhlásit se",
    "loggedOut": "Nyní jste odhlášeni",
    "returnToPuzzles": "Vraťte se k hádankám",
    "changePassword": "Změnit heslo",
    "settings": "Nastavení",
    "settingsUpdated": "Nastavení aktualizováno",
    "register": "Registrovat",
    "forgotYourPassword": "Zapomněli jste heslo?",
    "resetPassword": "Obnovit heslo",
    "email": "E-mailem",
    "confirmPassword": "Potvrďte heslo",
    "passwordUpdated": "Aktualizováno heslo",
    "passwordsMustMatch": "Heslo se musí shodovat",
    "haveAnAccount": "Máte již účet?",
    "ajaxError": "Nastal problém. Zkuste to později znovu",
    "language": "Jazyk",
    "size": "Velikost",
    "save": "Uložit",
    "invalidEmail": "Zadejte platný e-mail",
    "sendingEmail": "Odesílání e-mailů",
    "resetEmailSent": "Ověřte svůj e-mail pro odkaz a obnovte heslo.",
    "ad": "reklama",
    "nonogramPuzzle": "Nonogram Puzzle",
    "goodLuck": "Hodně štěstí!",
    "errorFound": "Byly nalezeny chyby",
    "correct": "Opravit!!",
    "startOver": "Začít znovu",
    "showCorrect": "Zobrazit správné řádky/sloupce",
    "checkIt": "Zkontroluj to",
    "newNonogram": "Nový nonogram",
    "topTimesFor": "Nejvyšší časy pro {size} x {size}",
    "noTimes": "Žádné časy, které by se ukázaly",
    "user": "Uživatel",
    "time": "Čas",
    "puzzle": "Hádanka",
    "nonogramSizes": "Nonogram velikosti",
    "contactMe": "Kontaktujte mě",
    "howToSolve": "Jak vyřešit",
    "topTimes": "Nejvyšší časy",
    "languages": "Jazyky",
    "yourName": "Tvé jméno",
    "yourEmail": "Tvůj e-mail",
    "message": "Zpráva",
    "sendingMessage": "Odeslání zprávy",
    "sendMessage": "Poslat zprávu",
    "sentMessage": "Zpráva odeslána",
    "bulgarian": "bulharština",
    "czech": "čeština",
    "danish": "dánský",
    "german": "Němec",
    "greek": "řecký",
    "english": "Angličtina",
    "spanish": "španělština",
    "estonian": "estonština",
    "finnish": "Finština",
    "french": "francouzština",
    "hebrew": "hebrejština",
    "croatian": "chorvatský",
    "hungarian": "maďarský",
    "italian": "italština",
    "japanese": "japonský",
    "korean": "korejština",
    "norwegian": "Norština",
    "dutch": "holandský",
    "polish": "polština",
    "portuguese": "portugalština",
    "romanian": "rumunština",
    "russian": "ruština",
    "slovak": "Slovák",
    "slovenian": "slovinský",
    "serbian": "srbština",
    "swedish": "švédský",
    "turkish": "turečtina",
    "vietnamese": "vietnamština",
    "chinese": "čínština",
    "howToSolveNonogram": "Jak vyřešit Nonogram",
    "objective": "Objektivní",
    "nonoObjective": "Cílem hry je vyplnit mřížku s černými čtverci tak, aby čísla vyplněných sekvenčních čtverců odpovídala číslům v horní a vlevo.",
    "controls": "Řízení",
    "nonoControlsLeft": "Klepnutím levým tlačítkem označte čtverec jako (černý) nebo neznámý (bílý).",
    "nonoControlsRight": "Klepnutím pravým tlačítkem označte čtverec jako vypnutý (červený X) nebo neznámý (bílý).",
    "nonoControlsTouch": "Dotkněte se, chcete-li vykreslit čtvereček jako na (černé), vypnout (červená X) nebo neznámé (bílé).",
    "nonoControlsDrag": "Tažení funguje pro všechny tři výše uvedené.",
    "freecell": "Freecell",
    "metaDescription": "Nonogramy online. Více než 500 000 hádanek v 29 jazycích.",
    "metaKeywords": "nonogram, nonograms, picture, puzzle, griddlers, griddler, picross",
    "mobileApps": "Mobilní aplikace"
  },
  "da": {
    "account": "Konto",
    "username": "Brugernavn",
    "password": "Adgangskode",
    "loading": "Indlæser",
    "login": "Log på",
    "noAccount": "Har du ikke en konto?",
    "createAccount": "Tilmeld dig her",
    "required": "Nødvendig",
    "loggedIn": "Du er logget ind!",
    "logout": "Log ud",
    "loggedOut": "Du er nu logget ud",
    "returnToPuzzles": "Gå tilbage til gåderne",
    "changePassword": "Skift kodeord",
    "settings": "Indstillinger",
    "settingsUpdated": "Indstillinger opdateret",
    "register": "Tilmeld",
    "forgotYourPassword": "Glemt din adgangskode?",
    "resetPassword": "Nulstil din adgangskode",
    "email": "E-mail",
    "confirmPassword": "Bekræft kodeord",
    "passwordUpdated": "Adgangskode opdateret",
    "passwordsMustMatch": "kodeordene skal være ens",
    "haveAnAccount": "har du allerede en bruger?",
    "ajaxError": "Der var et problem. Prøv igen senere",
    "language": "Sprog",
    "size": "Størrelse",
    "save": "Gemme",
    "invalidEmail": "Giv en gyldig email",
    "sendingEmail": "Afsendelse af e-mail",
    "resetEmailSent": "Tjek din email for linket for at nulstille din adgangskode.",
    "ad": "Reklame",
    "nonogramPuzzle": "Nonogram puslespil",
    "goodLuck": "Held og lykke!",
    "errorFound": "Fejl fundet",
    "correct": "Korrekt!!",
    "startOver": "Start forfra",
    "showCorrect": "Vis korrekte rækker/kolonner",
    "checkIt": "Tjekke det",
    "newNonogram": "Nyt nonogram",
    "topTimesFor": "Toptider for {size} x {size}",
    "noTimes": "Ingen gange at vise",
    "user": "Bruger",
    "time": "Tid",
    "puzzle": "Gåde",
    "nonogramSizes": "Nonogram størrelser",
    "contactMe": "Kontakt mig",
    "howToSolve": "Hvordan man løser",
    "topTimes": "Top Times",
    "languages": "Sprog",
    "yourName": "Dit navn",
    "yourEmail": "Din email",
    "message": "Besked",
    "sendingMessage": "Afsendelse af besked",
    "sendMessage": "Send besked",
    "sentMessage": "Besked sendt",
    "bulgarian": "bulgarsk",
    "czech": "tjekkisk",
    "danish": "dansk",
    "german": "tysk",
    "greek": "græsk",
    "english": "engelsk",
    "spanish": "spansk",
    "estonian": "estisk",
    "finnish": "Finsk",
    "french": "fransk",
    "hebrew": "Hebrew",
    "croatian": "Kroatisk",
    "hungarian": "ungarsk",
    "italian": "italiensk",
    "japanese": "japansk",
    "korean": "Korean",
    "norwegian": "Norsk",
    "dutch": "hollandske",
    "polish": "Polere",
    "portuguese": "portugisisk",
    "romanian": "rumænsk",
    "russian": "Russisk",
    "slovak": "slovakisk",
    "slovenian": "slovensk",
    "serbian": "serbisk",
    "swedish": "Svensk",
    "turkish": "tyrkisk",
    "vietnamese": "Vietnamesisk",
    "chinese": "kinesisk",
    "howToSolveNonogram": "Sådan løses et nonogram",
    "objective": "Objektiv",
    "nonoObjective": "Formålet med spillet er at udfylde gitteret med sorte firkanter, så antallet af fyldte sekventielle firkanter matcher tallene øverst og venstre.",
    "controls": "Controls",
    "nonoControlsLeft": "Venstre klik for at markere en firkant som på (sort) eller ukendt (hvid).",
    "nonoControlsRight": "Højreklik for at markere en firkant som slukket (rødt X) eller ukendt (hvidt).",
    "nonoControlsTouch": "Tryk for at cykle en firkant som på (sort), slukket (rød X) eller ukendt (hvid).",
    "nonoControlsDrag": "Trækværk for alle tre af ovenstående.",
    "freecell": "Freecell",
    "metaDescription": "Nonograms online. Over 500.000 gåder på 29 sprog.",
    "metaKeywords": "nonogram, nonograms, billede, puslespil, griddlers, griddler, picross",
    "mobileApps": "Mobile Apps"
  },
  "de": {
    "account": "Konto",
    "username": "Nutzername",
    "password": "Passwort",
    "loading": "Wird geladen",
    "login": "Anmeldung",
    "noAccount": "Ich habe noch kein Konto",
    "createAccount": "Hier registrieren",
    "required": "Erforderlich",
    "loggedIn": "Du bist eingeloggt!",
    "logout": "Ausloggen",
    "loggedOut": "Sie sind jetzt ausgeloggt",
    "returnToPuzzles": "Kehre zu den Rätseln zurück",
    "changePassword": "Ändere das Passwort",
    "settings": "die Einstellungen",
    "settingsUpdated": "Einstellungen aktualisiert",
    "register": "Registrieren",
    "forgotYourPassword": "Haben Sie Ihr Passwort vergessen?",
    "resetPassword": "Setze dein Passwort zurück",
    "email": "Email",
    "confirmPassword": "Passwort bestätigen",
    "passwordUpdated": "Passwort aktualisiert",
    "passwordsMustMatch": "Die Passwörter müssen übereinstimmen",
    "haveAnAccount": "Hast du schon ein Konto?",
    "ajaxError": "Es gab ein Problem. Versuchen Sie es später noch einmal",
    "language": "Sprache",
    "size": "Größe",
    "save": "sparen",
    "invalidEmail": "Geben Sie eine gültige E-Mail-Adresse an",
    "sendingEmail": "Email schicken",
    "resetEmailSent": "Überprüfen Sie Ihre E-Mail nach dem Link, um Ihr Passwort zurückzusetzen.",
    "ad": "Werbung",
    "nonogramPuzzle": "Nonogram Puzzle",
    "goodLuck": "Viel Glück!",
    "errorFound": "Fehler gefunden",
    "correct": "Richtig!!",
    "startOver": "Von vorn anfangen",
    "showCorrect": "Richtige Zeilen/Spalten anzeigen",
    "checkIt": "Prüfen Sie",
    "newNonogram": "Neues Nonogramm",
    "topTimesFor": "Top-Zeiten für {Größe} x {Größe}",
    "noTimes": "Keine Zeiten zu zeigen",
    "user": "Nutzer",
    "time": "Zeit",
    "puzzle": "Puzzle",
    "nonogramSizes": "Nonogram-Größen",
    "contactMe": "Kontaktiere mich",
    "howToSolve": "Wie löst man",
    "topTimes": "Top-Zeiten",
    "languages": "Sprachen",
    "yourName": "Dein Name",
    "yourEmail": "Deine E-Mail",
    "message": "Botschaft",
    "sendingMessage": "Schicke Nachricht",
    "sendMessage": "Nachricht senden",
    "sentMessage": "Nachricht gesendet",
    "bulgarian": "bulgarisch",
    "czech": "Tschechisch",
    "danish": "dänisch",
    "german": "Deutsche",
    "greek": "griechisch",
    "english": "Englisch",
    "spanish": "Spanisch",
    "estonian": "estnisch",
    "finnish": "finnisch",
    "french": "Französisch",
    "hebrew": "Hebräisch",
    "croatian": "kroatisch",
    "hungarian": "ungarisch",
    "italian": "Italienisch",
    "japanese": "japanisch",
    "korean": "Koreanisch",
    "norwegian": "norwegisch",
    "dutch": "Niederländisch",
    "polish": "Polieren",
    "portuguese": "Portugiesisch",
    "romanian": "rumänisch",
    "russian": "Russisch",
    "slovak": "slowakisch",
    "slovenian": "Slowenisch",
    "serbian": "serbisch",
    "swedish": "Schwedisch",
    "turkish": "Türkisch",
    "vietnamese": "Vietnamesisch",
    "chinese": "Chinesisch",
    "howToSolveNonogram": "So lösen Sie ein Nonogramm",
    "objective": "Zielsetzung",
    "nonoObjective": "Das Ziel des Spiels ist es, das Gitter mit schwarzen Quadraten zu füllen, so dass die Anzahl der gefüllten sequentiellen Quadrate mit den Zahlen oben und links übereinstimmt.",
    "controls": "Steuerelemente",
    "nonoControlsLeft": "Klicken Sie mit der linken Maustaste, um ein Quadrat als (schwarz) oder als unbekannt (weiß) zu markieren.",
    "nonoControlsRight": "Klicken Sie mit der rechten Maustaste, um ein Quadrat als deaktiviert (rotes X) oder unbekannt (weiß) zu markieren.",
    "nonoControlsTouch": "Berühren Sie, um ein Quadrat als Ein (Schwarz), Aus (Rotes X) oder Unbekanntes (Weiß) zu durchlaufen.",
    "nonoControlsDrag": "Ziehen funktioniert für alle drei der oben genannten.",
    "freecell": "Freie Zelle",
    "metaDescription": "Nonogramme Online. Über 500.000 Rätsel in 29 Sprachen.",
    "metaKeywords": "nonogramm, nonogramme, bild, puzzle, griddlers, griddler, picross",
    "mobileApps": "Mobile Apps"
  },
  "el": {
    "account": "λογαριασμός",
    "username": "Όνομα χρήστη",
    "password": "Κωδικός πρόσβασης",
    "loading": "Φόρτωση",
    "login": "Σύνδεση",
    "noAccount": "Δεν έχετε λογαριασμό;",
    "createAccount": "Εγγραφείτε εδώ",
    "required": "Απαιτείται",
    "loggedIn": "Είστε συνδεδεμένοι!",
    "logout": "Αποσυνδέση",
    "loggedOut": "Τώρα αποσυνδεθείτε",
    "returnToPuzzles": "Επιστρέψτε στο παζλ",
    "changePassword": "Άλλαξε κωδικό",
    "settings": "Ρυθμίσεις",
    "settingsUpdated": "Οι ρυθμίσεις ενημερώθηκαν",
    "register": "Κανω ΕΓΓΡΑΦΗ",
    "forgotYourPassword": "Ξεχάσατε τον κωδικό σας?",
    "resetPassword": "Επαναφορά του κωδικού πρόσβασής σας",
    "email": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
    "confirmPassword": "Επιβεβαίωση Κωδικού",
    "passwordUpdated": "Ο κωδικός είναι ενημερωμένος",
    "passwordsMustMatch": "οι κωδικοί πρέπει να ταιριάζουν",
    "haveAnAccount": "Έχετε ήδη λογαριασμό?",
    "ajaxError": "Υπήρξε ένα πρόβλημα. Δοκιμάστε ξανά αργότερα",
    "language": "Γλώσσα",
    "size": "Μέγεθος",
    "save": "Αποθηκεύσετε",
    "invalidEmail": "Δώστε έγκυρο μήνυμα ηλεκτρονικού ταχυδρομείου",
    "sendingEmail": "Αποστολή μηνύματος ηλεκτρονικού ταχυδρομείου",
    "resetEmailSent": "Ελέγξτε το μήνυμα ηλεκτρονικού ταχυδρομείου για τη σύνδεση για να επαναφέρετε τον κωδικό πρόσβασής σας",
    "ad": "Διαφήμιση",
    "nonogramPuzzle": "Πλανόγραμμα Nonogram",
    "goodLuck": "Καλή τύχη!",
    "errorFound": "Βρέθηκαν σφάλματα",
    "correct": "Σωστός!!",
    "startOver": "Ξεκίνα από την αρχή",
    "showCorrect": "Εμφάνιση των σωστών σειρών/στελών",
    "checkIt": "Ελεγξέ το",
    "newNonogram": "Νέο Nonogram",
    "topTimesFor": "Κορυφαίοι χρόνοι για {size} x {size}",
    "noTimes": "Δεν υπάρχουν στιγμές για εμφάνιση",
    "user": "Χρήστης",
    "time": "χρόνος",
    "puzzle": "Παζλ",
    "nonogramSizes": "Μεγέθη Nonogram",
    "contactMe": "Επικοινώνησε μαζί μου",
    "howToSolve": "Πώς να λύσετε",
    "topTimes": "Κορυφαίοι χρόνοι",
    "languages": "Γλώσσες",
    "yourName": "Το όνομα σου",
    "yourEmail": "Η διεύθυνση του ηλεκτρονικού σου ταχυδρομείου",
    "message": "Μήνυμα",
    "sendingMessage": "Αποστολή μηνύματος",
    "sendMessage": "Να στείλετε μήνυμα",
    "sentMessage": "Το μήνυμα στάλθηκε",
    "bulgarian": "Βούλγαρος",
    "czech": "Τσέχος",
    "danish": "δανικός",
    "german": "Γερμανός",
    "greek": "Ελληνικά",
    "english": "Αγγλικά",
    "spanish": "Ισπανικά",
    "estonian": "Εσθονικά",
    "finnish": "φινλανδικός",
    "french": "γαλλική γλώσσα",
    "hebrew": "Εβραϊκά",
    "croatian": "Κροατία",
    "hungarian": "ουγγρικός",
    "italian": "ιταλικός",
    "japanese": "Ιαπωνικά",
    "korean": "κορεάτης",
    "norwegian": "Νορβηγός",
    "dutch": "Ολλανδός",
    "polish": "Στίλβωση",
    "portuguese": "Πορτογαλικά",
    "romanian": "ρουμανικός",
    "russian": "Ρωσική",
    "slovak": "Σλοβάκος",
    "slovenian": "Σλοβενικά",
    "serbian": "Σέρβος",
    "swedish": "Σουηδικά",
    "turkish": "τούρκικος",
    "vietnamese": "Βιετναμέζοι",
    "chinese": "κινέζικα",
    "howToSolveNonogram": "Πώς να λύσετε ένα μηγραμμό",
    "objective": "Σκοπός",
    "nonoObjective": "Το αντικείμενο του παιχνιδιού είναι να γεμίσει το πλέγμα με μαύρα τετράγωνα έτσι ώστε οι αριθμοί των γεμάτων διαδοχικά τετράγωνα να ταιριάζουν με τους αριθμούς στην κορυφή και στο αριστερό.",
    "controls": "Έλεγχοι",
    "nonoControlsLeft": "Αριστερό κλικ για να επισημάνετε ένα τετράγωνο ως ενεργοποιημένο (μαύρο) ή άγνωστο (λευκό).",
    "nonoControlsRight": "Κάντε δεξί κλικ για να επισημάνετε ένα τετράγωνο ως απενεργοποιημένο (κόκκινο Χ) ή άγνωστο (λευκό).",
    "nonoControlsTouch": "Αγγίξτε για κύλιση ενός τετραγώνου όπως είναι ενεργοποιημένο (μαύρο), απενεργοποιημένο (κόκκινο Χ) ή άγνωστο (λευκό).",
    "nonoControlsDrag": "Το dragging λειτουργεί και για τα τρία παραπάνω.",
    "freecell": "Freecell",
    "metaDescription": "Nonograms Online. Πάνω από 500.000 παζλ σε 29 γλώσσες.",
    "metaKeywords": "nonogram, nonograms, εικόνα, παζλ, griddlers, griddler, picross",
    "mobileApps": "Εφαρμογές για κινητά"
  },
  "es": {
    "account": "Cuenta",
    "username": "Nombre de usuario",
    "password": "Contraseña",
    "loading": "Cargando",
    "login": "Iniciar sesión",
    "noAccount": "¿No tienes una cuenta?",
    "createAccount": "Registrar aquí",
    "required": "Necesario",
    "loggedIn": "Has iniciado sesión!",
    "logout": "Cerrar sesión",
    "loggedOut": "ahora estás registrado",
    "returnToPuzzles": "Volver a los puzzles",
    "changePassword": "Cambia la contraseña",
    "settings": "Ajustes",
    "settingsUpdated": "Ajustes actualizan",
    "register": "Registro",
    "forgotYourPassword": "¿Olvidaste tu contraseña?",
    "resetPassword": "Restablecer su contraseña",
    "email": "Email",
    "confirmPassword": "Confirmar contraseña",
    "passwordUpdated": "Contraseña actualiza",
    "passwordsMustMatch": "Las contraseñas deben coincidir",
    "haveAnAccount": "¿Ya tienes una cuenta?",
    "ajaxError": "Había un problema. Inténtalo más tarde",
    "language": "Idioma",
    "size": "tamaño",
    "save": "Salvar",
    "invalidEmail": "Proporcionar un correo electrónico válido",
    "sendingEmail": "Enviando email",
    "resetEmailSent": "Revise su correo electrónico para el enlace para restablecer su contraseña.",
    "ad": "Anuncio",
    "nonogramPuzzle": "Rompecabezas Nonogram",
    "goodLuck": "¡Buena suerte!",
    "errorFound": "Errores encontrados",
    "correct": "¡¡Correcto!!",
    "startOver": "Comenzar de nuevo",
    "showCorrect": "Mostrar filas/columnas correctas",
    "checkIt": "Revisalo",
    "newNonogram": "Nuevo nonograma",
    "topTimesFor": "Los mejores tiempos para {tamaño} x {tamaño}",
    "noTimes": "No hay tiempos para mostrar",
    "user": "Usuario",
    "time": "Hora",
    "puzzle": "Rompecabezas",
    "nonogramSizes": "Tamaños Nonogram",
    "contactMe": "Contáctame",
    "howToSolve": "Cómo resolver",
    "topTimes": "Top Times",
    "languages": "Idiomas",
    "yourName": "Tu nombre",
    "yourEmail": "Tu correo electrónico",
    "message": "Mensaje",
    "sendingMessage": "Enviando mensaje",
    "sendMessage": "Enviar mensaje",
    "sentMessage": "Mensaje enviado",
    "bulgarian": "búlgaro",
    "czech": "checo",
    "danish": "danés",
    "german": "alemán",
    "greek": "griego",
    "english": "Inglés",
    "spanish": "Español",
    "estonian": "Estonio",
    "finnish": "finlandés",
    "french": "francés",
    "hebrew": "hebreo",
    "croatian": "croata",
    "hungarian": "húngaro",
    "italian": "italiano",
    "japanese": "japonés",
    "korean": "coreano",
    "norwegian": "noruego",
    "dutch": "holandés",
    "polish": "polaco",
    "portuguese": "portugués",
    "romanian": "rumano",
    "russian": "ruso",
    "slovak": "eslovaco",
    "slovenian": "esloveno",
    "serbian": "serbio",
    "swedish": "sueco",
    "turkish": "turco",
    "vietnamese": "vietnamita",
    "chinese": "chino",
    "howToSolveNonogram": "Cómo resolver un nonograma",
    "objective": "Objetivo",
    "nonoObjective": "El objetivo del juego es rellenar la cuadrícula con cuadrados negros para que los números de cuadrados secuenciales rellenos coincidan con los números en la parte superior e izquierda.",
    "controls": "Controles",
    "nonoControlsLeft": "Haga clic izquierdo para marcar un cuadrado como encendido (negro) o desconocido (blanco).",
    "nonoControlsRight": "Haga clic con el botón derecho para marcar un cuadrado como desactivado (X roja) o desconocido (blanco).",
    "nonoControlsTouch": "Toque para alternar un cuadrado como activado (negro), desactivado (X roja) o desconocido (blanco).",
    "nonoControlsDrag": "El arrastre funciona para los tres de los anteriores.",
    "freecell": "Libre de células",
    "metaDescription": "Nonogramas en línea. Más de 500,000 rompecabezas en 29 idiomas.",
    "metaKeywords": "nonograma, nonogramas, imagen, rompecabezas, griddlers, griddler, picross",
    "mobileApps": "Aplicaciones móviles"
  },
  "et": {
    "account": "Konto",
    "username": "Kasutajanimi",
    "password": "Parool",
    "loading": "Laadimine",
    "login": "Logi sisse",
    "noAccount": "Kas teil pole kontot?",
    "createAccount": "Registreeru siin",
    "required": "Nõutud",
    "loggedIn": "Sa oled sisse logitud!",
    "logout": "Logi välja",
    "loggedOut": "Olete nüüd välja loginud",
    "returnToPuzzles": "Tagasi mõistatuste juurde",
    "changePassword": "Muuda salasõna",
    "settings": "Seaded",
    "settingsUpdated": "Seaded uuendatud",
    "register": "Registreeri",
    "forgotYourPassword": "Unustasid salasõna?",
    "resetPassword": "Taasta oma salasõna",
    "email": "E-post",
    "confirmPassword": "Kinnita salasõna",
    "passwordUpdated": "Parool uuendatud",
    "passwordsMustMatch": "salasõnad peavad kattuma",
    "haveAnAccount": "On juba konto?",
    "ajaxError": "Probleem oli. Proovige hiljem uuesti",
    "language": "Keel",
    "size": "Suurus",
    "save": "Salvesta",
    "invalidEmail": "Esitage kehtiv e-kiri",
    "sendingEmail": "E-kirja saatmine",
    "resetEmailSent": "Kontrollige oma e-posti parooli parooli lähtestamiseks linki.",
    "ad": "Reklaam",
    "nonogramPuzzle": "Nonogramm Puzzle",
    "goodLuck": "Edu!",
    "errorFound": "Leitud vead",
    "correct": "Õige!",
    "startOver": "Alusta uuesti",
    "showCorrect": "Kuva õiged read/veerud",
    "checkIt": "Vaata seda",
    "newNonogram": "Uus Nonogram",
    "topTimesFor": "Parim aeg {size} x {size} jaoks",
    "noTimes": "Ei ole aega näidata",
    "user": "Kasutaja",
    "time": "Aeg",
    "puzzle": "Mõistatus",
    "nonogramSizes": "Nonogram Sizes",
    "contactMe": "Võta minuga ühendust",
    "howToSolve": "Kuidas lahendada",
    "topTimes": "Top Times",
    "languages": "Keeled",
    "yourName": "Sinu nimi",
    "yourEmail": "Sinu email",
    "message": "Sõnum",
    "sendingMessage": "Sõnumi saatmine",
    "sendMessage": "Saada sõnum",
    "sentMessage": "Sõnum saadetud",
    "bulgarian": "Bulgaaria keel",
    "czech": "Tšehhi keel",
    "danish": "Taani keel",
    "german": "Saksa keel",
    "greek": "Kreeka keel",
    "english": "Inglise",
    "spanish": "Hispaania keel",
    "estonian": "Eesti keel",
    "finnish": "Soome keel",
    "french": "Prantsuse keel",
    "hebrew": "Heebrea keel",
    "croatian": "Horvaatia keel",
    "hungarian": "Ungari keel",
    "italian": "Itaalia keel",
    "japanese": "Jaapani keel",
    "korean": "Korea keel",
    "norwegian": "Norra keel",
    "dutch": "Hollandi keel",
    "polish": "Poola keel",
    "portuguese": "Portugali keel",
    "romanian": "Rumeenia keel",
    "russian": "Vene keel",
    "slovak": "Slovaki keel",
    "slovenian": "Sloveeni keel",
    "serbian": "Serbia",
    "swedish": "Rootsi keel",
    "turkish": "Türgi keel",
    "vietnamese": "Vietnami keel",
    "chinese": "Hiina",
    "howToSolveNonogram": "Kuidas lahendada mittogramm",
    "objective": "Eesmärk",
    "nonoObjective": "Mängu eesmärk on täita ruudustik mustade ruutudega, nii et täidetud järjestikuste ruutude arv sobiks üleval ja vasakul olevate numbritega.",
    "controls": "Kontrollid",
    "nonoControlsLeft": "Vasak klõps tähistab ruudu nagu (must) või tundmatu (valge).",
    "nonoControlsRight": "Paremklõps ruudu tähistamiseks välja (punane X) või teadmata (valge).",
    "nonoControlsTouch": "Puudutage ruudu tsüklit nagu sisse (must), välja (punane X) või tundmatu (valge).",
    "nonoControlsDrag": "Lohistamine toimib kõigi kolme eespool.",
    "freecell": "Freecell",
    "metaDescription": "Nonogrammid veebis. Üle 500 000 mõistatuse 29 keeles.",
    "metaKeywords": "nonogramm, nonogramm, pilt, mõistatus, lehtsildurid, lehtsinder, pikross",
    "mobileApps": "Mobiilirakendused"
  },
  "fi": {
    "account": "Tili",
    "username": "Käyttäjätunnus",
    "password": "Salasana",
    "loading": "Ladataan",
    "login": "Kirjaudu sisään",
    "noAccount": "Eikö sinulla ole tiliä?",
    "createAccount": "Rekisteröidy täällä",
    "required": "Vaaditaan",
    "loggedIn": "Olet kirjautunut sisään!",
    "logout": "Kirjautua ulos",
    "loggedOut": "Olet nyt kirjautunut ulos",
    "returnToPuzzles": "Palaa palapeleihin",
    "changePassword": "Vaihda salasana",
    "settings": "asetukset",
    "settingsUpdated": "Asetukset päivitetty",
    "register": "Rekisteröidy",
    "forgotYourPassword": "Unohditko salasanasi?",
    "resetPassword": "Nollaa salasana",
    "email": "Sähköposti",
    "confirmPassword": "Vahvista salasana",
    "passwordUpdated": "Salasana päivitetty",
    "passwordsMustMatch": "Salasanojen täytyy täsmätä",
    "haveAnAccount": "Onko sinulla jo tili?",
    "ajaxError": "Oli ongelma. Yritä myöhemmin uudelleen",
    "language": "Kieli",
    "size": "Koko",
    "save": "Tallentaa",
    "invalidEmail": "Anna kelvollinen sähköposti",
    "sendingEmail": "Sähköpostin lähettäminen",
    "resetEmailSent": "Tarkista sähköpostiosoitteesi salasanan nollaamiseksi.",
    "ad": "Mainos",
    "nonogramPuzzle": "Nonogram-palapeli",
    "goodLuck": "Onnea!",
    "errorFound": "Löytyneet virheet",
    "correct": "Oikea!!",
    "startOver": "Aloittaa alusta",
    "showCorrect": "Näytä oikeat rivit/sarakkeet",
    "checkIt": "Tarkista se",
    "newNonogram": "Uusi Nonogram",
    "topTimesFor": "Eniten kertaa {size} x {size}",
    "noTimes": "Ei aikaa näyttää",
    "user": "käyttäjä",
    "time": "Aika",
    "puzzle": "Palapeli",
    "nonogramSizes": "Nonogram-koot",
    "contactMe": "Ota yhteyttä minuun",
    "howToSolve": "Miten ratkaista",
    "topTimes": "Top Times",
    "languages": "Kieli (kielet",
    "yourName": "Sinun nimesi",
    "yourEmail": "Sähköpostisi",
    "message": "Viesti",
    "sendingMessage": "Viestin lähettäminen",
    "sendMessage": "Lähetä viesti",
    "sentMessage": "Viesti lähetetty",
    "bulgarian": "bulgarialainen",
    "czech": "czech",
    "danish": "Tanskan kieli",
    "german": "Saksan kieli",
    "greek": "kreikkalainen",
    "english": "Englanti",
    "spanish": "Espanja",
    "estonian": "Virolainen",
    "finnish": "Suomalainen",
    "french": "Ranskan kieli",
    "hebrew": "heprealainen",
    "croatian": "kroaatti",
    "hungarian": "Unkarin kieli",
    "italian": "italialainen",
    "japanese": "japanilainen",
    "korean": "Korealainen",
    "norwegian": "Norjan kieli",
    "dutch": "Hollannin kieli",
    "polish": "Kiillottaa",
    "portuguese": "Portugalin kieli",
    "romanian": "romanialainen",
    "russian": "Venäjän kieli",
    "slovak": "Slovakian",
    "slovenian": "slovenian",
    "serbian": "serbialainen",
    "swedish": "Ruotsin kieli",
    "turkish": "turkki",
    "vietnamese": "vietnam",
    "chinese": "Kiinalainen",
    "howToSolveNonogram": "Miten ratkaista Nonogram",
    "objective": "Tavoite",
    "nonoObjective": "Pelin tavoitteena on täyttää ruudukko mustilla neliöillä, jotta täytettyjen peräkkäisten neliöiden numerot vastaavat ylhäällä ja vasemmalla olevia numeroita.",
    "controls": "ohjaimet",
    "nonoControlsLeft": "Napsauta vasenta painiketta, kun haluat merkitä neliön kuten (musta) tai tuntematon (valkoinen).",
    "nonoControlsRight": "Napsauta hiiren kakkospainikkeella merkitä neliö pois päältä (punainen X) tai tuntematon (valkoinen).",
    "nonoControlsTouch": "Voit pyörittää neliötä kuten (musta), pois päältä (punainen X) tai tuntematon (valkoinen) koskettamalla.",
    "nonoControlsDrag": "Vedä kaikki edellä mainitut.",
    "freecell": "Vapaa solu",
    "metaDescription": "Ei-ohjelmat verkossa. Yli 500 000 palapeliä 29 kielellä.",
    "metaKeywords": "nonogram, nonograms, kuva, palapeli, griddlers, griddler, picross",
    "mobileApps": "Mobiilisovellukset"
  },
  "fr": {
    "account": "Compte",
    "username": "Nom d'utilisateur",
    "password": "Mot de passe",
    "loading": "Chargement",
    "login": "S'identifier",
    "noAccount": "Vous n'avez pas de compte?",
    "createAccount": "Inscrivez-vous ici",
    "required": "Champs obligatoires",
    "loggedIn": "Vous êtes authentifié!",
    "logout": "Connectez - Out",
    "loggedOut": "Vous êtes maintenant déconnecté",
    "returnToPuzzles": "Retour aux puzzles",
    "changePassword": "Changer le mot de passe",
    "settings": "Réglages",
    "settingsUpdated": "Paramètres mis à jour",
    "register": "registre",
    "forgotYourPassword": "Mot de passe oublié?",
    "resetPassword": "Réinitialisez votre mot de passe",
    "email": "Email",
    "confirmPassword": "Confirmez le mot de passe",
    "passwordUpdated": "Mot de passe mis à jour",
    "passwordsMustMatch": "les mots de passe doivent correspondre",
    "haveAnAccount": "Vous avez déjà un compte?",
    "ajaxError": "Il y avait un problème. Réessayez plus tard",
    "language": "La langue",
    "size": "Taille",
    "save": "sauvegarder",
    "invalidEmail": "Fournir un email valide",
    "sendingEmail": "Envoi d'email",
    "resetEmailSent": "Vérifiez votre email pour le lien pour réinitialiser votre mot de passe.",
    "ad": "Publicité",
    "nonogramPuzzle": "Puzzle de Nonogram",
    "goodLuck": "Bonne chance!",
    "errorFound": "Erreurs trouvées",
    "correct": "Correct!!",
    "startOver": "Recommencer",
    "showCorrect": "Afficher les lignes/colonnes correctes",
    "checkIt": "Vérifie ça",
    "newNonogram": "Nouveau nonogramme",
    "topTimesFor": "Meilleurs temps pour {taille} x {taille}",
    "noTimes": "Pas de temps à montrer",
    "user": "Utilisateur",
    "time": "Temps",
    "puzzle": "Puzzle",
    "nonogramSizes": "Tailles Nonogram",
    "contactMe": "Contactez moi",
    "howToSolve": "Comment résoudre",
    "topTimes": "Meilleurs temps",
    "languages": "Les langues",
    "yourName": "votre nom",
    "yourEmail": "Votre email",
    "message": "Message",
    "sendingMessage": "Envoi du message",
    "sendMessage": "Envoyer le message",
    "sentMessage": "Message envoyé",
    "bulgarian": "bulgare",
    "czech": "tchèque",
    "danish": "danois",
    "german": "allemand",
    "greek": "grec",
    "english": "Anglais",
    "spanish": "Espanol",
    "estonian": "estonien",
    "finnish": "finlandais",
    "french": "français",
    "hebrew": "hébreu",
    "croatian": "croate",
    "hungarian": "hongrois",
    "italian": "italien",
    "japanese": "Japonais",
    "korean": "coréen",
    "norwegian": "norvégien",
    "dutch": "néerlandais",
    "polish": "polonais",
    "portuguese": "Portugais",
    "romanian": "roumain",
    "russian": "russe",
    "slovak": "slovaque",
    "slovenian": "slovène",
    "serbian": "serbe",
    "swedish": "suédois",
    "turkish": "turc",
    "vietnamese": "vietnamien",
    "chinese": "chinois",
    "howToSolveNonogram": "Comment résoudre un nonogramme",
    "objective": "Objectif",
    "nonoObjective": "Le but du jeu est de remplir la grille avec des carrés noirs afin que les nombres de carrés séquentiels remplis correspondent aux chiffres en haut et à gauche.",
    "controls": "Les contrôles",
    "nonoControlsLeft": "Clic gauche pour marquer un carré comme sur (noir) ou inconnu (blanc).",
    "nonoControlsRight": "Cliquez avec le bouton droit pour marquer un carré comme étant désactivé (X rouge) ou inconnu (blanc).",
    "nonoControlsTouch": "Touchez pour faire défiler un carré allumé (noir), désactivé (X rouge) ou inconnu (blanc).",
    "nonoControlsDrag": "Faire glisser fonctionne pour les trois éléments ci-dessus.",
    "freecell": "Cellule libre",
    "metaDescription": "Nonogrammes en ligne. Plus de 500 000 puzzles en 29 langues.",
    "metaKeywords": "nonogramme, nonogrammes, image, puzzle, plaques de cuisson, plaque de cuisson, picross",
    "mobileApps": "Application mobile"
  },
  "he": {
    "account": "חשבון",
    "username": "שם משתמש",
    "password": "סיסמה",
    "loading": "טוען",
    "login": "התחברות",
    "noAccount": "אין לך חשבון?",
    "createAccount": "הירשם כאן",
    "required": "נדרש",
    "loggedIn": "אתה מחובר!",
    "logout": "להתנתק",
    "loggedOut": "אתה עכשיו מנותק",
    "returnToPuzzles": "לחזור פאזלים",
    "changePassword": "שנה סיסמא",
    "settings": "הגדרות",
    "settingsUpdated": "ההגדרות עודכנו",
    "register": "הירשם",
    "forgotYourPassword": "שכחת ססמה?",
    "resetPassword": "לאפס את הסיסמה שלך",
    "email": "דוא\"ל",
    "confirmPassword": "אשר סיסמה",
    "passwordUpdated": "הסיסמה עודכנה",
    "passwordsMustMatch": "הססמאות חייבות להיות זהות",
    "haveAnAccount": "כבר יש לך חשבון?",
    "ajaxError": "הייתה בעיה. נסה שוב מאוחר יותר",
    "language": "שפה",
    "size": "גודל",
    "save": "להציל",
    "invalidEmail": "ספק דוא\"ל חוקי",
    "sendingEmail": "שולח דוא\"ל",
    "resetEmailSent": "בדוק את הדוא\"ל שלך לקבלת הקישור לאיפוס הסיסמה.",
    "ad": "פרסומת",
    "nonogramPuzzle": "פאזל Nonogram",
    "goodLuck": "בהצלחה!",
    "errorFound": "נמצאו שגיאות",
    "correct": "נכון!!",
    "startOver": "להתחיל מחדש",
    "showCorrect": "הצג שורות/קולות נכונות",
    "checkIt": "בדוק את זה",
    "newNonogram": "חדש Nonogram",
    "topTimesFor": "הזמנים המובילים עבור {size} x {size}",
    "noTimes": "אין זמן להציג",
    "user": "משתמש",
    "time": "זמן",
    "puzzle": "פאזל",
    "nonogramSizes": "גדלי Nonogram",
    "contactMe": "צור איתי קשר",
    "howToSolve": "איך לפתור",
    "topTimes": "טיימס מובילים",
    "languages": "שפות",
    "yourName": "השם שלך",
    "yourEmail": "האימייל שלך",
    "message": "הודעה",
    "sendingMessage": "לשלוח הודעה",
    "sendMessage": "לשלוח הודעה",
    "sentMessage": "הודעה נשלחה",
    "bulgarian": "בולגרית",
    "czech": "צ'כית",
    "danish": "דנית",
    "german": "גרמנית",
    "greek": "יווני",
    "english": "אנגלית",
    "spanish": "ספרדית",
    "estonian": "אסטונית",
    "finnish": "פינית",
    "french": "צרפתית",
    "hebrew": "עברית",
    "croatian": "קרואטית",
    "hungarian": "הונגרית",
    "italian": "איטלקית",
    "japanese": "יפנית",
    "korean": "קוריאנית",
    "norwegian": "נורווגית",
    "dutch": "הולנדית",
    "polish": "פולני",
    "portuguese": "פורטוגזית",
    "romanian": "רומנית",
    "russian": "רוסית",
    "slovak": "סלובקית",
    "slovenian": "סלובנית",
    "serbian": "סרבית",
    "swedish": "שוודית",
    "turkish": "טורקי",
    "vietnamese": "ויאטנמית",
    "chinese": "סינית",
    "howToSolveNonogram": "כיצד לפתור Nonogram",
    "objective": "המטרה",
    "nonoObjective": "מטרת המשחק היא למלא את הרשת עם ריבועים שחורים, כך מספרים של ריבועים רציפים מלאים להתאים את המספרים בחלק העליון והשמאלי.",
    "controls": "פקדים",
    "nonoControlsLeft": "לחץ שמאלה כדי לסמן ריבוע על (שחור) או לא ידוע (לבן).",
    "nonoControlsRight": "לחץ לחיצה ימנית כדי לסמן ריבוע כבוי (אדום X) או לא ידוע (לבן).",
    "nonoControlsTouch": "גע כדי לסובב ריבוע כמו על (שחור), כבוי (אדום X), או לא ידוע (לבן).",
    "nonoControlsDrag": "גרירת עובד עבור כל שלושת האמור לעיל.",
    "freecell": "פרייסל",
    "metaDescription": "Nonograms מקוון. מעל 500,000 חידות ב 29 שפות.",
    "metaKeywords": "nonogram, nonograms, תמונה, פאזל, מגשנים, מגש, פיקרוס",
    "mobileApps": "אפליקציות סלולריות"
  },
  "hr": {
    "account": "Račun",
    "username": "Korisničko ime",
    "password": "Zaporka",
    "loading": "Učitavam",
    "login": "Prijaviti se",
    "noAccount": "Nemate račun?",
    "createAccount": "Registrirajte se ovdje",
    "required": "Potreban",
    "loggedIn": "Prijavljeni ste!",
    "logout": "Odjaviti se",
    "loggedOut": "Sada ste odjavljeni",
    "returnToPuzzles": "Vratite se zagonetkama",
    "changePassword": "Promijeni zaporku",
    "settings": "postavke",
    "settingsUpdated": "Postavke ažurirane",
    "register": "Registrirajte se",
    "forgotYourPassword": "Zaboravili ste zaporku?",
    "resetPassword": "Vraćanje izvorne lozinke",
    "email": "E-mail",
    "confirmPassword": "Potvrdi lozinku",
    "passwordUpdated": "Ažurirana zaporka",
    "passwordsMustMatch": "lozinka mora odgovarati",
    "haveAnAccount": "Već imate račun?",
    "ajaxError": "Došlo je do problema. Pokušajte ponovno kasnije",
    "language": "Jezik",
    "size": "Veličina",
    "save": "Uštedjeti",
    "invalidEmail": "Unesite valjanu e-poštu",
    "sendingEmail": "Slanje e-pošte",
    "resetEmailSent": "Provjerite vezu e-pošte za poništavanje zaporke.",
    "ad": "Oglas",
    "nonogramPuzzle": "Nonogram Puzzle",
    "goodLuck": "Sretno!",
    "errorFound": "Pronađene pogreške",
    "correct": "Ispravan !!",
    "startOver": "Početi ispočetka",
    "showCorrect": "Prikaži ispravne retke/stupce",
    "checkIt": "Provjeriti",
    "newNonogram": "Novi Nonogram",
    "topTimesFor": "Prva vremena za {size} x {size}",
    "noTimes": "Nema vremena za pokazivanje",
    "user": "Korisnik",
    "time": "Vrijeme",
    "puzzle": "Puzzle",
    "nonogramSizes": "Veličine Nonogram",
    "contactMe": "Kontaktiraj me",
    "howToSolve": "Kako riješiti problem",
    "topTimes": "Top Times",
    "languages": "Jezici",
    "yourName": "Tvoje ime",
    "yourEmail": "Tvoj email",
    "message": "Poruka",
    "sendingMessage": "Slanje poruke",
    "sendMessage": "Pošalji poruku",
    "sentMessage": "Poruka je poslana",
    "bulgarian": "bugarski",
    "czech": "češki",
    "danish": "danski",
    "german": "njemački",
    "greek": "grčki",
    "english": "Engleski",
    "spanish": "španjolski",
    "estonian": "estonski",
    "finnish": "finski",
    "french": "francuski",
    "hebrew": "hebrejski",
    "croatian": "Hrvatski",
    "hungarian": "madžarski",
    "italian": "talijanski",
    "japanese": "japanski",
    "korean": "korejski",
    "norwegian": "norveški",
    "dutch": "Nizozemac",
    "polish": "Polirati",
    "portuguese": "Portugalski",
    "romanian": "rumunski",
    "russian": "ruski",
    "slovak": "slovački",
    "slovenian": "Slovenac",
    "serbian": "srpski",
    "swedish": "švedski",
    "turkish": "turski",
    "vietnamese": "vijetnamski",
    "chinese": "kineski",
    "howToSolveNonogram": "Kako riješiti Nonogram",
    "objective": "Cilj",
    "nonoObjective": "Cilj igre je ispuniti rešetku crnim kvadratima tako da se broj ispunjenih sekvencijalnih kvadrata podudara s brojevima na vrhu i lijevo.",
    "controls": "kontrole",
    "nonoControlsLeft": "Lijevi klik označite kvadratom na (crno) ili nepoznato (bijelo).",
    "nonoControlsRight": "Desnim klikom označite kvadrat kao isključen (crveni X) ili nepoznati (bijeli).",
    "nonoControlsTouch": "Dodirnite za kruženje kvadrata kao on (crno), isključeno (crveno X) ili nepoznato (bijelo).",
    "nonoControlsDrag": "Povlačenje radi za sva tri gore navedena.",
    "freecell": "FreeCell",
    "metaDescription": "Nonogrami na mreži. Preko 500 000 zagonetki na 29 jezika.",
    "metaKeywords": "nonogram, nonograms, slika, puzzle, griddlers, griddler, picross",
    "mobileApps": "Mobilne aplikacije"
  },
  "hu": {
    "account": "számla",
    "username": "Felhasználónév",
    "password": "Jelszó",
    "loading": "Betöltés",
    "login": "Belépés",
    "noAccount": "Nincs fiókod?",
    "createAccount": "Itt regisztrálj",
    "required": "Kívánt",
    "loggedIn": "Ön bejelentkezett!",
    "logout": "Kijelentkezés",
    "loggedOut": "Most kijelentkezett",
    "returnToPuzzles": "Vissza a rejtvényekhez",
    "changePassword": "Jelszó módosítása",
    "settings": "Beállítások",
    "settingsUpdated": "Beállítások frissítve",
    "register": "Regisztráció",
    "forgotYourPassword": "Elfelejtette a jelszavát?",
    "resetPassword": "Állítsd vissza a jelszavad",
    "email": "Email",
    "confirmPassword": "Jelszó megerősítése",
    "passwordUpdated": "Jelszó frissítve",
    "passwordsMustMatch": "a jelszavaknak egyezniük kell",
    "haveAnAccount": "Már van fiókja?",
    "ajaxError": "Probléma volt. Próbáld újra később",
    "language": "Nyelv",
    "size": "Méret",
    "save": "Mentés",
    "invalidEmail": "Adjon meg érvényes e-mailt",
    "sendingEmail": "E-mail küldése",
    "resetEmailSent": "Ellenőrizze e-mail címét a jelszó visszaállításához.",
    "ad": "Hirdetés",
    "nonogramPuzzle": "Nonogram Puzzle",
    "goodLuck": "Sok szerencsét!",
    "errorFound": "Hibák találtak",
    "correct": "Helyes!!",
    "startOver": "Elölről kezdeni",
    "showCorrect": "Helyes sorok/oszlopok megjelenítése",
    "checkIt": "Ellenőrizd",
    "newNonogram": "Új Nonogram",
    "topTimesFor": "Legnagyobb idők a {méret} x {méret} számára",
    "noTimes": "Nincs idő a megjelenítésre",
    "user": "használó",
    "time": "Idő",
    "puzzle": "Kirakós játék",
    "nonogramSizes": "Nemogramméretek",
    "contactMe": "Keress meg",
    "howToSolve": "Hogyan kell megoldani",
    "topTimes": "Top Times",
    "languages": "Nyelvek",
    "yourName": "A neved",
    "yourEmail": "Az email címed",
    "message": "Üzenet",
    "sendingMessage": "Üzenet küldése",
    "sendMessage": "Üzenet küldése",
    "sentMessage": "Üzenet elküldve",
    "bulgarian": "bolgár",
    "czech": "cseh",
    "danish": "dán",
    "german": "német",
    "greek": "görög",
    "english": "angol",
    "spanish": "spanyol",
    "estonian": "észt",
    "finnish": "finn",
    "french": "Francia",
    "hebrew": "héber",
    "croatian": "horvát",
    "hungarian": "Magyar",
    "italian": "olasz",
    "japanese": "japán",
    "korean": "koreai",
    "norwegian": "norvég",
    "dutch": "holland",
    "polish": "lengyel",
    "portuguese": "portugál",
    "romanian": "román",
    "russian": "orosz",
    "slovak": "szlovák",
    "slovenian": "szlovén",
    "serbian": "szerb",
    "swedish": "svéd",
    "turkish": "török",
    "vietnamese": "vietnami",
    "chinese": "kínai",
    "howToSolveNonogram": "Hogyan lehet megoldani egy nonogramot",
    "objective": "Célkitűzés",
    "nonoObjective": "A játék célja, hogy kitöltse a rácsot fekete négyzetekkel, hogy a kitöltött szekvenciális négyzetek száma megegyezzen a felső és a bal oldali számokkal.",
    "controls": "Controls",
    "nonoControlsLeft": "A bal egérgombbal jelölje ki a négyzetet (fekete) vagy ismeretlen (fehér).",
    "nonoControlsRight": "A jobb egérgombbal jelölje ki a négyzetet ki (piros X) vagy ismeretlen (fehér).",
    "nonoControlsTouch": "Érintse meg a négyzetet, mint a (fekete), a kikapcsolt (piros X) vagy az ismeretlen (fehér).",
    "nonoControlsDrag": "A húzás a fentiek mindháromára vonatkozik.",
    "freecell": "Admirális",
    "metaDescription": "Nonograms Online. Több mint 500 000 rejtvény 29 nyelven.",
    "metaKeywords": "nonogram, nonogram, kép, puzzle, griddlers, griddler, picross",
    "mobileApps": "Mobil alkalmazások"
  },
  "it": {
    "account": "account",
    "username": "Nome utente",
    "password": "Parola d'ordine",
    "loading": "Caricamento in corso",
    "login": "Accesso",
    "noAccount": "Non hai un account?",
    "createAccount": "Registrati qui",
    "required": "necessario",
    "loggedIn": "Ti sei autenticato!",
    "logout": "Disconnettersi",
    "loggedOut": "Ora siete disconnessi",
    "returnToPuzzles": "Ritorna ai puzzle",
    "changePassword": "Cambia la password",
    "settings": "impostazioni",
    "settingsUpdated": "Impostazioni aggiornate",
    "register": "Registrare",
    "forgotYourPassword": "Hai dimenticato la password?",
    "resetPassword": "Reimposta la tua password",
    "email": "E-mail",
    "confirmPassword": "Conferma password",
    "passwordUpdated": "Password aggiornata",
    "passwordsMustMatch": "Le password devono essere identiche",
    "haveAnAccount": "Hai già un account?",
    "ajaxError": "C'era un problema. Riprovare più tardi",
    "language": "linguaggio",
    "size": "Taglia",
    "save": "Salvare",
    "invalidEmail": "Fornire un'e-mail valida",
    "sendingEmail": "Invio di email",
    "resetEmailSent": "Controlla la tua email per il link per reimpostare la password.",
    "ad": "Annuncio pubblicitario",
    "nonogramPuzzle": "Puzzle Nonogram",
    "goodLuck": "In bocca al lupo!",
    "errorFound": "Errori trovati",
    "correct": "Corretta!!",
    "startOver": "Ricominciare",
    "showCorrect": "Mostra righe/colonne corrette",
    "checkIt": "Controllalo",
    "newNonogram": "Nuovo nonogramma",
    "topTimesFor": "I tempi migliori per {size} x {size}",
    "noTimes": "Non c'è tempo da mostrare",
    "user": "Utente",
    "time": "Tempo",
    "puzzle": "Puzzle",
    "nonogramSizes": "Dimensioni nonogram",
    "contactMe": "Contattami",
    "howToSolve": "Come risolvere",
    "topTimes": "I tempi migliori",
    "languages": "Le lingue",
    "yourName": "Il tuo nome",
    "yourEmail": "La tua email",
    "message": "Messaggio",
    "sendingMessage": "Invio di messaggi",
    "sendMessage": "Invia messaggio",
    "sentMessage": "Messaggio inviato",
    "bulgarian": "bulgaro",
    "czech": "ceco",
    "danish": "danese",
    "german": "Tedesco",
    "greek": "greco",
    "english": "Inglese",
    "spanish": "spagnolo",
    "estonian": "estone",
    "finnish": "finlandese",
    "french": "francese",
    "hebrew": "ebraico",
    "croatian": "croato",
    "hungarian": "ungherese",
    "italian": "italiano",
    "japanese": "giapponese",
    "korean": "coreano",
    "norwegian": "norvegese",
    "dutch": "olandese",
    "polish": "polacco",
    "portuguese": "portoghese",
    "romanian": "rumeno",
    "russian": "russo",
    "slovak": "slovacco",
    "slovenian": "sloveno",
    "serbian": "serbo",
    "swedish": "svedese",
    "turkish": "Turco",
    "vietnamese": "vietnamita",
    "chinese": "Cinese",
    "howToSolveNonogram": "Come risolvere un nonogram",
    "objective": "Obbiettivo",
    "nonoObjective": "Lo scopo del gioco è riempire la griglia di quadrati neri in modo che i numeri dei quadrati sequenziali riempiti corrispondano ai numeri in alto ea sinistra.",
    "controls": "controlli",
    "nonoControlsLeft": "Fare clic con il tasto sinistro per contrassegnare un quadrato come attivo (nero) o sconosciuto (bianco).",
    "nonoControlsRight": "Fare clic con il tasto destro per marcare un quadrato come spento (rosso X) o sconosciuto (bianco).",
    "nonoControlsTouch": "Tocca per ciclare un quadrato come attivo (nero), spento (rosso X) o sconosciuto (bianco).",
    "nonoControlsDrag": "Trascinando funziona per tutti e tre dei precedenti.",
    "freecell": "Freecell",
    "metaDescription": "Nonogrammi online. Oltre 500.000 puzzle in 29 lingue.",
    "metaKeywords": "nonogramma, nonogrammi, immagine, puzzle, griddlers, griddler, picross",
    "mobileApps": "App mobili"
  },
  "ja": {
    "account": "アカウント",
    "username": "ユーザー名",
    "password": "パスワード",
    "loading": "読み込み中",
    "login": "ログイン",
    "noAccount": "アカウントを持っていませんか？",
    "createAccount": "ここに登録",
    "required": "必須",
    "loggedIn": "あなたはログインしています！",
    "logout": "ログアウト",
    "loggedOut": "あなたはログアウトしました",
    "returnToPuzzles": "パズルに戻る",
    "changePassword": "パスワードを変更する",
    "settings": "設定",
    "settingsUpdated": "設定が更新されました",
    "register": "登録",
    "forgotYourPassword": "パスワードをお忘れですか？",
    "resetPassword": "あなたのパスワードをリセット",
    "email": "Eメール",
    "confirmPassword": "パスワードを認証する",
    "passwordUpdated": "パスワード更新",
    "passwordsMustMatch": "パスワードが一致する必要があります",
    "haveAnAccount": "すでにアカウントをお持ちですか？",
    "ajaxError": "問題がありました。あとでもう一度試してみてください",
    "language": "言語",
    "size": "サイズ",
    "save": "保存する",
    "invalidEmail": "有効なメールアドレスを入力してください",
    "sendingEmail": "メールを送信する",
    "resetEmailSent": "あなたのパスワードをリセットするためのリンクをあなたのEメールでチェックしてください。",
    "ad": "広告",
    "nonogramPuzzle": "ノノグラムパズル",
    "goodLuck": "がんばろう！",
    "errorFound": "見つかったエラー",
    "correct": "正しい！！",
    "startOver": "やり直す",
    "showCorrect": "正しい行/列を表示",
    "checkIt": "それをチェック",
    "newNonogram": "新しいノノグラム",
    "topTimesFor": "{size} x {size}のトップタイム",
    "noTimes": "表示する時間はありません",
    "user": "ユーザー",
    "time": "時間",
    "puzzle": "パズル",
    "nonogramSizes": "ノノグラムサイズ",
    "contactMe": "私に連絡して",
    "howToSolve": "の解き方",
    "topTimes": "トップタイムズ",
    "languages": "言語",
    "yourName": "あなたの名前",
    "yourEmail": "あなたのEメール",
    "message": "メッセージ",
    "sendingMessage": "メッセージを送信する",
    "sendMessage": "メッセージを送る",
    "sentMessage": "送信メッセージ",
    "bulgarian": "ブルガリア語",
    "czech": "チェコ語",
    "danish": "デンマーク語",
    "german": "ドイツ人",
    "greek": "ギリシャ語",
    "english": "英語",
    "spanish": "スペイン語",
    "estonian": "エストニア語",
    "finnish": "フィンランド語",
    "french": "フランス語",
    "hebrew": "ヘブライ語",
    "croatian": "クロアチア語",
    "hungarian": "ハンガリー語",
    "italian": "イタリアの",
    "japanese": "日本人",
    "korean": "韓国語",
    "norwegian": "ノルウェー語",
    "dutch": "オランダの",
    "polish": "研磨",
    "portuguese": "ポルトガル語",
    "romanian": "ルーマニア語",
    "russian": "ロシア",
    "slovak": "スロバキア",
    "slovenian": "スロベニア語",
    "serbian": "セルビア語",
    "swedish": "スウェーデンの",
    "turkish": "トルコ語",
    "vietnamese": "ベトナム人",
    "chinese": "中国語",
    "howToSolveNonogram": "ノノグラムを解く方法",
    "objective": "目的",
    "nonoObjective": "ゲームの目的は、グリッドを黒い四角で塗りつぶして、塗りつぶされた連続する四角の数が上と下の数字と一致するようにすることです。",
    "controls": "コントロール",
    "nonoControlsLeft": "左クリックして、正方形にオン（黒）または不明（白）のマークを付けます。",
    "nonoControlsRight": "右クリックして、正方形をオフ（赤X）または不明（白）としてマークします。",
    "nonoControlsTouch": "タッチすると、四角形がオン（黒）、オフ（赤X）、または不明（白）に変わります。",
    "nonoControlsDrag": "ドラッグは上記の3つすべてに有効です。",
    "freecell": "フリーセル",
    "metaDescription": "ノノグラムオンライン。 29言語で500,000を超えるパズル。",
    "metaKeywords": "nonogram、nonograms、画像、パズル、griddlers、griddler、picross",
    "mobileApps": "モバイルアプリ"
  },
  "ko": {
    "account": "계정",
    "username": "사용자 이름",
    "password": "암호",
    "loading": "로딩",
    "login": "로그인",
    "noAccount": "계정이 없으십니까?",
    "createAccount": "여기에 등록하십시오",
    "required": "필수",
    "loggedIn": "로그인하셨습니다!",
    "logout": "로그 아웃",
    "loggedOut": "지금 로그 아웃되었습니다.",
    "returnToPuzzles": "퍼즐로 돌아 가기",
    "changePassword": "비밀번호 변경",
    "settings": "설정",
    "settingsUpdated": "설정이 업데이트되었습니다.",
    "register": "레지스터",
    "forgotYourPassword": "비밀번호를 잊어 버렸습니까?",
    "resetPassword": "비밀번호를 재설정",
    "email": "이메일",
    "confirmPassword": "비밀번호 확인",
    "passwordUpdated": "암호 업데이트 됨",
    "passwordsMustMatch": "비밀번호가 일치해야합니다",
    "haveAnAccount": "이미 계정이 있으십니까?",
    "ajaxError": "문제가 발생했습니다. 나중에 다시 시도하십시오.",
    "language": "언어",
    "size": "크기",
    "save": "구하다",
    "invalidEmail": "유효한 이메일 제공",
    "sendingEmail": "이메일 보내기",
    "resetEmailSent": "이메일 재설정 링크를 확인하십시오.",
    "ad": "광고",
    "nonogramPuzzle": "노 그램 퍼즐",
    "goodLuck": "행운을 빕니다!",
    "errorFound": "오류가 발견되었습니다.",
    "correct": "옳은!!",
    "startOver": "다시 시작하다",
    "showCorrect": "올바른 행/열 표시",
    "checkIt": "확인해 봐",
    "newNonogram": "새로운 노노 그램",
    "topTimesFor": "{size} x {size}의 톱 타임",
    "noTimes": "보여줄 시간이 없다.",
    "user": "사용자",
    "time": "시각",
    "puzzle": "퍼즐",
    "nonogramSizes": "노 그램 크기",
    "contactMe": "저에게 연락하십시오",
    "howToSolve": "해결 방법",
    "topTimes": "최고의 시간",
    "languages": "언어",
    "yourName": "당신의 이름",
    "yourEmail": "귀하의 이메일",
    "message": "메시지",
    "sendingMessage": "보내는 메시지",
    "sendMessage": "문자 보내",
    "sentMessage": "메시지 전송 됨",
    "bulgarian": "불가리아 사람",
    "czech": "체코 사람",
    "danish": "덴마크 말",
    "german": "독일 사람",
    "greek": "그리스 사람",
    "english": "영어",
    "spanish": "스페인 사람",
    "estonian": "에스토니아 사람",
    "finnish": "핀란드어",
    "french": "프랑스 국민",
    "hebrew": "헤브라이 사람",
    "croatian": "크로아티아 사람",
    "hungarian": "헝가리 인",
    "italian": "이탈리아 사람",
    "japanese": "일본어",
    "korean": "한국어",
    "norwegian": "노르웨이 인",
    "dutch": "네덜란드 사람",
    "polish": "광택",
    "portuguese": "포르투갈 인",
    "romanian": "루마니아 사람",
    "russian": "러시아인",
    "slovak": "슬로바키아 사람",
    "slovenian": "슬로베니아",
    "serbian": "세르비아 사람",
    "swedish": "스웨덴어",
    "turkish": "터키어",
    "vietnamese": "베트남 사람",
    "chinese": "중국말",
    "howToSolveNonogram": "노노 그램을 풀는 방법",
    "objective": "목표",
    "nonoObjective": "게임의 목적은 채워진 순차적 인 사각형의 수가 상단과 좌측의 숫자와 일치하도록 검정색 사각형으로 눈금을 채우는 것입니다.",
    "controls": "통제 수단",
    "nonoControlsLeft": "사각형을 (검정) 또는 알 수 없음 (흰색)으로 표시하려면 왼쪽 버튼을 클릭하십시오.",
    "nonoControlsRight": "사각형을 꺼짐 (빨간색 X) 또는 알 수 없음 (흰색)으로 표시하려면 마우스 오른쪽 버튼을 클릭하십시오.",
    "nonoControlsTouch": "터치하여 (검정), 꺼짐 (빨강 X) 또는 알 수 없음 (흰색)과 같은 사각형을 순환하십시오.",
    "nonoControlsDrag": "드래그는 위의 세 가지 모두에 적용됩니다.",
    "freecell": "프리셀",
    "metaDescription": "노노 그램 온라인. 29 개 언어로 된 500,000 개 이상의 퍼즐.",
    "metaKeywords": "nonogram, nonograms, 그림, 퍼즐, griddlers, griddler, picross",
    "mobileApps": "모바일 앱"
  },
  "nb": {
    "account": "Konto",
    "username": "Brukernavn",
    "password": "Passord",
    "loading": "Laster",
    "login": "Logg Inn",
    "noAccount": "Har du ikke en konto?",
    "createAccount": "Registrer her",
    "required": "Må",
    "loggedIn": "Du er logget inn!",
    "logout": "Logg ut",
    "loggedOut": "Du er nå logget ut",
    "returnToPuzzles": "Gå tilbake til puslespillene",
    "changePassword": "Bytt passord",
    "settings": "innstillinger",
    "settingsUpdated": "Innstillingene er oppdatert",
    "register": "Registrere",
    "forgotYourPassword": "Glemt passordet?",
    "resetPassword": "Tilbakestill passordet ditt",
    "email": "e-post",
    "confirmPassword": "Bekreft passord",
    "passwordUpdated": "Passord oppdatert",
    "passwordsMustMatch": "Passordene må være like",
    "haveAnAccount": "Har du allerede en konto?",
    "ajaxError": "Det var et problem. Prøv igjen senere",
    "language": "Språk",
    "size": "Størrelse",
    "save": "Lagre",
    "invalidEmail": "Gi en gyldig epost",
    "sendingEmail": "Sende e-post",
    "resetEmailSent": "Sjekk e-postadressen din for linken for å tilbakestille passordet ditt.",
    "ad": "Annonse",
    "nonogramPuzzle": "Nonogram Puzzle",
    "goodLuck": "Lykke til!",
    "errorFound": "Feil funnet",
    "correct": "Riktig!!",
    "startOver": "Begynne på nytt",
    "showCorrect": "Vis riktige rader/kolonner",
    "checkIt": "Sjekk det",
    "newNonogram": "Nytt Nonogram",
    "topTimesFor": "Topptider for {size} x {size}",
    "noTimes": "Ingen ganger å vise",
    "user": "Bruker",
    "time": "Tid",
    "puzzle": "Puslespill",
    "nonogramSizes": "Nonogram størrelser",
    "contactMe": "Kontakt meg",
    "howToSolve": "Hvordan løse",
    "topTimes": "Topptider",
    "languages": "språk",
    "yourName": "Navnet ditt",
    "yourEmail": "Din epost",
    "message": "Budskap",
    "sendingMessage": "Sende melding",
    "sendMessage": "Sende melding",
    "sentMessage": "Melding sendt",
    "bulgarian": "bulgarian",
    "czech": "Czech",
    "danish": "dansk",
    "german": "tysk",
    "greek": "gresk",
    "english": "Engelsk",
    "spanish": "spansk",
    "estonian": "estonian",
    "finnish": "finsk",
    "french": "fransk",
    "hebrew": "Hebrew",
    "croatian": "kroa~~POS=TRUNC",
    "hungarian": "ungarsk",
    "italian": "italiensk",
    "japanese": "japansk",
    "korean": "Korean",
    "norwegian": "norsk",
    "dutch": "nederlandsk",
    "polish": "Pusse",
    "portuguese": "portugisisk",
    "romanian": "rumensk",
    "russian": "russisk",
    "slovak": "slovak",
    "slovenian": "slovenian",
    "serbian": "serbisk",
    "swedish": "svensk",
    "turkish": "Turkish",
    "vietnamese": "Vietnamesisk",
    "chinese": "kinesisk",
    "howToSolveNonogram": "Slik løser du et nonogram",
    "objective": "Objektiv",
    "nonoObjective": "Formålet med spillet er å fylle rutenettet med svarte firkanter, slik at tallene fylte sekvensielle firkanter stemmer overens med tallene øverst og venstre.",
    "controls": "kontroller",
    "nonoControlsLeft": "Venstre klikk for å markere et firkant som på (svart) eller ukjent (hvitt).",
    "nonoControlsRight": "Høyreklikk for å markere en firkant som av (rød X) eller ukjent (hvit).",
    "nonoControlsTouch": "Berør for å sykle et firkant som på (svart), av (rødt) eller ukjent (hvit).",
    "nonoControlsDrag": "Trenger arbeid for alle tre av de ovennevnte.",
    "freecell": "Freecell",
    "metaDescription": "Nonograms online. Over 500 000 gåter på 29 språk.",
    "metaKeywords": "nonogram, nonograms, bilde, puslespill, griddlers, griddler, picross",
    "mobileApps": "Mobilapper"
  },
  "nl": {
    "account": "Account",
    "username": "Gebruikersnaam",
    "password": "Wachtwoord",
    "loading": "Bezig met laden",
    "login": "Log in",
    "noAccount": "Heb je geen account?",
    "createAccount": "Registreer hier",
    "required": "Verplicht",
    "loggedIn": "Je bent ingelogd!",
    "logout": "Uitloggen",
    "loggedOut": "Je bent nu uitgelogd",
    "returnToPuzzles": "Keer terug naar de puzzels",
    "changePassword": "Wachtwoord wijzigen",
    "settings": "instellingen",
    "settingsUpdated": "Instellingen bijgewerkt",
    "register": "Registreren",
    "forgotYourPassword": "Uw wachtwoord vergeten?",
    "resetPassword": "Stel je wachtwoord opnieuw in",
    "email": "E-mail",
    "confirmPassword": "Bevestig wachtwoord",
    "passwordUpdated": "Wachtwoord bijgewerkt",
    "passwordsMustMatch": "wachtwoorden moeten overeenkomen",
    "haveAnAccount": "Heb je al een account?",
    "ajaxError": "Er was een probleem. Probeer het later opnieuw",
    "language": "Taal",
    "size": "Grootte",
    "save": "Opslaan",
    "invalidEmail": "Geef een geldig e-mailadres op",
    "sendingEmail": "Email verzenden",
    "resetEmailSent": "Controleer uw e-mail voor de link om uw wachtwoord opnieuw in te stellen.",
    "ad": "Advertentie",
    "nonogramPuzzle": "Nonogram puzzel",
    "goodLuck": "Succes!",
    "errorFound": "Fouten gevonden",
    "correct": "Correct!!",
    "startOver": "Begin opnieuw",
    "showCorrect": "Toon juiste rijen/kolommen",
    "checkIt": "Controleer het",
    "newNonogram": "Nieuw nonogram",
    "topTimesFor": "Toptijden voor {size} x {size}",
    "noTimes": "Geen tijd om te laten zien",
    "user": "Gebruiker",
    "time": "Tijd",
    "puzzle": "Puzzel",
    "nonogramSizes": "Nonogram Maten",
    "contactMe": "Neem contact met mij op",
    "howToSolve": "Hoe op te lossen",
    "topTimes": "Toptijden",
    "languages": "talen",
    "yourName": "Uw naam",
    "yourEmail": "Jouw email",
    "message": "Bericht",
    "sendingMessage": "Bericht versturen",
    "sendMessage": "Bericht versturen",
    "sentMessage": "Bericht verzonden",
    "bulgarian": "Bulgarian",
    "czech": "Tsjechisch",
    "danish": "Deens",
    "german": "Duitse",
    "greek": "Grieks",
    "english": "Engels",
    "spanish": "Spaans",
    "estonian": "Estlands",
    "finnish": "Fins",
    "french": "Frans",
    "hebrew": "Hebreeuws",
    "croatian": "Kroatisch",
    "hungarian": "Hongaars",
    "italian": "Italiaans",
    "japanese": "Japans",
    "korean": "Koreaans",
    "norwegian": "Noors",
    "dutch": "Nederlands",
    "polish": "Pools",
    "portuguese": "Portugees",
    "romanian": "Roemeense",
    "russian": "Russisch",
    "slovak": "Slowaaks",
    "slovenian": "Sloveens",
    "serbian": "Servisch",
    "swedish": "Zweeds",
    "turkish": "Turks",
    "vietnamese": "Vietnamees",
    "chinese": "Chinese",
    "howToSolveNonogram": "Hoe een nonogram op te lossen",
    "objective": "Doelstelling",
    "nonoObjective": "Het doel van het spel is om het raster te vullen met zwarte vierkanten zodat de aantallen gevulde sequentiële vierkanten overeenkomen met de nummers bovenaan en links.",
    "controls": "controls",
    "nonoControlsLeft": "Klik met de linkermuisknop om een vierkant te markeren als aan (zwart) of onbekend (wit).",
    "nonoControlsRight": "Klik met de rechtermuisknop om een vierkant als uit (rood X) of onbekend (wit) te markeren.",
    "nonoControlsTouch": "Raak aan om een vierkant te fietsen zoals aan (zwart), uit (rood X) of onbekend (wit).",
    "nonoControlsDrag": "Slepen werkt voor alle drie hierboven.",
    "freecell": "Freecell",
    "metaDescription": "Nonogrammen online. Meer dan 500.000 puzzels in 29 talen.",
    "metaKeywords": "nonogram, nonograms, afbeelding, puzzel, griddlers, griddler, picross",
    "mobileApps": "Mobiele apps"
  },
  "pl": {
    "account": "Konto",
    "username": "Nazwa Użytkownika",
    "password": "Hasło",
    "loading": "Ładowanie",
    "login": "Zaloguj Się",
    "noAccount": "Nie masz konta?",
    "createAccount": "Rejestracja tutaj",
    "required": "wymagany",
    "loggedIn": "Jesteś zalogowany!",
    "logout": "Wyloguj",
    "loggedOut": "teraz jesteś wylogowany",
    "returnToPuzzles": "Wróć do zagadek",
    "changePassword": "Zmień hasło",
    "settings": "Ustawienia",
    "settingsUpdated": "Ustawienia zaktualizowane",
    "register": "Zarejestrować",
    "forgotYourPassword": "Zapomniałeś hasła?",
    "resetPassword": "Zresetuj swoje hasło",
    "email": "E-mail",
    "confirmPassword": "Potwierdź hasło",
    "passwordUpdated": "Hasło zaktualizowane",
    "passwordsMustMatch": "Hasło musi pasować",
    "haveAnAccount": "Posiadasz już konto?",
    "ajaxError": "Był problem. Spróbuj ponownie później",
    "language": "Język",
    "size": "Rozmiar",
    "save": "Zapisać",
    "invalidEmail": "Podaj prawidłowy adres e-mail",
    "sendingEmail": "Wysyłać email",
    "resetEmailSent": "Sprawdź swój adres e-mail pod kątem linku, aby zresetować hasło.",
    "ad": "Reklama",
    "nonogramPuzzle": "Puzzle Nonogram",
    "goodLuck": "Powodzenia!",
    "errorFound": "Znalezione błędy",
    "correct": "Poprawny!!",
    "startOver": "Zacząć od nowa",
    "showCorrect": "Pokaż prawidłowe wiersze/kolumny",
    "checkIt": "Sprawdź to",
    "newNonogram": "Nowy Nonogram",
    "topTimesFor": "Najpopularniejsze czasy za {size} x {size}",
    "noTimes": "Nie ma czasu na pokazanie",
    "user": "Użytkownik",
    "time": "Czas",
    "puzzle": "Puzzle",
    "nonogramSizes": "Rozmiary Nonogram",
    "contactMe": "Skontaktuj się ze mną",
    "howToSolve": "Jak rozwiązać",
    "topTimes": "Najwyższe czasy",
    "languages": "Języki",
    "yourName": "Twoje imię",
    "yourEmail": "Twój email",
    "message": "Wiadomość",
    "sendingMessage": "Wysyłanie wiadomości",
    "sendMessage": "Wyślij wiadomość",
    "sentMessage": "Wiadomość wysłana",
    "bulgarian": "bułgarski",
    "czech": "Czech",
    "danish": "duński",
    "german": "Niemiecki",
    "greek": "grecki",
    "english": "język angielski",
    "spanish": "hiszpański",
    "estonian": "estoński",
    "finnish": "fiński",
    "french": "Francuski",
    "hebrew": "hebrajski",
    "croatian": "chorwacki",
    "hungarian": "język węgierski",
    "italian": "Włoski",
    "japanese": "język japoński",
    "korean": "koreański",
    "norwegian": "norweski",
    "dutch": "holenderski",
    "polish": "Polskie",
    "portuguese": "portugalski",
    "romanian": "rumuński",
    "russian": "Rosyjski",
    "slovak": "słowacki",
    "slovenian": "słoweński",
    "serbian": "serbski",
    "swedish": "szwedzki",
    "turkish": "turecki",
    "vietnamese": "wietnamski",
    "chinese": "chiński",
    "howToSolveNonogram": "Jak rozwiązać Nonogram",
    "objective": "Cel",
    "nonoObjective": "Celem gry jest wypełnienie siatki czarnymi kwadratami, tak aby liczby wypełnionych kolejnych pól pasowały do liczb na górze i na lewo.",
    "controls": "Sterownica",
    "nonoControlsLeft": "Kliknij lewym przyciskiem, aby zaznaczyć kwadrat jako włączony (czarny) lub nieznany (biały).",
    "nonoControlsRight": "Kliknij prawym przyciskiem, aby zaznaczyć kwadrat jako wyłączony (czerwony X) lub nieznany (biały).",
    "nonoControlsTouch": "Dotknij, aby włączyć kwadrat, tak jak on (czarny), off (czerwony X) lub nieznany (biały).",
    "nonoControlsDrag": "Przeciąganie działa dla wszystkich trzech powyższych.",
    "freecell": "Wolna komórka",
    "metaDescription": "Nonogramy online. Ponad 500 000 zagadek w 29 językach.",
    "metaKeywords": "nonogram, nonogramy, obraz, puzzle, griddlers, griddler, picross",
    "mobileApps": "Aplikacje mobilne"
  },
  "pt": {
    "account": "Conta",
    "username": "Nome de usuário",
    "password": "Senha",
    "loading": "Carregando",
    "login": "Entrar",
    "noAccount": "Não tem uma conta?",
    "createAccount": "Registre-se aqui",
    "required": "Requeridos",
    "loggedIn": "Você está logado!",
    "logout": "Sair",
    "loggedOut": "você está desconectado agora",
    "returnToPuzzles": "Volte para os quebra-cabeças",
    "changePassword": "Mudar senha",
    "settings": "Definições",
    "settingsUpdated": "Configurações atualizadas",
    "register": "registo",
    "forgotYourPassword": "Esqueceu sua senha?",
    "resetPassword": "Redefinir sua senha",
    "email": "O email",
    "confirmPassword": "Confirme a Senha",
    "passwordUpdated": "Senha atualizada",
    "passwordsMustMatch": "As senhas devem corresponder",
    "haveAnAccount": "já tem uma conta?",
    "ajaxError": "Havia um problema. Tente mais tarde",
    "language": "Língua",
    "size": "Tamanho",
    "save": "Salve ",
    "invalidEmail": "Forneça um email válido",
    "sendingEmail": "Enviando email",
    "resetEmailSent": "Verifique seu email para o link para redefinir sua senha.",
    "ad": "Propaganda",
    "nonogramPuzzle": "Quebra-cabeça Nonogram",
    "goodLuck": "Boa sorte!",
    "errorFound": "Erros encontrados",
    "correct": "Corrigir!!",
    "startOver": "Recomeçar",
    "showCorrect": "Mostrar linhas/colunas corretas",
    "checkIt": "Verifique-o",
    "newNonogram": "Novo Nonogram",
    "topTimesFor": "Principais tempos para {size} x {size}",
    "noTimes": "Não há horários para mostrar",
    "user": "Do utilizador",
    "time": "Tempo",
    "puzzle": "Enigma",
    "nonogramSizes": "Tamanhos de Nonogram",
    "contactMe": "Contate-me",
    "howToSolve": "Como resolver",
    "topTimes": "Top Times",
    "languages": "línguas",
    "yourName": "Seu nome",
    "yourEmail": "Seu email",
    "message": "mensagem",
    "sendingMessage": "Enviando mensagem",
    "sendMessage": "Enviar mensagem",
    "sentMessage": "Mensagem enviada",
    "bulgarian": "búlgaro",
    "czech": "Checo",
    "danish": "dinamarquês",
    "german": "alemão",
    "greek": "grego",
    "english": "Inglês",
    "spanish": "espanhol",
    "estonian": "estoniano",
    "finnish": "finlandês",
    "french": "francês",
    "hebrew": "hebraico",
    "croatian": "croata",
    "hungarian": "húngaro",
    "italian": "italiano",
    "japanese": "japonês",
    "korean": "coreano",
    "norwegian": "norueguês",
    "dutch": "holandês",
    "polish": "polonês",
    "portuguese": "Português",
    "romanian": "romena",
    "russian": "russo",
    "slovak": "Eslovaco",
    "slovenian": "esloveno",
    "serbian": "sérvio",
    "swedish": "sueco",
    "turkish": "turco",
    "vietnamese": "vietnamita",
    "chinese": "chinês",
    "howToSolveNonogram": "Como resolver um nonogram",
    "objective": "Objetivo",
    "nonoObjective": "O objetivo do jogo é preencher a grade com quadrados pretos para que os números de quadrados sequenciais preenchidos correspondam aos números no topo e na esquerda.",
    "controls": "Controles",
    "nonoControlsLeft": "Clique com o botão esquerdo para marcar um quadrado como on (preto) ou desconhecido (branco).",
    "nonoControlsRight": "Clique com o botão direito para marcar um quadrado como off (vermelho X) ou desconhecido (branco).",
    "nonoControlsTouch": "Toque para percorrer um quadrado como ligado (preto), desligado (X vermelho) ou desconhecido (branco).",
    "nonoControlsDrag": "Arrastar trabalha para todos os três acima.",
    "freecell": "Célula livre",
    "metaDescription": "Nonogramas Online. Mais de 500.000 quebra-cabeças em 29 idiomas.",
    "metaKeywords": "nonogram, nonograms, quadro, quebra-cabeça, griddlers, griddler, picross",
    "mobileApps": "Aplicativos móveis"
  },
  "ro": {
    "account": "Cont",
    "username": "Nume de utilizator",
    "password": "Parola",
    "loading": "se incarca",
    "login": "Logare",
    "noAccount": "Nu aveți un cont?",
    "createAccount": "Înregistrează-te aici",
    "required": "Necesar",
    "loggedIn": "Ești logat!",
    "logout": "Deconectați-vă",
    "loggedOut": "Acum ești deconectat",
    "returnToPuzzles": "Întoarceți-vă la puzzle-uri",
    "changePassword": "Schimbați parola",
    "settings": "Setări",
    "settingsUpdated": "Setările aduse la zi",
    "register": "Inregistreaza-te",
    "forgotYourPassword": "Ti-ai uitat parola?",
    "resetPassword": "Reseteaza parola",
    "email": "E-mail",
    "confirmPassword": "Confirma parola",
    "passwordUpdated": "Parolă actualizată",
    "passwordsMustMatch": "Parolele trebuie să se potrtivească",
    "haveAnAccount": "ai deja un cont?",
    "ajaxError": "A fost o problemă. Încercați mai târziu",
    "language": "Limba",
    "size": "mărimea",
    "save": "Salvați",
    "invalidEmail": "Furnizați un e-mail valid",
    "sendingEmail": "Trimiterea e-mailului",
    "resetEmailSent": "Verificați adresa de e-mail a link-ului pentru resetarea parolei.",
    "ad": "Publicitate",
    "nonogramPuzzle": "Puzzle nonogram",
    "goodLuck": "Mult noroc!",
    "errorFound": "Erori gasite",
    "correct": "Corect!!",
    "startOver": "Începeți",
    "showCorrect": "Afișați rândurile/Col. corecte",
    "checkIt": "Verifică",
    "newNonogram": "Nou nonogram",
    "topTimesFor": "Top timp pentru {size} x {size}",
    "noTimes": "Nu are timp să se arate",
    "user": "Utilizator",
    "time": "Timp",
    "puzzle": "Puzzle",
    "nonogramSizes": "Dimensiuni nonogram",
    "contactMe": "Contacteaza-ma",
    "howToSolve": "Cum să rezolve",
    "topTimes": "Timpul maxim",
    "languages": "Limbile",
    "yourName": "Numele dumneavoastră",
    "yourEmail": "Email-ul tau",
    "message": "Mesaj",
    "sendingMessage": "Trimiterea unui mesaj",
    "sendMessage": "Trimite mesaj",
    "sentMessage": "Mesaj trimis",
    "bulgarian": "bulgară",
    "czech": "ceh",
    "danish": "danez",
    "german": "limba germana",
    "greek": "greacă",
    "english": "Engleză",
    "spanish": "Spaniolă",
    "estonian": "eston",
    "finnish": "finlandeză",
    "french": "limba franceza",
    "hebrew": "ebraică",
    "croatian": "croat",
    "hungarian": "maghiar",
    "italian": "Italiană",
    "japanese": "japonez",
    "korean": "coreeană",
    "norwegian": "norvegian",
    "dutch": "olandeză",
    "polish": "polonez",
    "portuguese": "portugheză",
    "romanian": "Română",
    "russian": "Rusă",
    "slovak": "slovacă",
    "slovenian": "sloven",
    "serbian": "sârb",
    "swedish": "suedez",
    "turkish": "turc",
    "vietnamese": "Vietnameză",
    "chinese": "chinez",
    "howToSolveNonogram": "Cum de a rezolva o nonogram",
    "objective": "Obiectiv",
    "nonoObjective": "Obiectul jocului este să umple grila cu pătrate negre, astfel încât numerele de pătrate pline succesive să se potrivească cu numerele de sus și de stânga.",
    "controls": "Controale",
    "nonoControlsLeft": "Clic pe stânga pentru a marca un pătrat ca pe (negru) sau necunoscut (alb).",
    "nonoControlsRight": "Faceți clic dreapta pentru a marca un pătrat ca oprit (roșu X) sau necunoscut (alb).",
    "nonoControlsTouch": "Atingeți pentru a marca un pătrat ca pe (negru), oprit (roșu X) sau necunoscut (alb).",
    "nonoControlsDrag": "Draggingul funcționează pentru toate cele trei de mai sus.",
    "freecell": "Freecell",
    "metaDescription": "Nongrame online. Peste 500.000 de puzzle-uri în 29 de limbi.",
    "metaKeywords": "nonogramă, nonograme, imagine, puzzle, ghicitori, picătoare, picros",
    "mobileApps": "Aplicații pentru mobil"
  },
  "ru": {
    "account": "учетная запись",
    "username": "имя пользователя",
    "password": "пароль",
    "loading": "загрузка",
    "login": "Авторизоваться",
    "noAccount": "У вас нет аккаунта?",
    "createAccount": "Зарегистрируйтесь здесь",
    "required": "необходимые",
    "loggedIn": "Вы вошли в систему!",
    "logout": "Выйти",
    "loggedOut": "Вы вышли из системы",
    "returnToPuzzles": "Вернуться к головоломкам",
    "changePassword": "Изменить пароль",
    "settings": "настройки",
    "settingsUpdated": "Настройки обновлены",
    "register": "регистр",
    "forgotYourPassword": "Забыли свой пароль?",
    "resetPassword": "Сбросить свой пароль",
    "email": "Эл. адрес",
    "confirmPassword": "Подтвердите Пароль",
    "passwordUpdated": "Пароль обновлен",
    "passwordsMustMatch": "Пароли должны совпадать",
    "haveAnAccount": "Уже есть аккаунт?",
    "ajaxError": "Была проблема. Попробуйте позже",
    "language": "язык",
    "size": "Размер",
    "save": "Сохранить",
    "invalidEmail": "Введите действительный адрес электронной почты",
    "sendingEmail": "Отправка электронной почты",
    "resetEmailSent": "Проверьте свою электронную почту, чтобы найти ссылку для сброса пароля.",
    "ad": "Реклама",
    "nonogramPuzzle": "Nonogram Puzzle",
    "goodLuck": "Удачи!",
    "errorFound": "Ошибки найдены",
    "correct": "Правильный!!",
    "startOver": "Начать сначала",
    "showCorrect": "Показать правильные строки/столбцы",
    "checkIt": "Проверь это",
    "newNonogram": "Новая нонограмма",
    "topTimesFor": "Лучшие времена для {размер} х {размер}",
    "noTimes": "Нет раз, чтобы показать",
    "user": "пользователь",
    "time": "Время",
    "puzzle": "Головоломка",
    "nonogramSizes": "Размеры нонограммы",
    "contactMe": "Свяжитесь со мной",
    "howToSolve": "Как решить",
    "topTimes": "Лучшие времена",
    "languages": "Языки",
    "yourName": "Ваше имя",
    "yourEmail": "Ваш адрес электронной почты",
    "message": "Сообщение",
    "sendingMessage": "Отправка сообщения",
    "sendMessage": "Отправить сообщение",
    "sentMessage": "Сообщение отправлено",
    "bulgarian": "болгарский",
    "czech": "чешский язык",
    "danish": "датский",
    "german": "Немецкий",
    "greek": "греческий",
    "english": "английский",
    "spanish": "испанский",
    "estonian": "эстонский",
    "finnish": "финский",
    "french": "Французский",
    "hebrew": "иврит",
    "croatian": "хорватский",
    "hungarian": "Венгерский",
    "italian": "итальянский",
    "japanese": "японский язык",
    "korean": "корейский язык",
    "norwegian": "норвежский язык",
    "dutch": "Голландский",
    "polish": "польский",
    "portuguese": "португальский",
    "romanian": "румынский",
    "russian": "русский",
    "slovak": "словацкий",
    "slovenian": "словенский",
    "serbian": "сербский",
    "swedish": "шведский",
    "turkish": "турецкий",
    "vietnamese": "вьетнамский",
    "chinese": "китайский язык",
    "howToSolveNonogram": "Как решить нонограмму",
    "objective": "Задача",
    "nonoObjective": "Цель игры состоит в том, чтобы заполнить сетку черными квадратами так, чтобы числа заполненных последовательных квадратов совпадали с числами сверху и слева.",
    "controls": "управления",
    "nonoControlsLeft": "Щелкните левой кнопкой мыши, чтобы отметить квадрат как (черный) или неизвестный (белый).",
    "nonoControlsRight": "Щелкните правой кнопкой мыши, чтобы пометить квадрат как выключенный (красный X) или неизвестный (белый).",
    "nonoControlsTouch": "Коснитесь, чтобы включить или выключить квадрат (черный), выключенный (красный X) или неизвестный (белый).",
    "nonoControlsDrag": "Перетаскивание работает для всех трех вышеперечисленных.",
    "freecell": "Свободная ячейка",
    "metaDescription": "Нонограммы онлайн. Более 500 000 головоломок на 29 языках.",
    "metaKeywords": "nonogram, nonograms, картинка, пазл, griddlers, griddler, picross",
    "mobileApps": "Мобильные приложения"
  },
  "sk": {
    "account": "účet",
    "username": "užívateľské meno",
    "password": "heslo",
    "loading": "Načítava",
    "login": "Prihlásiť sa",
    "noAccount": "Nemáte účet?",
    "createAccount": "Registrujte sa tu",
    "required": "Požadovaný",
    "loggedIn": "Ste prihlásení!",
    "logout": "Odhlásiť sa",
    "loggedOut": "Teraz ste sa odhlásili",
    "returnToPuzzles": "Vráťte sa na hádanky",
    "changePassword": "Zmeniť heslo",
    "settings": "nastavenie",
    "settingsUpdated": "Nastavenia aktualizované",
    "register": "Registrovať",
    "forgotYourPassword": "Zabudol si heslo?",
    "resetPassword": "Obnoviť heslo",
    "email": "e-mail",
    "confirmPassword": "Potvrďte heslo",
    "passwordUpdated": "Aktualizované heslo",
    "passwordsMustMatch": "heslá sa musia zhodovať",
    "haveAnAccount": "Máte už účet?",
    "ajaxError": "Bol tu problém. Skúste znova neskôr",
    "language": "Jazyk",
    "size": "veľkosť",
    "save": "Uložiť",
    "invalidEmail": "Zadajte platný e-mail",
    "sendingEmail": "Odosielanie e-mailov",
    "resetEmailSent": "Skontrolujte svoj e-mail pre odkaz na obnovenie hesla.",
    "ad": "Reklama",
    "nonogramPuzzle": "Nonogram Puzzle",
    "goodLuck": "Veľa štastia!",
    "errorFound": "Nájdené chyby",
    "correct": "Správna !!",
    "startOver": "Začať odznova",
    "showCorrect": "Zobraziť správne riadky/stĺpce",
    "checkIt": "Skontrolujte to",
    "newNonogram": "Nový nonogram",
    "topTimesFor": "Najvyššie časy pre {size} x {size}",
    "noTimes": "Žiadny čas na zobrazenie",
    "user": "užívateľ",
    "time": "čas",
    "puzzle": "skladačka",
    "nonogramSizes": "Veľkosti nonogramu",
    "contactMe": "Kontaktujte ma",
    "howToSolve": "Ako riešiť",
    "topTimes": "Najvyššie časy",
    "languages": "jazyky",
    "yourName": "Tvoje meno",
    "yourEmail": "Tvoj email",
    "message": "správa",
    "sendingMessage": "Odosielanie správy",
    "sendMessage": "Poslať správu",
    "sentMessage": "Správa poslaná",
    "bulgarian": "bulharský",
    "czech": "český",
    "danish": "dánsky",
    "german": "Nemec",
    "greek": "grécky",
    "english": "Angličtina",
    "spanish": "španielsky",
    "estonian": "estónsky",
    "finnish": "fínsky",
    "french": "francúzsky",
    "hebrew": "hebrejčina",
    "croatian": "chorvátsky",
    "hungarian": "maďarský",
    "italian": "taliansky",
    "japanese": "japonský",
    "korean": "kórejský",
    "norwegian": "nórsky",
    "dutch": "holandský",
    "polish": "poľský",
    "portuguese": "portugalský",
    "romanian": "rumunský",
    "russian": "ruský",
    "slovak": "slovenský",
    "slovenian": "slovinčina",
    "serbian": "srbský",
    "swedish": "švédsky",
    "turkish": "turecký",
    "vietnamese": "vietnamský",
    "chinese": "čínsky",
    "howToSolveNonogram": "Ako vyriešiť nonogram",
    "objective": "objektívny",
    "nonoObjective": "Cieľom hry je vyplniť mriežku čiernymi štvorcami tak, aby čísla vyplnených sekvenčných štvorcov zodpovedali číslam v hornej a ľavej.",
    "controls": "riadenie",
    "nonoControlsLeft": "Kliknutím ľavým tlačidlom označte štvorec ako zapnutú (čiernu) alebo neznámu (bielu).",
    "nonoControlsRight": "Kliknutím pravým tlačidlom označte štvorec ako vypnutý (červený X) alebo neznámy (biely).",
    "nonoControlsTouch": "Dotknite sa, ak chcete štvorcový štítok zapnúť (čierny), vypnutý (červený X) alebo neznámy (biely).",
    "nonoControlsDrag": "Dragovanie funguje pre všetky tri vyššie uvedené.",
    "freecell": "Freecell",
    "metaDescription": "Nonograms Online. Viac ako 500 000 hádaniek v 29 jazykoch.",
    "metaKeywords": "nonogram, nonograms, picture, puzzle, griddlers, griddler, picross",
    "mobileApps": "Mobilné aplikácie"
  },
  "sl": {
    "account": "račun",
    "username": "Uporabniško ime",
    "password": "Geslo",
    "loading": "nalaganje",
    "login": "Vpiši se",
    "noAccount": "Nimate računa?",
    "createAccount": "Registrirajte se tukaj",
    "required": "Zahtevano",
    "loggedIn": "Prijavljeni ste!",
    "logout": "Izpisati",
    "loggedOut": "Zdaj ste odjavljeni",
    "returnToPuzzles": "Vrni se na uganke",
    "changePassword": "Spremeni geslo",
    "settings": "Nastavitve",
    "settingsUpdated": "Nastavitve posodobljene",
    "register": "Register",
    "forgotYourPassword": "Ste pozabili geslo?",
    "resetPassword": "Ponastavi geslo",
    "email": "E-naslov",
    "confirmPassword": "Potrdi geslo",
    "passwordUpdated": "Geslo posodobljeno",
    "passwordsMustMatch": "Geslo se mora ujemati",
    "haveAnAccount": "Že imate račun?",
    "ajaxError": "Prišlo je do težave. Poskusi znova kasneje",
    "language": "Jezik",
    "size": "Velikost",
    "save": "Shrani",
    "invalidEmail": "Vnesite veljaven e-poštni naslov",
    "sendingEmail": "Pošiljanje e-pošte",
    "resetEmailSent": "Preverite e-pošto za povezavo za ponastavitev gesla.",
    "ad": "Oglaševanje",
    "nonogramPuzzle": "Nonogram Puzzle",
    "goodLuck": "Vso srečo!",
    "errorFound": "Najdene napake",
    "correct": "Pravilno!",
    "startOver": "Začeti znova",
    "showCorrect": "Prikaži pravilne vrstice/stolpce",
    "checkIt": "Preveri",
    "newNonogram": "Novi Nonogram",
    "topTimesFor": "Najboljši časi za {size} x {size}",
    "noTimes": "Ni časa za prikaz",
    "user": "Uporabnik",
    "time": "Čas",
    "puzzle": "Puzzle",
    "nonogramSizes": "Velikosti Nonogramov",
    "contactMe": "Kontaktiraj me",
    "howToSolve": "Kako rešiti",
    "topTimes": "Top Times",
    "languages": "Jeziki",
    "yourName": "Tvoje ime",
    "yourEmail": "Vaš e-poštni naslov",
    "message": "Sporočilo",
    "sendingMessage": "Pošiljanje sporočila",
    "sendMessage": "Pošlji sporočilo",
    "sentMessage": "Sporočilo poslano",
    "bulgarian": "Bolgarščina",
    "czech": "Češčina",
    "danish": "Danščina",
    "german": "Nemščina",
    "greek": "Grščina",
    "english": "angleščina",
    "spanish": "španski",
    "estonian": "Estonščina",
    "finnish": "Finščina",
    "french": "Francosko",
    "hebrew": "Hebrejščina",
    "croatian": "Hrvaščina",
    "hungarian": "Madžarščina",
    "italian": "Italijanščina",
    "japanese": "Japonščina",
    "korean": "Korejščina",
    "norwegian": "Norveščina",
    "dutch": "Nizozemščina",
    "polish": "Poljščina",
    "portuguese": "Portugalščina",
    "romanian": "Romunščina",
    "russian": "Ruski",
    "slovak": "Slovaščina",
    "slovenian": "Slovenščina",
    "serbian": "Srbščina",
    "swedish": "Švedščina",
    "turkish": "Turščina",
    "vietnamese": "Vietnamski",
    "chinese": "Kitajski",
    "howToSolveNonogram": "Kako rešiti Nonogram",
    "objective": "Cilj",
    "nonoObjective": "Cilj igre je zapolniti mrežo s črnimi kvadrati, tako da se število zapolnjenih zaporednih kvadratov ujema s številkami na vrhu in levo.",
    "controls": "Kontrole",
    "nonoControlsLeft": "Z levim klikom označite kvadrat kot (črno) ali neznano (belo).",
    "nonoControlsRight": "Z desnim klikom označite kvadrat kot izklopljen (rdeč X) ali neznan (bel).",
    "nonoControlsTouch": "Dotaknite se, da označite kvadrat kot (črno), izklopljeno (rdeč X) ali neznano (belo).",
    "nonoControlsDrag": "Vlečenje deluje za vse tri zgoraj.",
    "freecell": "Freecell",
    "metaDescription": "Nonogrami na spletu. Več kot 500.000 ugank v 29 jezikih.",
    "metaKeywords": "nonogram, nonogrami, slika, sestavljanka, griddlers, griddler, picross",
    "mobileApps": "Mobilne aplikacije"
  },
  "sr": {
    "account": "Рачун",
    "username": "Корисничко име",
    "password": "Пассворд",
    "loading": "Учитавање",
    "login": "Пријавите се",
    "noAccount": "Немате налог?",
    "createAccount": "Региструјте овде",
    "required": "Потребан",
    "loggedIn": "Пријављени сте!",
    "logout": "Одјавити се",
    "loggedOut": "Сада сте одјављени",
    "returnToPuzzles": "Вратите се на загонетке",
    "changePassword": "Промени лозинку",
    "settings": "Подешавања",
    "settingsUpdated": "Подешавања су ажурирана",
    "register": "Регистровати",
    "forgotYourPassword": "Заборавили сте лозинку?",
    "resetPassword": "Ресетујте вашу лозинку",
    "email": "Емаил",
    "confirmPassword": "Потврди лозинку",
    "passwordUpdated": "Пассворд Упдатед",
    "passwordsMustMatch": "шифре морају да се подударају",
    "haveAnAccount": "Већ имате налог?",
    "ajaxError": "Настао је проблем. Покушајте поново касније",
    "language": "Језик",
    "size": "Величина",
    "save": "сачувати",
    "invalidEmail": "Наведите важећу е-пошту",
    "sendingEmail": "Слање е-поште",
    "resetEmailSent": "Проверите своју е-пошту за везу да бисте поново поставили лозинку.",
    "ad": "Реклама",
    "nonogramPuzzle": "Нонограм Пуззле",
    "goodLuck": "Срећно!",
    "errorFound": "Пронађене грешке",
    "correct": "Цоррецт !!",
    "startOver": "Почети испочетка",
    "showCorrect": "Прикажи исправне редове/колове",
    "checkIt": "Провери",
    "newNonogram": "Нев Нонограм",
    "topTimesFor": "Топ Тимес фор {сизе} к {сизе}",
    "noTimes": "Нема времена за показивање",
    "user": "Усер",
    "time": "време",
    "puzzle": "Пуззле",
    "nonogramSizes": "Нонограм Сизес",
    "contactMe": "Контактирај ме",
    "howToSolve": "Како решити",
    "topTimes": "Топ Тимес",
    "languages": "Лангуагес",
    "yourName": "Твоје име",
    "yourEmail": "Иоур Емаил",
    "message": "Порука",
    "sendingMessage": "Сендинг Мессаге",
    "sendMessage": "Пошаљи поруку",
    "sentMessage": "Порука послата",
    "bulgarian": "Булгариан",
    "czech": "Чешки",
    "danish": "Данисх",
    "german": "Немачки",
    "greek": "Греек",
    "english": "енглески језик",
    "spanish": "Шпански",
    "estonian": "Естонски",
    "finnish": "Фински",
    "french": "Француски",
    "hebrew": "Хебрев",
    "croatian": "Цроатиан",
    "hungarian": "Хунгариан",
    "italian": "Италијан",
    "japanese": "Јапанесе",
    "korean": "корејски",
    "norwegian": "Норвегиан",
    "dutch": "Дутцх",
    "polish": "Пољски",
    "portuguese": "Португалски",
    "romanian": "Румунски",
    "russian": "Руски",
    "slovak": "Словак",
    "slovenian": "Словеначки",
    "serbian": "Српски",
    "swedish": "Шведски",
    "turkish": "Туркисх",
    "vietnamese": "Вијетнамски",
    "chinese": "Цхинесе",
    "howToSolveNonogram": "Како ријешити Нонограм",
    "objective": "објективан",
    "nonoObjective": "Циљ игре је попунити мрежу црним квадратима тако да бројеви попуњених секвенцијалних квадрата одговарају бројевима на врху и лијево.",
    "controls": "Контроле",
    "nonoControlsLeft": "Левим кликом означите квадрат као он (црно) или непознато (бело).",
    "nonoControlsRight": "Десним кликом означите квадрат као искључен (црвени Кс) или непознати (бијели).",
    "nonoControlsTouch": "Додирните за кружење квадрата на (црно), искључено (црвено Кс) или непознато (бело).",
    "nonoControlsDrag": "Повлачење ради за сва три горе наведена.",
    "freecell": "Слободна ћелија",
    "metaDescription": "Нонограмс Онлине. Преко 500 000 загонетки на 29 језика.",
    "metaKeywords": "нонограм, нонограмс, слика, слагалица, гриддлерс, гриддлер, пицросс",
    "mobileApps": "Мобилне апликације"
  },
  "sv": {
    "account": "konto",
    "username": "Användarnamn",
    "password": "Lösenord",
    "loading": "Läser in",
    "login": "Logga in",
    "noAccount": "Har du inget konto?",
    "createAccount": "Registrera här",
    "required": "Nödvändig",
    "loggedIn": "Du är inloggad!",
    "logout": "Logga ut",
    "loggedOut": "Du är nu utloggad",
    "returnToPuzzles": "Återgå till pusselarna",
    "changePassword": "Ändra lösenord",
    "settings": "inställningar",
    "settingsUpdated": "Inställningar uppdaterade",
    "register": "Registrera",
    "forgotYourPassword": "Glömt ditt lösenord?",
    "resetPassword": "Återställ ditt lösenord",
    "email": "E-post",
    "confirmPassword": "Bekräfta lösenord",
    "passwordUpdated": "Lösenord uppdaterat",
    "passwordsMustMatch": "Lösenorden måste matcha",
    "haveAnAccount": "Har du redan ett konto?",
    "ajaxError": "Det var ett problem. Försök igen senare",
    "language": "Språk",
    "size": "Storlek",
    "save": "Spara",
    "invalidEmail": "Ange ett giltigt e-postmeddelande",
    "sendingEmail": "Skickar e-post",
    "resetEmailSent": "Kontrollera din email för länken för att återställa ditt lösenord.",
    "ad": "Annons",
    "nonogramPuzzle": "Nonogram pussel",
    "goodLuck": "Lycka till!",
    "errorFound": "Fel hittade",
    "correct": "Korrekt!!",
    "startOver": "Börja om",
    "showCorrect": "Visa korrekta rader/kolumner",
    "checkIt": "Kolla upp det",
    "newNonogram": "Nytt nonogram",
    "topTimesFor": "Topptider för {size} x {size}",
    "noTimes": "Inga gånger att visa",
    "user": "Användare",
    "time": "Tid",
    "puzzle": "Pussel",
    "nonogramSizes": "Nonogram storlekar",
    "contactMe": "Kontakta mig",
    "howToSolve": "Hur man löser",
    "topTimes": "Top Times",
    "languages": "språk",
    "yourName": "Ditt namn",
    "yourEmail": "Din email",
    "message": "Meddelande",
    "sendingMessage": "Skickar meddelande",
    "sendMessage": "Skicka meddelande",
    "sentMessage": "Meddelande skickat",
    "bulgarian": "bulgarisk",
    "czech": "tjeck",
    "danish": "danska",
    "german": "tysk",
    "greek": "grekisk",
    "english": "engelsk",
    "spanish": "spansk",
    "estonian": "estniska",
    "finnish": "finsk",
    "french": "franska",
    "hebrew": "hebreiska",
    "croatian": "Kroatisk",
    "hungarian": "ungerska",
    "italian": "italienska",
    "japanese": "japansk",
    "korean": "korean",
    "norwegian": "norrman",
    "dutch": "holländska",
    "polish": "putsa",
    "portuguese": "portugisiska",
    "romanian": "rumänska",
    "russian": "ryska",
    "slovak": "slovakiska",
    "slovenian": "slovenska",
    "serbian": "serb",
    "swedish": "svenska",
    "turkish": "turkiska",
    "vietnamese": "vietnames",
    "chinese": "kinesisk",
    "howToSolveNonogram": "Hur man löser ett nonogram",
    "objective": "Mål",
    "nonoObjective": "Syftet med spelet är att fylla rutnätet med svarta rutor så att antalet fyllda sekventiella rutor matchar siffrorna längst upp och vänster.",
    "controls": "kontroller",
    "nonoControlsLeft": "Vänster klicka för att markera en kvadrat som på (svart) eller okänt (vit).",
    "nonoControlsRight": "Högerklicka för att markera en kvadrat som av (röd X) eller okänd (vit).",
    "nonoControlsTouch": "Tryck på för att slå en kvadrat som på (svart), av (röd X) eller okänd (vit).",
    "nonoControlsDrag": "Draverk för alla tre ovanstående.",
    "freecell": "Freecell",
    "metaDescription": "Nonograms online. Över 500 000 pussel på 29 språk.",
    "metaKeywords": "nonogram, nonograms, bild, pussel, griddlers, griddler, picross",
    "mobileApps": "Mobilappar"
  },
  "tr": {
    "account": "hesap",
    "username": "Kullanıcı adı",
    "password": "Parola",
    "loading": "Yükleniyor",
    "login": "Oturum aç",
    "noAccount": "Hesabınız yok mu?",
    "createAccount": "Buraya kayıt ol",
    "required": "gereklidir",
    "loggedIn": "Giriş yaptınız!",
    "logout": "Çıkış Yap",
    "loggedOut": "şimdi çıkış yaptın",
    "returnToPuzzles": "Bulmacalara dön",
    "changePassword": "Şifre değiştir",
    "settings": "Ayarlar",
    "settingsUpdated": "Ayarlar güncellendi",
    "register": "Kayıt olmak",
    "forgotYourPassword": "Parolanızı mı unuttunuz?",
    "resetPassword": "Şifrenizi sıfırlayın",
    "email": "E-posta",
    "confirmPassword": "Şifreyi Onayla",
    "passwordUpdated": "Şifre güncellendi",
    "passwordsMustMatch": "Parolalar eşleşmeli",
    "haveAnAccount": "Zaten hesabınız var mı?",
    "ajaxError": "Bir problem vardı. Daha sonra tekrar deneyin",
    "language": "Dil",
    "size": "Boyut",
    "save": "Kayıt etmek",
    "invalidEmail": "Geçerli bir e-posta sağlayın",
    "sendingEmail": "E-mail göndermek",
    "resetEmailSent": "Şifrenizi sıfırlamak için bağlantı için e-postanızı kontrol edin.",
    "ad": "reklâm",
    "nonogramPuzzle": "Nonogram Yapboz",
    "goodLuck": "İyi şanslar!",
    "errorFound": "Bulunan Hatalar",
    "correct": "Doğru!!",
    "startOver": "Baştan başlamak",
    "showCorrect": "Doğru Satırları/Sütunları Göster",
    "checkIt": "Kontrol et",
    "newNonogram": "Yeni Nonogram",
    "topTimesFor": "{Size} x {size} için en iyi zamanlar",
    "noTimes": "Gösterecek zaman yok",
    "user": "kullanıcı",
    "time": "zaman",
    "puzzle": "Bulmaca",
    "nonogramSizes": "Nonogram Boyutları",
    "contactMe": "Benimle irtibata geçin",
    "howToSolve": "Nasıl çözülür",
    "topTimes": "En İyi Zamanlar",
    "languages": "duujjil",
    "yourName": "Adınız",
    "yourEmail": "E-posta adresiniz",
    "message": "Mesaj",
    "sendingMessage": "Mesaj göndermek",
    "sendMessage": "Mesaj gönder",
    "sentMessage": "Mesajı gönderildi",
    "bulgarian": "Bulgar",
    "czech": "Çek",
    "danish": "Danimarkalı",
    "german": "Almanca",
    "greek": "Yunan",
    "english": "ingilizce",
    "spanish": "İspanyol",
    "estonian": "Estonyalı",
    "finnish": "Fince",
    "french": "Fransızca",
    "hebrew": "İbranice",
    "croatian": "Hırvat",
    "hungarian": "Macarca",
    "italian": "İtalyan",
    "japanese": "Japonca",
    "korean": "Koreli",
    "norwegian": "Norveççe",
    "dutch": "Flemenkçe",
    "polish": "Polonya",
    "portuguese": "Portekizce",
    "romanian": "Romen",
    "russian": "Rusça",
    "slovak": "Slovak",
    "slovenian": "Sloven",
    "serbian": "Sırpça",
    "swedish": "İsveççe",
    "turkish": "Türk",
    "vietnamese": "Vietnam",
    "chinese": "Çince",
    "howToSolveNonogram": "Nonogram Nasıl Çözülür?",
    "objective": "Amaç",
    "nonoObjective": "Oyunun amacı, ızgarayı siyah karelerle doldurmak, böylece doldurulmuş ardışık karelerin sayıları üst ve soldaki sayılarla eşleşiyor.",
    "controls": "Kontroller",
    "nonoControlsLeft": "Bir kareyi (siyah) veya bilinmeyen (beyaz) olarak işaretlemek için sol tıklayın.",
    "nonoControlsRight": "Bir kareyi kapalı (kırmızı X) veya bilinmeyen (beyaz) olarak işaretlemek için sağ tıklayın.",
    "nonoControlsTouch": "Bir kareyi açık (siyah), kapalı (kırmızı X) veya bilinmiyor (beyaz) olarak değiştirmek için dokunun.",
    "nonoControlsDrag": "Sürüklemek yukarıdakilerin üçünde de işe yarar.",
    "freecell": "Freecell",
    "metaDescription": "Nonogramlar Çevrimiçi. 29 dilde 500.000'den fazla bulmaca.",
    "metaKeywords": "nonogram, nonogram, resim, bulmaca, griddlers, griddler, picross",
    "mobileApps": "Mobil uygulamalar"
  },
  "vi": {
    "account": "Tài khoản",
    "username": "Tên đăng nhập",
    "password": "Mật khẩu",
    "loading": "Đang tải",
    "login": "Đăng nhập",
    "noAccount": "Không có tài khoản?",
    "createAccount": "Đăng ký ở đây",
    "required": "Cần thiết",
    "loggedIn": "Bạn đã đăng nhập!",
    "logout": "Đăng xuất",
    "loggedOut": "Bạn đã đăng xuất",
    "returnToPuzzles": "Quay trở lại câu đố",
    "changePassword": "Đổi mật khẩu",
    "settings": "Cài đặt",
    "settingsUpdated": "Cài đặt đã cập nhật",
    "register": "Ghi danh",
    "forgotYourPassword": "Quên mật khẩu?",
    "resetPassword": "Đặt lại mật khẩu của bạn",
    "email": "E-mail",
    "confirmPassword": "Xác nhận mật khẩu",
    "passwordUpdated": "Mật khẩu được cập nhật",
    "passwordsMustMatch": "mật khẩu phải trùng khớp",
    "haveAnAccount": "Bạn co săn san để tạo một tai khoản?",
    "ajaxError": "Có một vấn đề. Thử lại sau",
    "language": "Ngôn ngữ",
    "size": "Kích thước",
    "save": "Tiết kiệm",
    "invalidEmail": "Cung cấp một email hợp lệ",
    "sendingEmail": "Gửi email",
    "resetEmailSent": "Kiểm tra email của bạn để biết liên kết để thiết lập lại mật khẩu của bạn.",
    "ad": "Quảng cáo",
    "nonogramPuzzle": "Câu đố Nonogram",
    "goodLuck": "Chúc may mắn!",
    "errorFound": "Lỗi tìm kiếm",
    "correct": "Chính xác!!",
    "startOver": "Bắt đầu lại",
    "showCorrect": "Hiển thị hàng/Cols chính xác",
    "checkIt": "Kiểm tra nó",
    "newNonogram": "Nonogram mới",
    "topTimesFor": "Thời gian hàng đầu cho {size} x {size}",
    "noTimes": "Không có thời gian để hiển thị",
    "user": "Người dùng",
    "time": "Thời gian",
    "puzzle": "Câu đố",
    "nonogramSizes": "Kích thước phi hình",
    "contactMe": "Liên hệ với tôi",
    "howToSolve": "Giải quyết thế nào",
    "topTimes": "Thời báo hàng đầu",
    "languages": "Ngôn ngữ",
    "yourName": "Tên của bạn",
    "yourEmail": "Email của bạn",
    "message": "Thông điệp",
    "sendingMessage": "Gửi tin nhắn",
    "sendMessage": "Gửi tin nhắn",
    "sentMessage": "Tin nhắn đã gửi",
    "bulgarian": "Tiếng Bulgaria",
    "czech": "Séc",
    "danish": "người Đan Mạch",
    "german": "tiếng Đức",
    "greek": "người Hy Lạp",
    "english": "Anh",
    "spanish": "người Tây Ban Nha",
    "estonian": "Tiếng Estonia",
    "finnish": "Phần Lan",
    "french": "người Pháp",
    "hebrew": "Tiếng Do Thái",
    "croatian": "Tiếng Croatia",
    "hungarian": "Tiếng Hungary",
    "italian": "người Ý",
    "japanese": "tiếng Nhật",
    "korean": "Hàn Quốc",
    "norwegian": "Na Uy",
    "dutch": "Hà Lan",
    "polish": "đánh bóng",
    "portuguese": "Bồ Đào Nha",
    "romanian": "Rumani",
    "russian": "Tiếng nga",
    "slovak": "Tiếng Slovak",
    "slovenian": "Tiếng Slovenia",
    "serbian": "Tiếng Serbia",
    "swedish": "Thụy Điển",
    "turkish": "Thổ Nhĩ Kỳ",
    "vietnamese": "Tiếng Việt",
    "chinese": "Trung Quốc",
    "howToSolveNonogram": "Làm thế nào để giải quyết một Nonogram",
    "objective": "Mục tiêu",
    "nonoObjective": "Mục tiêu của trò chơi là lấp đầy lưới bằng các ô vuông màu đen sao cho số lượng các ô vuông liên tiếp được điền khớp với các số ở trên cùng và bên trái.",
    "controls": "Kiểm soát",
    "nonoControlsLeft": "Nhấp chuột trái để đánh dấu hình vuông như trên (đen) hoặc không xác định (trắng).",
    "nonoControlsRight": "Nhấp chuột phải để đánh dấu một hình vuông là tắt (X màu đỏ) hoặc không xác định (màu trắng).",
    "nonoControlsTouch": "Chạm để xoay vòng một hình vuông như trên (đen), tắt (đỏ X) hoặc không xác định (trắng).",
    "nonoControlsDrag": "Kéo hoạt động cho cả ba trong số trên.",
    "freecell": "Freecell",
    "metaDescription": "Nonograms trực tuyến. Hơn 500.000 câu đố trong 29 ngôn ngữ.",
    "metaKeywords": "nonogram, nonograms, hình ảnh, câu đố, griddlers, griddler, picross",
    "mobileApps": "Ứng dụng di động"
  },
  "zh": {
    "account": "帐户",
    "username": "用户名",
    "password": "密码",
    "loading": "载入中",
    "login": "登录",
    "noAccount": "没有账号？",
    "createAccount": "在这里注册",
    "required": "需要",
    "loggedIn": "你登录了！",
    "logout": "登出",
    "loggedOut": "您现在已退出",
    "returnToPuzzles": "回到谜题",
    "changePassword": "更改密码",
    "settings": "设置",
    "settingsUpdated": "设置更新",
    "register": "寄存器",
    "forgotYourPassword": "忘记密码了吗？",
    "resetPassword": "重置你的密码",
    "email": "电子邮件",
    "confirmPassword": "确认密码",
    "passwordUpdated": "密码已更新",
    "passwordsMustMatch": "密码必须匹配",
    "haveAnAccount": "已经有账号？",
    "ajaxError": "有一个问题。稍后再试",
    "language": "语言",
    "size": "尺寸",
    "save": "保存",
    "invalidEmail": "提供有效的电子邮件",
    "sendingEmail": "发送电子邮件",
    "resetEmailSent": "查看您的电子邮件，找到重置密码的链接。",
    "ad": "广告",
    "nonogramPuzzle": "非图形拼图",
    "goodLuck": "祝好运！",
    "errorFound": "发现错误",
    "correct": "正确！！",
    "startOver": "重来",
    "showCorrect": "显示正确的行/列",
    "checkIt": "核实",
    "newNonogram": "新的Nonogram",
    "topTimesFor": "{size} x {size}的热门时间",
    "noTimes": "没时间表现出来",
    "user": "用户",
    "time": "时间",
    "puzzle": "难题",
    "nonogramSizes": "非图形尺寸",
    "contactMe": "联络我",
    "howToSolve": "怎么解决",
    "topTimes": "热门时报",
    "languages": "语言",
    "yourName": "你的名字",
    "yourEmail": "你的邮件",
    "message": "信息",
    "sendingMessage": "发送消息",
    "sendMessage": "发信息",
    "sentMessage": "消息已发送",
    "bulgarian": "保加利亚语",
    "czech": "捷克",
    "danish": "丹麦",
    "german": "德语",
    "greek": "希腊语",
    "english": "英语",
    "spanish": "西班牙语",
    "estonian": "爱沙尼亚语",
    "finnish": "芬兰",
    "french": "法国",
    "hebrew": "希伯来语",
    "croatian": "克罗地亚",
    "hungarian": "匈牙利",
    "italian": "意大利",
    "japanese": "日本",
    "korean": "朝鲜的",
    "norwegian": "挪威",
    "dutch": "荷兰人",
    "polish": "抛光",
    "portuguese": "葡萄牙语",
    "romanian": "罗马尼亚",
    "russian": "俄语",
    "slovak": "斯洛伐克",
    "slovenian": "斯洛文尼亚",
    "serbian": "塞尔维亚",
    "swedish": "瑞典",
    "turkish": "土耳其",
    "vietnamese": "越南",
    "chinese": "中文",
    "howToSolveNonogram": "如何解决Nonogram",
    "objective": "目的",
    "nonoObjective": "游戏的目的是用黑色方块填充网格，以便填充的连续方块的数量与顶部和左侧的数字相匹配。",
    "controls": "控制",
    "nonoControlsLeft": "左键单击以将方块标记为（黑色）或未知（白色）。",
    "nonoControlsRight": "右键单击将方块标记为关闭（红色X）或未知（白色）。",
    "nonoControlsTouch": "触摸可将方块循环为（黑色），关闭（红色X）或未知（白色）。",
    "nonoControlsDrag": "拖动适用于上述所有三个。",
    "freecell": "接龙",
    "metaDescription": "Nonograms在线。 29种语言的超过500,000个谜题。",
    "metaKeywords": "非图，非图，图片，拼图，平板电脑，格斗，picross",
    "mobileApps": "移动应用程序"
  }
};

export default localeData;
