import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation sendMessage($name: String!, $email: String, $msg: String!) {
    contactMe(name: $name, email: $email, message: $msg)
  }
`;

export const GET_CONTACT_ME = gql`
  query getContactMe {
    getContactMe {
      id
      name
      email
      message
      inserted
      is_read
    }
  }
`;

export const MARK_CONTACT_MESSAGE_READ = gql`
  mutation markContactMeAsRead($id: String!) {
    markContactMeAsRead(id: $id)
  }
`;
