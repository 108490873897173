import React from 'react';
import FieldWrap from './FieldWrap';
import { FormField } from './types';

interface FormProps<T extends object> {
  fields: FormField[];
  onSubmit: (values: T) => void;
  validate?: (values: T) => {};
  onCancel?: (e: any) => void;
  initialValues?: T;
  submitLabel?: React.ReactNode;
  hideCancel?: boolean;
  loading?: boolean;
}

const Form = <T extends object>({
  fields,
  onSubmit,
  validate,
  onCancel,
  initialValues,
  submitLabel,
  hideCancel,
  loading,
}: FormProps<T>) => {
  const [values, setValues] = React.useState<T>(initialValues as T);
  const [errors, setErrors] = React.useState({});

  const onChange = React.useCallback((fieldName: string, value: any) => {
    const newValues: T = {
      ...values,
      [fieldName]: value,
    };
    const errors = validate ? validate(newValues) : {};
    setValues(newValues);
    setErrors(errors);
  }, [ values, validate ]);

  const handleSubmit = React.useCallback((e: any) => {
    const isValid = Object.keys(errors).length === 0;
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    if (isValid) {
      if (onSubmit) {
        onSubmit(values);
      }
    }
  }, [ errors, onSubmit, values ]);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      {fields.map((field) => (
        <FieldWrap
          key={field.name}
          field={field}
          values={values}
          errors={errors}
          onChange={onChange}
        />
      ))}
      <div className="row justify-content-center">
        <button
          type="submit"
          className="btn btn-primary mr-3"
          disabled={loading}
        >
          {submitLabel}
        </button>
        {!hideCancel &&
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
            disabled={loading}
          >
            Cancel
          </button>
        }
      </div>
    </form>
  );
};

export default Form;
