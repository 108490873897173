import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from 'graphql/client';
import HomeApp from 'features/home/components/App';
import Router from 'Router';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';
import 'features/puzzle/components/style.scss';
import 'features/home/components/style.scss';
import { store } from './redux/store';

const App: React.FC = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <HomeApp>
          <Router />
        </HomeApp>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);

export default App;
