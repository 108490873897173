import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages';
import Form from 'features/barry-forms';
import { FormField } from 'features/barry-forms/types';

const fields: FormField[] = [
  {
    type: 'password',
    name: 'password',
    label: <FormattedMessage {...messages.password} />,
  },
  {
    type: 'password',
    name: 'password2',
    label: <FormattedMessage {...messages.confirmPassword} />,
  },
];

export interface PasswordValues {
  password: string;
  password2: string;
}

interface PasswordFormErrors {
  password?: React.ReactNode;
  password2?: React.ReactNode;
}

export const validate = (values: PasswordValues) => {
  const errors: PasswordFormErrors = {};
  if (!values.password) {
    errors.password = <FormattedMessage {...messages.required} />;
  }
  if (!values.password2) {
    errors.password2 = <FormattedMessage {...messages.required} />;
  } else if (values.password !== values.password2) {
    errors.password2 = <FormattedMessage {...messages.passwordsMustMatch} />;
  }
  return errors;
};

interface PasswordFormProps {
  onSubmit: (values: PasswordValues) => void;
  loading?: boolean;
}

const PasswordForm: React.FC<PasswordFormProps> = ({ onSubmit, ...props }) => (
  <Form<PasswordValues>
    {...props}
    onSubmit={onSubmit}
    fields={fields}
    hideCancel
    submitLabel={<FormattedMessage {...messages.changePassword} />}
    validate={validate}
  />
);

export default PasswordForm;
